.filterSectionFilters{
  display: flex;
  justify-content: center;
}
.filterSectionTagBlock{
  display: inline-flex;
  float: left;
  background-color: white;
  margin-right: 10px;
  margin-bottom: 10px;
  padding:2px 2px 2px 10px;
  border-radius: 15px;
  transition: 0.3s;
  position: relative;
  max-width: 71vw;
  width: fit-content;
  flex-flow: row wrap;
  height: fit-content;
  flex: 1 1 auto;
}
.filterSectionSubGroup{
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  color:var(--color-text-3);
  margin: 5px 5px 5px 5px;
}
.filterSectionTag{
  margin: 2.5px 2.5px 2.5px 2.5px;
  cursor: pointer;
  position: relative;
  max-width: 200px;
  width: fit-content;
  display: block;
  /* flex: 1 1 auto; */
  /* min-width: fit-content; */
}
.filterSectionTagLabel{
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--border-color-gray-00);
  border:1px solid var(--border-color-gray-0);
  padding:1px 6px 1px 6px;
  border-radius: 18px;
  transition: 0.3s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  width: fit-content;
}

.filterSectionTag:hover .filterTagReset{
  opacity:1
}
.filterSectionTagLabel:hover{
  background-color: var(--color-bad-bg) !important;
  color: rgba(0,0,0,0.2);
  /* box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.1); */
}
.filterSectionTagReset{
  position:absolute;
  right:-4px;
  top:-4px;
  font-size:14px;
  color:var(--color-bad);
  background-color: white;
  border-radius: 9px;
  transition:0.3s;
  cursor: pointer;
}
.filterSectionTagReset:hover{
  color:var(--color-bad-hover);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
}

.filterSection {
  width: 100%;
  margin-bottom: 10px;
  position:relative;
  display: flex;
  align-items: center;
  justify-items: center;
}
.filterSectionClearIcon {
  float: left;
  cursor:pointer;
  border-radius: 20px;
  transition: 0.3s;
  background-color: var(--color-bad);
  color: white;
  font-size: 18px;
  padding:4px;
  opacity:0.8;
  margin-right: 2vw;
  margin-bottom: 10px;
  margin-top:2px
}
.filterSectionClearIcon:hover {
  background-color:var(--color-bad-hover);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
  opacity:1
}