.riskCardLinkAction{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
.riskCardLinkActionSelect{
  max-width: 100%;
  width: 80%;
}
.riskCardLinkActionButtons{
  display: flex;
  width: 15%;
  align-items: center;
  justify-content: center;
}