/* ------------------------------------------------------------------------------------------------------------------ */
/*--- START COMMON INTERFACE ---------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */

@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@100;200;300;400;500;600;700;800&display=swap');

body { 
  font-size: 14px;
  font-family: 'Segoe UI', 'Sarabun', Arial, Tahoma, Geneva, Verdana, sans-serif;
  overflow: hidden; 
}
 
 html {
   scroll-behavior: smooth;
 }
 
 a{text-decoration: none;}
 a:hover{text-decoration: none;}
 
 :root{
  --color-primary:#0d6efd;
  --color-primary-1:rgb(61,139,253);
  --color-primary-2:rgb(110,168,254);
  --color-primary-3:rgb(127,189,255);
  --color-enrich-toolbar-item: #34495e;
  --color-migso-black:rgb(30,30,30);
  --color-migso-darkBlue:#2c2b64;
  --color-migso-darkBlue-light:#424174;
  --color-migso-blue:#03a9db;
  --color-migso-blue-hover:rgb(2, 151, 198);
  --color-migso-blue-hover-border: #86B7FE;
  --color-migso-blue-light:#CCEEF8;
  --color-migso-blue-light2:#e7f2f5;
  --color-migso-yeallow:#fcc708;
  --color-migso-yeallow-hover:rgb(221, 174, 4);
  --color-migso-green: #4ad199;
  --color-migso-green-hover: rgb(54, 189, 133);
  --color-good:#00b04f;
  --color-good-hover:#009E47;
  --color-good-bg:#F2F8EE;
  --color-alert:#ed7d31;
  --color-alert-hover:#ce6c2b;
  --color-alert-bg:#fcebe0;
  --color-bad:#e55766;
  --color-bad-hover:#E13B4B;
  --color-bad-bg:#fbebed;
  --color-forecasted: #0085ad;
  --color-escalated:#ffaa00;
  --color-clash:#ED7D31;
  --color-grey-light:#dee2e6;
  --color-text-1:#333333;
  --color-text-2:#5a5a5a;
  --color-text-3:#787878;
  --color-text-4:#aaaaaa;
  --color-text-5:rgb(190,190,190);
  --border-color-gray-00: rgb(250,250,250);
  --border-color-gray-00-5: rgb(245,245,245);
  --border-color-gray-0: #F0F0F0;
  --border-color-gray-0-5: #e6e6e6;
  --border-color-gray-1: #dcdcdc;
  --border-color-gray-1-5: rgb(205, 205, 205);
  --border-color-gray-2: rgb(190, 190, 190);
  --border-color-gray-3: #aaaaaa;
  --border-color-gray-4: rgb(140,140,140);
  --bg-color-selected-1: rgb(51,51,51);
  --bg-color-selected-2: #ffffc8;
  --bg-color-selected-3: #ffff64;
  --bg-app:  rgb(228,228,228);
  --color-bronze: #cd7f32;
  --color-silver: #C0C0C0;
  --color-gold: #FFD700;
  --cvt-color-highlight: #00E6AA;
  --cvt-color-dark: #2080CD;
  --cvt-color-light: #00CFD1;

  --multi-color-border: linear-gradient(to right, #ed8034 0%,#ed8034 33%,#feb123 33%,#feb123 66%,#2184cd 66%,#2184cd 100%);
}
 
 .grabbing{
   cursor:grabbing;
 }
   
 /* IMPORT -------------------------------------------------------------------------*/
  
  @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

/* TEXT ---------------------------------------------------------------------------- */
.text_Color2_600_11px {
  color: var(--color-text-2);
  font-size: 11px;
  font-weight: 600;
}
.text_Color2_600_12px {
  color: var(--color-text-2);
  font-size: 12px;
  font-weight: 600;
}
.text_Color2_700_11px {
  color: var(--color-text-2);
  font-size: 11px;
  font-weight: 700;
}
.text_Color2_600_14px {
  color: var(--color-text-2);
  font-size: 14px;
  font-weight: 600;
}
.text_Color2_500_10px {
  color: var(--color-text-2);
  font-size: 10px;
  font-weight: 500;
}
.text_Color3_500_10px {
  color: var(--color-text-3);
  font-size: 10px;
  font-weight: 500;
}
.text_Color3_600_14px {
  color: var(--color-text-3);
  font-size: 14px;
  font-weight: 600;
}
.text_Color3_400_13px {
  color: var(--color-text-3);
  font-size: 13px;
  font-weight: 400;
}
.text_Color3_500_13px {
  color: var(--color-text-3);
  font-size: 13px;
  font-weight: 500;
}
.text_Color3_500_12px {
  color: var(--color-text-3);
  font-size: 12px;
  font-weight: 500;
}
.text_Color4_500_11px {
  color: var(--color-text-4);
  font-size: 11px;
  font-weight: 500;
}
.text_Color4_500_12px {
  color: var(--color-text-4);
  font-size: 12px;
  font-weight: 500;
}
.text_Color4_600_13px {
  color: var(--color-text-4);
  font-size: 13px;
  font-weight: 600;
}
.text_Color4_600_14px {
  color: var(--color-text-4);
  font-size: 14px;
  font-weight: 600;
}
.text_Color5_600_14px {
  color: var(--color-text-5);
  font-size: 14px;
  font-weight: 600;
}
.text_White_700_11px {
  color: white;
  font-size: 11px;
  font-weight: 700;
}
.text_migsoBlue_600_13px {
  color: var(--color-migso-blue);
  font-size: 13px;
  font-weight: 600;
}

.textItalic {
  font-style: italic;
}

/* APP STRUCTURE ------------------------------------------------------------------- */
 
#appMain{
  height:100vh;
}

#appHeader{
 background-color: var(--color-migso-darkBlue);
 height: 50px;
 box-shadow: 2px 2px 4px 0px rgba(0, 0, 0,0.3);
 position:relative;
 z-index:1000;
 transition: 0.3s;
}

#appBody{
  height: calc(100% - 80px ); 
  background: linear-gradient(0.25turn, rgba(201, 207, 213, 0.65), rgba(222, 228, 234, 0.65), rgba(196, 208, 222, 0.65));
 }

#appSideBar{
  background-color: white;
  min-width:64px;
  max-width:64px;
  padding-top:8px;
  padding-bottom:10px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0,0.2);
  transition:0.3s;
}

#appRightSide{
 background-color: white;
 margin: 30px 30px 30px 0px;
 height: fit-content;
 max-height: calc(100% - 50px );
 box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 20%);
 border-radius: 8px;
 animation-duration: 500ms;
 animation-name: opacityIn;
 animation-iteration-count: 1;
 overflow: auto;
 min-width: 450px;
 max-width: 450px;
}

#appContent{
 margin:30px;
 background-color: white;
 overflow: auto;
 animation-duration: 800ms;
 animation-name: opacityIn;
 animation-iteration-count: 1;
 box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.2);
 width: 100%;
 position: relative;
 height: fit-content;
 max-height: calc(100% - 50px );
 transition: all 0.5s ease 0s; 
 border-radius: 8px;
}

#appContentHome{
 overflow-x: hidden;
 /* animation-duration: 800ms;
 animation-name: opacityIn;
 animation-iteration-count: 1; */
 width: 100%;
 position: relative;
 height: fit-content;
 max-height: 100%;
 transition: all 0.5s ease 0s; 
}

#appContentMapping{
 width:100%;
}

#appContentGlobal{
 overflow: auto;
 animation-duration: 800ms;
 animation-name: opacityIn;
 animation-iteration-count: 1;
 padding:30px;
 width: 100%;
 position: relative;
 height: fit-content;
 max-height: calc(100% );
 transition: all 0.5s ease 0s; 
}

#appFooter{
 background-color: var(--color-migso-black);
 height:30px;
 position: absolute;
 width:100%;
 bottom:0;
 transition: 0.5s;
}

.appContentBlock{
 width:100%;
 background-color: white;
 box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.2);
 padding:20px;
 margin-bottom:20px;
}

.appFooterContent{
 color:rgba(255,255,255,0.3);
 font-size: 12px;
 font-weight: 500;
 padding: 0px 150px 0px 20px;
}

.footerLink a{
 margin-left: 5px;
 color: rgba(255,255,255,0.3);
 transition:0.3s
}

.footerLink a:hover{
 color: white
}

.rootElementHoverHide > .childElementHoverHide {
  display: none;
}

.rootElementHoverHide:hover > .childElementHoverHide {
  display: flex;
}

/* APP SCROLLBAR ------------------------------------------------------------------------ */
 
::-webkit-scrollbar{
  background-color:var(--border-color-gray-0); 
  height:8px;
  width:8px; 
  transition: 0.3s;
}

::-webkit-scrollbar-thumb{
background-color: var(--border-color-gray-4);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-migso-blue);
}

.structureBlockProject::-webkit-scrollbar,
.structureBlockProject::-webkit-scrollbar-thumb{
  border-radius: 10px ;
}


/* RIGHT SIDE BAR ------------------------------------*/

.rightSideBar{
  width:600px
}

#rightSideBarBody{
  overflow-x: hidden;
}

/* SAVE ERROR MODAL POP UP ANIMATION */
@keyframes modalPopUpBottomMiddle {
  from {
    margin-bottom: -50vh;
    opacity: 0;
  }
  to {
    margin-bottom: 0px;
    opacity: 1;
  }
}
/* SAVE ERROR MODAL */
.rightSideBarSaveErrorModalContainer {
  position: absolute;
  width: 100%;
  background:rgba(0, 0, 0, 0.404);
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}
.rightSideBarSaveErrorModal {
  width: 300px;
  height: 200px;
  border-radius: 8px;
  border: 1px solid var(--border-color-gray-0);
  background: white;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.5);
  
  /* POP UP ANIM */
  animation-name: modalPopUpBottomMiddle;
  animation-duration: 400ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}
.rightSideBarSaveErrorCloseBtn {
  cursor: pointer;
  transition: 0.2s all ease;
}
.rightSideBarSaveErrorCloseBtn:hover {
  color: var(--color-bad);
}
.rightSideBarSaveErrorModalBody {
  font-size: 15px;
  width: 100%;
  text-align: center;
  flex: 1;
  font-weight: 500;
  padding: 0px 10px;
  border-bottom: 1px solid var(--border-color-gray-1);
}
.rightSideBarSaveErrorPortModalBody {
  font-size: 15px;
  width: 100%;
  text-align: center;
  flex: 1;
  font-weight: 500;
  padding: 0px 10px;
}

.rightSideBarSaveErrorModalBottomBtn {
  border: 1px solid var(--border-color-gray-1);
  background: var(--color-good);
  margin: 5px 0px;
  color: white;
  transition: 0.2s all ease;
}
.rightSideBarSaveErrorModalBottomBtn:hover {
  background: var(--color-good-hover);
  transform: scale(1.1);
  color: white;
}
.rightSideBarHeader{
  background-color: var(--border-color-gray-00-5);
  border-bottom: 1px solid var(--border-color-gray-1);
}
.rightSideBarHeaderTitle{
  font-size: 20px;
  font-weight: 500;
  color: var(--color-text-2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rightSideBarHeaderClose{
  color: var(--color-text-4);
  border: 1px solid var(--border-color-gray-1);
  background-color: #ffffff;
  padding:0px;
  transition:0.3s
}

.rightSideBarHeaderClose:hover{
  color: var(--color-migso-blue-hover) !important;
}

.rightSideBarHeaderClose:focus{
  box-shadow: none !important;
}

.rightSideBarNoGroup{
  margin: -15px 0px 20px 0px;
  padding-bottom: 20px;
}

.rightSideBarGroup{
  border: 1px solid var(--border-color-gray-0);
  margin-bottom: 15px;
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.rightSideBarGroupButton{
  padding:8px 10px;
  border-radius: 6px;
  background-position: calc(100% - 5px);
  font-weight: 500;
  color: var(--color-text-2);
  font-size: 14px;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.rightSideBarGroupButton:hover{
  background-color: var(--border-color-gray-00-5);
}
.rightSideBarGroupButton:focus{
  border-color: var(--border-color-gray-0-5); 
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.rightSideBarGroupButton::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23aaaaaa%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  background-size:15px;
  background-position: center;
}
.rightSideBarGroupButton:not(.collapsed)::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2302a8db%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
}
.rightSideBarGroupButton:not(.collapsed){
  background-color: var(--border-color-gray-00-5);
  border-radius: 6px 6px 0px 0px;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 5%);
  color: var(--color-text-2);
}
.rightSideBarGroupButtonAlert{
  margin-left: 10px;
  color:var(--color-bad);
}
.rightSideBarGroupButtonAlertTxt{
  margin-left: 2px;
  color:var(--color-bad);
  font-size: 12px;

}
.rightSideBarGroupButtonInfo{
  font-size: 12px;
  background-color: white;
  color:var(--color-text-3);
  padding: 1px 4px;
  border: 1px solid var(--border-color-gray-0);
  border-radius: 15px;
  margin-right: 10px;
}
.rightSideBarGroupButtonInfoGreen{
  background-color: var(--color-migso-green);
  color:white
}
.rightSideBarGroupButton:hover .rightSideBarGroupButtonInfo{
  background-color: white;
}
.rightSideBarGroupButton:not(.collapsed) .rightSideBarGroupButtonInfo{
  background-color: white;
}
.rightSideBarGroupButton:hover .rightSideBarGroupButtonInfoGreen{
  background-color: var(--color-migso-green);
}
.rightSideBarGroupButton:not(.collapsed) .rightSideBarGroupButtonInfoGreen{
  background-color: var(--color-migso-green);
}
.rightSideBarGroupMandatoryErrorButton {
  background: var(--color-bad)!important;
  color: white;
}
.rightSideBarGroupButtonIcon{
 font-size: 20px;
 color:rgba(3, 169, 219, 0.5);
 margin-right: 8px;
}
.rightSideBarGroupContent{
  padding:5px 20px 0px 20px
}
.rightSideBarConfirmBlock{
  position: fixed;
  z-index: 2000;
  padding: 15px 20px 20px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0,0.3);
  transition:0.3s
}
.rightSideBarConfirmBlockTitle{
  margin-bottom: 15px;
  font-weight: 500;
  color:var(--color-text-2)
}

/* COMMON ----------------------------------------- */

.commonTextBlock{
  border: 1px solid var(--border-color-gray-0-5);
  width: 100%;
  border-radius: 6px;
  padding: 10px 15px;
  background-color: white;
}

.commonTextBlockIcon{
  color:var(--border-color-gray-1);
  font-size:28px;
  margin-right:10px;
}

.commonEmptyTextLine{
  height: 20px;
  width: 100%;
  background-color: var(--border-color-gray-0);
  border-radius: 20px;
  font-size: 10px;
  padding-left: 5px;
  color:var(--border-color-gray-3)
}

.commonTagBlock{
  font-weight: 600;
  padding: 0px 4px;
  border-radius: 6px
}

.commonlitleIconLeftText{
  font-size: 20px;
  margin-right: 5px;
  color: var(--color-text-5);
}

/* ANIMATION -------------------------------------- */
.noAnimations {
  transition: none !important;
}

/* SUPPORT ---------------------------------------- */

.supportTabButton {
  background-color: #02A8DB;
  position: absolute;
  z-index: 1044;
  height: 35px;
  bottom: 0;
  right: 15px;
  padding: 0px 15px 0px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  transition: 0.3s;
}
.supportTabButton:hover {
  box-shadow: -2px -2px 5px 2px rgba(255, 255, 255, 0.3), 2px -2px 5px 2px rgba(255, 255, 255, 0.3);
  height: 40px;
  padding-bottom: 5px;
}

/* CHARTER --------------------------------------- */

@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--value);
  }
}

.charterProgressBar {
  --size: 32px;
  --fg: var(--color-migso-blue);
  --bg: #def;
  --pgPercentage: var(--value);
  animation: growProgressBar 1s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background:
    radial-gradient(closest-side, white 70%, transparent 0 99.9%, white 0),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 2.5);
  color: var(--fg);
}

.charterCardTitleLegend{
  color:var(--color-text-4);
  font-size: 11px;
  font-weight: 500;
  margin-bottom: -5px;
}

.charterCardCalcul{
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.charterSwitchView{
  position: absolute;
  right:10px;
  top:10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
  padding: 2px 6px;
  border-radius: 10px;
  color:var(--color-text-3);
  transition: 0.3s;
}
.charterSwitchView:hover{
  color:var(--color-migso-blue)
}

.charterSwitchViewIcon{
  font-size:14px;
  color:var(--color-text-5);
  margin-right:5px;
}

.charterFlipCard {
  background-color: transparent;
  margin-left: 25px;
  perspective: 1000px; 
}

.charterFlipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.charterFlipCardFront, .charterFlipCardBack {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.charterFlipCardBack {
  transform: rotateY(180deg);
}


.charterCalculCellSelected{
  font-weight: 500;
  background-color: rgba(3, 169, 219, 0.7);
  color: white;
  padding:0px 5px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
}
.charterCalculCellLegend{
  min-width:110px;
  max-width:110px;
  padding:0px 6px 0px 6px;
  white-space:nowrap;
  font-size: 12px;
  font-weight: 500;
  border-right:1px solid var(--border-color-gray-0);
}
.charterCalculCellUnit{
  font-size: 11px;
  font-weight: 400;
  margin-left: 5px;
  font-style: italic;
}
.charterCalculCellHeader{
  font-size: 12px;
  width:100%;
  color:var(--color-text-3);
  font-weight: 500;
  border-right: 1px solid white;
}
.charterCalculCellHeader:last-child{
  border-right: none;
}
.charterCalculCell{
  font-size: 11px;
  width:100%;
  padding:0px 14px 0px 4px;
  color:var(--color-text-4);
}

.charterCalculCellContent{
  position:relative;
  border-right:1px solid var(--border-color-gray-00-5)
}
.charterCalculCellContent:last-child{
  border-right:none
}
.charterCalculCellWeight{
  font-size: 10px;
  font-weight: 500;
  position:absolute;
  top:3px;
  right:6px;
  background-color: var(--border-color-gray-0);
  color:var(--border-color-gray-2);
  height:14px;
  width:14px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
}
.charterCalculRow{
  width: 100%;
  height: 34px
}
.charterCalculRowHeader{
  background-color: var(--border-color-gray-0);
  margin-top: 10px;
  height:24px;
  border:1px solid var(--border-color-gray-0-5);
  border-bottom:1px solid var(--border-color-gray-0);
  border-radius: 8px 8px 0px 0px;
}
.charterCalculRowBody{
  background-color: white;
  border-bottom: 1px solid var(--border-color-gray-0-5) ;
  border-left: 1px solid var(--border-color-gray-0-5) ;
  border-right: 1px solid var(--border-color-gray-0-5) ;
}
.charterCalculRowBody:last-child{
  border-radius: 0px 0px 8px 8px;
}
.charterCalculRowResult{
  margin-top:15px;
  height:50px;
  border: 1px solid var(--border-color-gray-0-5);
  border-radius: 8px !important;
}
.charterCalculRowResultCell{
  border-right:1px solid var(--border-color-gray-00-5)
}
.charterCalculRowResultCell:last-child{
  border-right:none
}
.charterCalculRowResultCellCategory{
  font-size: 14px;
  font-weight: 500;
}

 
/* AUTHENTICATION ----------------------------------- */

/* !! realigner */

.signInBlock{
  margin: 40px auto auto auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.2);
}

.signInBlock a{
  cursor: pointer;
  color: var(--color-migso-blue);
}

.signInBlock a:hover{
  color: var(--color-migso-blue-hover);
}

.signInMessage{
  width:100%;
  margin-bottom: 15px;
  font-weight: 500;
}

.signInQuote{
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-style: italic ;
  font-weight: 400;
  opacity: 0.5;
}

.appAuthenticatorTitle{
  font-weight: 500;
  font-size: 18px;
  color:var(--color-text-3);
  margin-bottom: 10px;
  max-height: 50px;
  overflow: hidden !important;
}

.appAuthenticatorInputBlock{
  background-color: var(--border-color-gray-00);
  border:1px solid var(--border-color-gray-0-5);
  border-radius: 8px;
  padding:0px;
  margin-bottom: 15px;
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.appAuthenticatorInput{
  font-size: 14px;
  padding:6px 8px;
  margin-top: 3px
}

.appAuthenticatorQuestion{
  margin-top:5px;
  padding:0px 20px 15px 20px
}

.appAuthenticatorPassword{
  background-color: var(--border-color-gray-00);
  margin:0px 20px 20px 20px;
  padding:10px 20px 10px 10px;
  border-radius: 6px;
  border:1px solid var(--border-color-gray-0);
  width:calc(100% - 40px)
}

.appAuthenticatorPasswordIcon{
  color:var(--color-bad);
  margin-right: 8px;
}

.appAuthenticatorWrongConfirmPassword{
  box-shadow: 0 0 0 0.25rem rgb(229 87 102 / 55%) !important;
}

.appAuthenticatorWrongConfirmPassword:focus{
  box-shadow: 0 0 0 0.25rem rgb(229 87 102 / 55%) !important;
}

.homeAuthenticator a:hover{
  color: var(--color-migso-yeallow-hover);
}

.homeAuthenticator{
  position: fixed;
  right: 5%;
  background-color: white;
  padding:20px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0,0.3);
  width:40%;
  top:90px;
  z-index:99;
  max-height: calc(100vh - 150px);
  transition: 1s;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

.homeAuthenticatorTopLink{
  top: calc(100vh - 100px);
  width: 45px;
  height: 45px;
  border-radius: 50px;
  right: 20px;
  padding: 10px;
  box-shadow: 1px 1px 5px 3px rgba(0, 0, 0,0.3);
}

.homeAuthenticator a{
  cursor: pointer;
  color: var(--color-migso-blue);
}

.homeAuthenticator a:hover{
  color: var(--color-migso-blue-hover);
}

.Section__sectionHeader___2djyg{
  font-weight: 500;
  font-size: 18px;
  color:var(--color-text-3);
  margin-bottom: 15px;
  max-height: 50px;
  overflow: hidden !important;
}

.Section__sectionBody___ihqqd{
  border:1px solid var(--border-color-gray-0-5);
  border-radius: 8px;
  padding:15px 20px 5px 20px;
  margin-bottom: 15px;
  max-height: calc(100vh - 420px);
  min-height: 62px;
  overflow-y: auto;
  overflow-x: hidden;
}

.Section__sectionFooterSecondaryContent___Nj41Q{
  margin-left: 15px;
}

.Button__button___vS7Mv{
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: var(--color-migso-blue);
  border-color: var(--color-migso-blue);
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.Button__button___vS7Mv:hover{
  background-color: var(--color-migso-blue-hover) !important;
  border-color: var(--color-migso-blue-hover) !important;
}

.Button__button___vS7Mv:focus{
  box-shadow: 0 0 0 0.25rem rgb(2 168 219 / 25%) !important;
}

.Button__button___vS7MvRed{
  background-color: var(--color-bad);
  border-color: var(--color-bad);
}

.Button__button___vS7MvRed:hover{
  background-color: var(--color-bad-hover) !important;
  border-color: var(--color-bad-hover) !important;
}

.Button__button___vS7MvRed:focus{
  box-shadow: 0 0 0 0.25rem rgb(229 87 102 / 25%) !important;
}

 /* LOGO ------------------------------------------------ */
 
 .logoBlock{
  opacity: 0.8;
  transition: 0.5s;
}

.logoBlock:hover{
  opacity: 1;
}

.logoImg{
  filter: grayscale(1);
  width:36px;
  opacity:0.7;
  margin: 0px 0px 0px -2px;
  transition:0.5s;
}

.logoBlock:hover .logoImg{
  filter: none;
  opacity:1
}

.logoContent{
  font-family: 'Sarabun';
  font-size: 16px;
  font-weight: bold;
  color:rgba(255,255,255);
}

.logoIcon{
  color:var(--color-migso-blue);
  font-size: 30px;
  margin:0px 10px 0px 10px;
  transition: 0.1s;
  opacity: 1;
}

.logoIconAbr{
  background-color: rgba(255,255,255,0.8);
  padding: 1px 8px;
  border-radius: 6px;
  color:var(--color-migso-darkBlue);
  transition: 00.3s;
}

.logoIconAbrContent{
  margin:0px 10px 0px 14px
}

.logoContent:hover .logoIconAbr{
  background-color: white !important;
  color:var(--color-migso-blue);
}

.logoContent:hover .logoTxtContent{
  color:white !important;
}

.logoTxtContent{
  transition:0.3s;
  margin-top:-2px;
}

/* LOADER PAGE ---------------------------------------- */

.loaderPageLogoBlock{
  width:100%;
  background-color: var(--color-migso-black);
  border-radius: 10px 10px 0px 0px;
  padding: 10px 

}

/* BUTTON --------------------------------------------- */

.commonButtonSubmit{
  color: #fff;
  background-color: var(--color-migso-blue);
  border-color: var(--color-migso-blue);
}

.commonButtonSubmit:hover{
  color: #fff !important;
  background-color: var(--color-migso-blue-hover) !important;
  border-color: var(--color-migso-blue-hover) !important;
}

.commonButtonSubmit:focus{
  box-shadow: 0 0 0 0.25rem rgb(2 168 219 / 25%) !important;
}

.commonButtonSubmitWhite{
  color: var(--color-text-4);
  background-color: white;
  border-color: white;
  transition:0.3s
}

.commonButtonSubmitWhite:hover{
  color: var(--color-migso-blue) !important;
}

.commonButtonSubmitWhite:focus{
  box-shadow: 0 0 0 0.25rem rgb(2 168 219 / 25%) !important;
}
.commonButtonNofocus:focus{
  box-shadow: none !important;
}



.commonButtonSubmitLight{
  color: var(--color-text-4);
  padding:0px;
  transition:0.3s
}

.commonButtonSubmitLight:hover{
  color: var(--color-migso-blue-hover) !important;
}

.commonButtonSubmitLight:focus{
  box-shadow: none !important;
}


.commonButtonSubmitLightClose{
  color: var(--color-text-4);
  transition:0.3s
}

.commonButtonSubmitLightClose:hover{
  color: var(--color-bad-hover) !important;
}

.commonButtonSubmitLightClose:focus{
  box-shadow: none !important;
}


/* COMMON HOVER --------------------------------------- */


/* !!! create standar hoer */

.headerUser:hover,
.headerLink:hover,
.modalButtonSubmitLightInverse:hover,
.modalInputColorIcon:hover,
.modalSwitchLinkButton:hover,
.organizeGroupItemSwitch:hover,
.paletteOrganizeGroupItem:hover{
  color:var(--color-migso-blue) !important;
}

.modalButtonSubmitOutlineIcon:hover,
.modalIconAction:hover{
  color: var(--color-migso-blue-hover)!important;
}

.buttonSideBarHOverRed:hover,
.modalButtonSubmitCloseIcon:hover,
.modalOptionBlockTitleReset:hover,
.modalBodyBlockInputIcon:hover,
.modalTableRowIcon:hover,
.contextMenuActionDelete:hover,
.roadmapHeaderTemplateSettingsSelect:hover,
.roadmapGroupIconDelete:hover{
  color:var(--color-bad) !important;
}

.modalIconDelete:hover,
.commonSearchIconReset:hover{
  color:var(--color-bad-hover);
}

.contextMenuAction:hover{
  background-color: var(--border-color-gray-0);
}

/* COMMON TO MOVE TO COMMON INTERFACE------------------------------ */

.commonBlockTitle{
  border-bottom: 1px solid var(--border-color-gray-1);
  padding:15px  ;
  position: sticky;
  top:0px;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 500;
  background-color: white;
  z-index: 1;
}

.commonBlockTitleIcon{
  color: var(--color-migso-blue);
  margin-right: 10px;
}

.commonSearchGroup{
  padding: 15px;
  background-color: white;
  z-index: 1;
}

.commonSearchIcon{
  margin-right:1px;
  font-size: 22px !important;
  border-radius:20px;
  height: 32px !important;
  background-color: var(--border-color-gray-0);
  color: var(--color-text-4);
  border-color: var(--border-color-gray-1);
  border-right:none;
  padding:6px 4px 6px 8px;
}

.commonSearchIconReset{
  margin-left:10px;
  font-size: 22px !important;
  height: 32px !important;
  color: var(--color-text-4);
  border-color: var(--border-color-gray-1);
  border-right:none;
  cursor: pointer;
}

.commonSearch{
 font-size: 14px !important;
 font-weight: 500 !important;
 padding:4px 10px 5px 10px ! important;
 border-radius:20px;
 height: 32px !important;
 border-color: var(--border-color-gray-1);
 border-left:none;
 transition: 0.3s;
}

.commonSearch:focus{
 box-shadow: none !important;
 border-color: var(--border-color-gray-1) !important;
}

.commonOrganizationLitleLogo {
  height: 32px;
  width: 32px;
  border-radius: 6px;
  margin-right: 10px;
  object-fit: contain;
}

/* TABLE ------------------------------- */

table.commonTableContent{
  width:100%;
  position:relative;
  border-collapse: separate;
  border-spacing: 0;
}

.commonTableHeaderCell{
  padding: 5px;
  height: 40px;
  position: sticky;
  background-color: white;
  z-index: 100;
  color:var(--color-text-1);
  border-bottom:1px solid var(--border-color-gray-1);
  border-top:1px solid var(--border-color-gray-1);
  background-color: var(--border-color-gray-0);
  text-align: center;
}

.commonTableRow{
  border-bottom: 1px solid var(--border-color-gray-0);
  transition: 0.1s;
}

.commonTableRow:hover{
  background-color:var(--color-migso-blue);
  color:white;
}

.commonTableRow:hover .material-icons{
  color:white !important;
}

.commonTableRow:hover .iconMil{
  border-bottom-color:white !important;
}

.commonTableRow.select:hover .material-icons{
  color:var(--border-color-gray-2) !important;
}

.commonTableRow.select:hover .iconMil{
  border-bottom-color:var(--border-color-gray-2) !important;
}

.commonTableCell{
  padding: 5px;
  height: 100%;
  border-bottom:1px solid var(--border-color-gray-0)
}

.commonTableCellIcon{
  color:var(--border-color-gray-2);
  display: table-cell;
  max-width: 34px;
  min-width: 34px;
}


.commonLoaderBlock{
  margin: 20px;
  font-weight: 500;
  color:var(--color-text-2);
}
.commonLoaderBlockSpinner{
  height: 20px;
  width: 20px;
  margin-right: 10px;
  color:var(--color-migso-blue);
}
.commonListItemLight{
  font-weight: 700;
  color:var(--color-text-2);
  font-size: 20px;
  transition:0.3s;
}
.commonListItem{
  background-color: var(--border-color-gray-00);
  border: 1px solid var(--border-color-gray-00-5);
  margin: 20px;
  font-weight: 500;
  color:var(--color-text-2);
  font-size: 18px;
  padding: 10px ;
  border-radius: 6px;
  cursor: pointer;
  transition:0.3s;
}
.commonListItem:hover{
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.6);
}
.commonListItemSelected{
  background-color: var(--bg-color-selected-2);
}
.commonListItemSubTitle{
  color: var(--color-text-4);
  margin-right: 7px;
  font-size: 12px;
}
.commonListItemSubTitle2{
  color: var(--color-text-4);
  margin-right: 7px;
  
}
.commonListItemDescription{
  margin: 15px 0px 20px 0px;
  color:var(--color-text-1);
  background-color: var(--border-color-gray-00-5);
  padding: 5px 10px;
  border-radius: 6px;
  width: fit-content
}
.commonListSelect{
  font-size: 14px;
  padding: 5px 5px 5px 8px;
  font-weight: 500;
}
.commonListItemHightLightList{
  background-color: var(--color-migso-blue);
  color:white;
  padding: 1px 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.4);
}
.commonOrganizationLitleLogo{
  height:32px; 
  width:32px;
  border-radius: 6px;
  margin-right: 10px;
  object-fit: contain;
}
.commonOrganizationName{
  color:var(--color-text-2);
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
  margin-top: -6px;
  transition:0.3s;
}
.commonOrganizationName:hover{
  color:var(--color-migso-blue);
}

.organizationProject .table-striped>:not(caption)>*>*{
  border-bottom-width: 0px !important;
}

.organizationProject table>:not(:first-child) {
  border-top: 2px solid var(--border-color-gray-1);
}

.commonDropIcon{
  animation-duration: 300ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
  top:0;
  width:40px;
  position:relative;
  z-index:1001;
  transition:0.3s;
}


/* HEADER MENU ---------------------------------------- */

.headerLogoSearchBlock{
  width:100%;
  flex: 1;
}

.headerLightMenuBlock{ 
  background-color: var(--color-migso-darkBlue-light);
  position: fixed;
  top:50px;
  left:0px;
  width: 100%;
  animation-duration: 200ms;
  animation-name: slideInTop;
  animation-iteration-count: 1;
  z-index: 999;
  padding:20px;
  font-weight: 600;
  white-space: nowrap;
}

.headerLightMenuBg{
  animation-duration: 500ms;
  animation-name: opacityInbg;
  animation-iteration-count: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity:0.5;
}

.headerMenuBlock{
  height: 100%;
  font-weight: 600;
  white-space: nowrap;
}

.headerMenuBlock a{
  color:white;
  transition: 0.1s;
}

.headerLightMenuBlock a{
  color:white;
  transition: 0.1s;
  margin-bottom: 10px;
}

.headerMenuItem:hover{
  opacity: 1;
  transition:0.3s;
}

.headerMenuIconLogOut{
  font-size: 22px;
}

.headerMenuIcon{
  font-size: 22px;
  color:var(--color-migso-blue);
}

.headerMenuItem{
  padding:5px 15px 5px 15px;
  opacity: 0.6;
}

.headerMenuTxt {
  margin:0px 5px 0px 10px;
  display: block;
}

.headerNavSelected{
  background-color: rgba(255,255,255,0.2);
  border-radius:20px;
}

.headerNavSelected .headerMenuItem{
  opacity:1;
}

.headerSeparatorY{
  border-left:1px solid rgba(255,255,255,0.3);
  margin:0px 20px;
  height:32px;
}

.headerSeparatorX{
  width:100%;
  margin:20px 0px;
}

.headerUser{
  margin-right:8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding:0px ! important;
  border-radius:20px;
  height: 28px !important;
  width: 28px;
  white-space: nowrap;
  background-color: rgba(255,255,255,0.8);
  color: var(--color-migso-black);
  border-color: rgba(255,255,255,0.3);
}

.headerLog{
  margin-right:15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding:4px 10px 5px 10px ! important;
  border-radius:20px;
  height: 32px !important;
  white-space: nowrap;
  background-color: rgba(255,255,255,0);
  color: rgba(255,255,255,0.8);
  border-color: rgba(255,255,255,0.3);
}

.headerLogIn:hover{
  color:var(--color-good) !important;
  font-weight: 500 !important;
 }

.headerLink{
  margin-left:15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding:4px 10px 5px 10px ! important;
  border-radius:20px;
  height: 32px !important;
  background-color: rgba(255,255,255,0.2);
  color: rgba(255,255,255,0.8);
  border-color: rgba(255,255,255,0);
  white-space: nowrap;
}

.headerServiceDropDown{
  width:100px;
  margin-right:15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding:4px 10px 5px 10px ! important;
  border-radius:20px;
  height: 32px !important;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255,255,255,0.8);
  border-color: rgba(255,255,255,0.3);
  min-width: 100px;
  transition: 0.3s;
  white-space: nowrap;
}

.headerServiceDropDown:hover, .headerServiceDropDown:focus{
  color:var(--color-migso-blue) !important;
  background-color: white;
  font-weight: 500 !important;
}

.headerServiceDropDown:focus{
  box-shadow: none;
  border-color: rgba(255,255,255,0) !important;
}

.headerServiceDropDown .select-items div{
  background-color: royalblue;
}

.headerSearchIcon{
  margin-right:1px;
  font-size: 22px !important;
  border-radius:20px;
  height: 32px !important;
  background-color: rgba(255,255,255,0.1);
  color: rgba(255,255,255,0.8);
  border-color: rgba(255,255,255,0.3);
  border-right:none;
  padding:6px 4px 6px 8px;
}

.headerSearchGroup{
  margin-left: 30px;
  width:100%;
  max-width: 400px;
  min-width:100px;
}

.headerSearchGroupLightMenu{
  width:100%;
  min-width:100px;
}

.headerSearchGroupLightMenu .headerSearch{
  margin-right:0px;
}

.headerSearch{
  margin-right:15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding:4px 10px 5px 10px ! important;
  border-radius:20px;
  height: 32px !important;
  background-color: rgba(255,255,255,0.1);
  color: rgba(255,255,255,0.8);
  border-color: rgba(255,255,255,0.4);
  border-left:none;
  transition: 0.3s;
}

.headerSearch:focus{
  box-shadow: none !important;
  border-color: rgba(255,255,255,0.4) !important;
}
 
@media only screen and (max-width: 768px) {
  .headerMenuBlock {
    display: none !important;
  }
  .headerLink{
    display:block;
  }
  .headerSearchGroup{
    display:none;
  }
  .headerMenuTxtAuth{
    display:none;
  }
  .modalButtonTxt{
    display:none;
  }
}

@media only screen and (min-width: 768px) {
  .headerMenuTxt {
    display: none;
  }
  .headerLink{
    display:none !important;
  }
  .headerLightMenuBlock {
    display: none !important;
  }
  .headerMenuTxtAuth{
    display: none;
  }
  .modalButtonTxt{
    display:none;
  }
}

@media only screen and (min-width: 992px) {
  .headerMenuIconLogOut{
    display:none;
  }
  .headerMenuTxtAuth {
    margin:0px 5px 0px 10px;
    display: block;
  }
  .headerMenuTxt {
    display: block;
  }
  .modalButtonTxt{
    display:block;
  }
  #appHeader .__react_component_tooltip{
    display:none;
  }

}

/* FLEX CONCATENATED -------------------------------------------------------------- */
 
.flexStartCenter{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexStartStart{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flexStartStartReverse {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row-reverse;
}

.flexStartEnd{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.flexEndCenter{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexEndStart{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.flexBetweenCenter{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexBetweenStart{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flexBetweenStrech{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.flexStartStrech{
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.flexCenterStrech{
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.flexCenterStart{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flexCenterCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexColCenterStart{
  display: flex;
  justify-content: center;
  align-items:flex-start;
  flex-direction: column;
}
.flexColStartEnd {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
}

.flexColStrechCenter{
  display: flex;
  justify-content: stretch;
  align-items:center;
  flex-direction: column;
}

.flexColStartStretch{
  display: flex;
  justify-content: flex-start;
  align-items:stretch;
  flex-direction: column;
}

.flexColStartStart{
  display: flex;
  justify-content: flex-start;
  align-items:flex-start;
  flex-direction: column;
}

.flexColStartCenter{
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction: column;
}

.flexColStartCenterReverse {
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction: column-reverse;
}

.flexColCenterCenter{
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
}

.flexColBetweenCenter{
  display: flex;
  justify-content: space-between;
  align-items:center;
  flex-direction: column;
}

.flexColBetweenStart{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.flexStartCenterReverse {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
}

 /* TEXT -------------------------------------------------------- */

 .textEllipsis{
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

 /* KEY FRAMES ----------------------------------------------------------- */
 
 @keyframes opacityIn {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
 
 @keyframes opacityInBg {
   from {
     opacity: 0;
   }
   to {
     opacity: 0.5;
   }
 }
 
 @keyframes slideInLeft {
   from {
     margin-left: -100%;
     width:0%;
   }
   to {
       margin-left: 0%;
       width:100%;
   }
 }

@keyframes slideInTop {
  from {
    top:50px;
    margin-top: -100%;
    height:0%;
  }
  to {
      margin-left: 0%;
      height:fit-content;
  }
}
 
 @keyframes slideInRight {
   from {
     margin-right: -100%;
     width:0%;
   }
   to {
       margin-right: 0%;
       width:100%;
   }
 }
 @keyframes enterFromRight {
  from {
      margin-right: -2em;
      opacity: 0;
  }

  to {
      margin-right: 0em;
      opacity: 1;
  }
}
 @keyframes heightIn {
   from {
     height: 0px;
   }
   to {
     height: 100%;
   }
 }
 
 @keyframes widthIn {
   from {
     width: 0px;
   }
   to {
     width: 100%;
   }
 }
 
 @keyframes slectedItem {
   0% {
     box-shadow: none;
   }
   50% {
     box-shadow: 0 0 0 3px rgb(13 110 253 / 25%);
   }
   100% {
     box-shadow: none
   }
 }

 @keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

 
/* BUTTON ----------------------------------------------------------  */
 
/* !!! reprendre tous les button définir class standard */

.modalQuote{
  margin-top: 10px;
  padding: 5px 10px;
  border-left: 4px solid var(--border-color-gray-0);
  font-weight: 500;
  color:var(--color-text-3)
}

.modalQuote a{
  margin-left: 5px;
  color:var(--color-migso-blue);
  font-weight: 500;
  transition: 0.3s
}

.modalQuote a:hover{
  color:var(--color-migso-blue-hover);
}

.modalQuoteWrong{
  color:var(--color-bad);
  margin-top: 0px;
  border-left-color: var(--color-bad-bg);
}


.modalButtonCollapse{
  font-size: 14px;
  font-weight: 500;
  background-color: var(--border-color-gray-0);
  border-radius: 0px;
  width: 100%;
  padding:10px ;
}

.modalButtonCollapse:focus{
  box-shadow: none;
}

.modalButtonSubmitLight{
  padding:0px;
  color: white;
  font-size: 30px;
  background-color: #ced4da;
  border-color: #ced4da;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height:35px;
  width:40px
}

.modalButtonSubmitLight:hover{
  background-color: var(--color-migso-blue) !important;
  color: white !important;
}

.modalButtonSubmitLight:focus{
  box-shadow: none !important;
  background-color: var(--color-migso-blue) !important;
  color: white !important;
}

.modalButtonSubmitLightInverse{
  padding:0px;
  color: #ced4da;
  background-color: white;
  border-color: #ced4da;
  height:35px;
  
}

.modalButtonSubmitLightInverse:focus{
  box-shadow: none !important;
  color: var(--color-migso-blue) !important;
}

.modalButtonSubmitCloseIcon{
  color: var(--color-text-3);
}
.modalButtonSubmitCloseIcon:focus{
  box-shadow: 0 0 0 0.25rem rgb(2 168 219 / 25%) !important;
}

.modalButtonSubmitCloseIcon .dataMappingButtonIcon{
  color: var(--color-migso-blue);
}

.modalButtonSubmit{
  color: #fff;
  background-color: var(--color-migso-blue);
  border-color: var(--color-migso-blue);
}

.modalButtonSubmit:hover{
  color: #fff !important;
  background-color: var(--color-migso-blue-hover) !important;
  border-color: var(--color-migso-blue-hover) !important;
}

.modalButtonSubmit:focus{
  box-shadow: 0 0 0 0.25rem rgb(2 168 219 / 25%) !important;
}

.modalButtonSubmit .dataMappingButtonIcon{
  color:white;
}

.modalButtonSubmitOutlineIcon{
  color: var(--color-text-4)!important;
  transition:0.3s
}

.modalButtonSubmitOutlineIcon:focus{
  box-shadow: 0 0 0 0.25rem rgb(2 168 219 / 25%) !important;
}

.modalButtonSubmitOutlineIcon .dataMappingButtonIcon{
  color: var(--color-migso-blue);
}

.modalButtonSubmitGreen{
  color: #fff;
  background-color: var(--color-migso-green);
  border-color: var(--color-migso-green);
}

.modalButtonSubmitGreen:hover{
  color: #fff !important;
  background-color: var(--color-migso-green-hover) !important;
  border-color: var(--color-migso-green-hover) !important;
}

.modalButtonSubmitGreen:focus{
  box-shadow: 0 0 0 0.25rem rgb(74 209 153 / 25%) !important;
}

.modalButtonSubmitGreen .dataMappingButtonIcon{
  color:white;
}

.modalButtonSubmitOutline{
  color: var(--color-text-1);
  background-color: #ffffff;
  border-color: var(--border-color-gray-1);
}

.modalButtonSubmitOutline:hover{
  color: var(--color-migso-blue-hover)!important;
  background-color: #fff !important;
  border-color: var(--color-migso-blue-hover) !important;
}

.modalButtonSubmitOutline:focus{
  box-shadow: 0 0 0 0.25rem rgb(2 168 219 / 25%) !important;
}

.modalButtonSubmitOutline .dataMappingButtonIcon{
  color: var(--color-migso-blue);
}

.modalButtonSubmitDangerOutline{
  color: var(--color-bad);
  background-color: #ffffff;
  border-color: var(--color-bad);
}

.modalButtonSubmitDangerOutline:hover{
  color: var(--color-bad-hover)!important;
  background-color: #fff !important;
  border-color: var(--color-bad-hover) !important;
}

.modalButtonSubmitDangerOutline:focus{
  box-shadow: 0 0 0 0.25rem rgb(229 87 102 / 25%) !important;
}

.modalButtonSubmitDangerOutline .dataMappingButtonIcon{
  color: var(--color-bad);
}

.modalButtonSubmitDangerOutlineIcon{
  color: var(--color-bad);
  border: none;
  font-size:14px;
  padding:5px;
}
.modalButtonSubmitDangerOutlineIcon:hover{
  color: var(--color-bad-hover)!important;
}
.modalButtonSubmitDangerOutlineIcon:focus{
  box-shadow: 0 0 0 0.25rem rgb(229 87 102 / 25%) !important;
}
.modalButtonSubmitDangerOutlineIcon .dataMappingButtonIcon{
  color: var(--color-bad);
}

.modalButtonSeparator{
  border-left:1px solid var(--border-color-gray-1);
  margin:0px 26px;
  height:35px;
}

.modalButtonSubmitDanger{
  color: #fff;
  background-color: var(--color-bad);
  border-color: var(--color-bad);
}

.modalButtonSubmitDanger:hover{
  color: #fff !important;
  background-color: var(--color-bad-hover) !important;
  border-color: var(--color-bad-hover) !important;
}
.modalButtonSubmitDanger:focus{
  box-shadow: 0 0 0 0.25rem rgb(229 87 102 / 25%) !important;
}

.modalButtonSubmitAlert{
  color: #fff;
  background-color: var(--color-alert);
  border-color: var(--color-alert);
}

.modalButtonSubmitAlert:hover{
  color: #fff !important;
  background-color: var(--color-alert-hover) !important;
  border-color: var(--color-alert-hover) !important;
}

.modalButtonSubmitAlert:focus{
  box-shadow: 0 0 0 0.25rem rgb(237 125 49 / 25%) !important;
}

.modalButtonSubmitCancel{
  color: #fff;
  background-color: var(--border-color-gray-2);
  border-color: var(--border-color-gray-2);
}

.modalButtonSubmitCancel:hover{
  color: #fff !important;
  background-color: var(--border-color-gray-3) !important;
  border-color: var(--border-color-gray-3) !important;
}

.modalButtonSubmitCancel:focus{
  box-shadow: 0 0 0 0.25rem rgb(190 190 190 / 25%) !important;
}

.modalButtonSubmitCancelOutline{
  color: var(--color-text-3);
  background-color: white;
  border-color: var(--border-color-gray-2);
}

.modalButtonSubmitCancelOutline:hover{
  color: var(--color-text-1) !important;
  background-color: white !important;
  border-color: var(--border-color-gray-3) !important;
}

.modalButtonSubmitCancelOutline:focus{
  box-shadow: 0 0 0 0.25rem rgb(190 190 190 / 25%) !important;
}

.modalButtonSubmitSuccess{
  color: #fff;
  background-color: var(--color-good);
  border-color: var(--color-good);
}

.modalButtonSubmitSuccess:hover{
  color: #fff !important;
  background-color: var(--color-good-hover) !important;
  border-color: var(--color-good-hover) !important;
}

.modalButtonSubmitSuccess:focus{
  box-shadow: 0 0 0 0.25rem rgb(0 176 79 / 25%) !important;
}

.modalButtonNoFocus:focus{
  box-shadow: none !important;
}

.modalButtonSubmitAlertIcon{
  font-size: 18px;
  padding:4px;
  border-radius: 14px;
  color: var(--color-migso-yeallow);
  background-color: white;
  border: 1px solid var(--color-migso-yeallow);
  transition: 0.3s;
  cursor:help;
  margin-left: 4px;
}

.modalButtonSubmitAlertIcon:hover{
  color: #fff !important;
  background-color: var(--color-migso-yeallow) !important;
  border-color: var(--color-migso-yeallow) !important;
}

.modalButtonSubmitLightIcon{
  font-size: 14px;
  padding:0px;
  border-radius: 14px;
  color: var(--border-color-gray-2);
  background-color: white;
  border: 1px solid var(--border-color-gray-2);
  transition: 0.3s;
  cursor:pointer;
  margin-left: 4px;
}

.modalButtonSubmitLightIcon:hover{
  color: #fff !important;
  background-color: var(--color-text-4) !important;
  border-color: var(--color-text-4) !important;
}

.modalButtonSubmitInfoIconLight{
  font-size: 14px;
  padding:2px;
  border-radius: 14px;
  background-color: white;
  border: 1px solid var(--border-color-gray-0-5);
  margin-left: 6px;
}

.buttonLabel{
  color:var(--border-color-gray-2);
  font-size: 14px;
  font-weight: 600;
  padding:3px 0px ;
  margin:14px 0px 8px 0px;
  background-color: rgba(200,200,200,0.15);
  border-top:1px solid var(--border-color-gray-0);
}

.buttonLabel:first-child{
  margin-top:0px;
}

.buttonSwitchSideBar{
  background-color: white;
  color:var(--color-text-3);
  border:1px solid white;
  transition: 0.1s;
  cursor: pointer;
  opacity:0.8;
  padding:4px;
  margin-bottom:-6px;
}

.buttonSideBar{
  background-color: white;
  color:var(--color-text-3);
  border:none;
  transition: 0.1s;
  cursor: pointer;
  opacity:0.8;
  margin-bottom: 5px;
  padding:5px;
  animation-name: opacityIn;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonSideBarBigComponent{
  font-size: 20px;
}

.buttonSideBarDisabled{
  color:var(--color-text-5) !important;
  cursor: default !important
}
.buttonSideBarDisabled:hover{
  color:var(--color-text-5) !important;
  opacity:0.8 !important;
}

.modalButtonWhiteBoxShadow{
  box-shadow: 0px 0px 2px 2px rgba(255,255,255,0.7);
}

.buttonGroup.buttonSideBar:last-child{
  margin-bottom: 0px;
}

.buttonSideBar:hover {
  opacity:1;
  color:var(--color-migso-blue) ;
}

.buttonSideBarTxt{
  font-weight: 500;
  margin: 0px 10px;
  white-space: nowrap;
  animation-duration: 300ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}

.buttonSideBarConfirmation{
  position: absolute;
  left:20px;
  white-space: nowrap;
  z-index: 999;
  background-color: white;
  border: 1px solid var(--color-bad);
  padding: 5px 10px;
  border-radius: 6px;
  color:var(--color-bad);
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0,0.3);
  animation-duration: 300ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}

.buttonSideBarConfirmation:hover{
  background-color: var(--color-bad-hover) !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0,0.8) !important;
}

.buttonSideBarConfirmation:hover .buttonSideBarConfirmationMessage{
  color:yellow;
}

.buttonSideBarConfirmationMessage{
  color:var(--color-text-2);
  font-weight: 400;
  font-size: 13px;
  transition: 0.3s;
}

.buttonSideBarSelected{
  background-color:var(--color-migso-blue);
  border-radius: 20px;
  color:white;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
  border:none !important;
}

.buttonSideBarSelected:hover {
  color:white !important;
  opacity:1;
}

.buttonSideBarBack{
  background-color:var(--color-text-4);
  border-radius: 20px;
  color:white;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
  border:none !important;
  transition: 0.3s;
}

.buttonSideBarBack:hover {
  color:white !important;
  background-color:var(--color-migso-blue);
  opacity:1;
}

.buttonSideBarAlert{
  background-color:var(--color-alert);
  border-radius: 20px;
  color:white;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
  border:none !important;
}

.buttonSideBarAlert:hover {
  color:white !important;
  opacity:1;
}

.buttonSideBarHighLight{
  color:var(--color-migso-blue) !important;
}
.buttonSideBarConfirmDelete {
  background-color:var(--color-bad);
  border-radius: 20px;
  color:white;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
  border:none !important;
}
.buttonSideBarConfirmDelete:hover {
  background-color: var(--color-bad-hover);
  color: white!important;
}

.buttonFillBad{
  background-color: var(--color-bad) !important;
  color:white !important;
  border-color: rgba(255,255,255,0.7)!important;
}

.buttonFillAlert{
  background-color: var(--color-alert) !important;
  color:white !important;
  border-color: rgba(255,255,255,0.7)!important;
}

/* ICON ----------------------------------------- */


.modalIconListDelete{
  color:var(--color-bad);
  font-size: 20px;
  font-weight: 600;
  margin-right: 8px;
}

.modalIconAction{
  color:var(--color-migso-blue);
  cursor: pointer;
  margin-left:10px;
  padding:4px;
  border-radius: 20px;
}

.modalIconAction:focus{
  box-shadow: none;
}

.modalIconActionFocus{
  color:white;
  background-color: var(--color-migso-blue);
  cursor: pointer;
  margin-left:10px;
  padding:4px;
  border-radius: 20px;
}

.modalIconActionFocus:hover{
  background-color:var(--color-migso-blue-hover);
  color:white;
}

.modalIconActionFocus:focus{
  box-shadow: none;
}

.modalIconDelete{
  color:var(--color-bad);
  cursor: pointer;
  margin-left:10px;
  padding:0px;
  font-size:24px;
  margin-left:5px
}

.modalIconAddDel{
  padding:2px;
  line-height: 1;
  background-color: var(--color-migso-blue);
  border-color: var(--color-migso-blue);
  color:white;
  font-size: 14px;
  border-radius: 12px;
  margin-left: 5px
}

.modalIconAddDel:hover{
  background-color: var(--color-migso-blue-hover);
  border-color: var(--color-migso-blue-hover);
  color:white;
}

/* TOOLTIP ------------------------------------------------------------------- */
  
.tooltipCommon{
  opacity: 0.85 !important;
  border-radius: 5px !important;
  max-width: 300px !important;
  z-index: 1200 !important;
  font-weight: 600;
}

.tooltipLight{
  opacity: 1 !important;
  border-radius: 5px !important;
  border : 1px solid var(--color-text-1)!important;
  color: var(--color-text-1)!important;
}

.tooltipText{
  font-weight: 600;
}

/* MODAL ------------------------------------ */

.modalContent{
  animation-duration: 200ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
  position: fixed;
  top: 30px;
  left:50%;
  margin-left: -250px;
  max-height: calc(100vh - 60px);
  z-index: 1060;
  width:500px;
  overflow: hidden;
}

.modalBg{
  animation-duration: 500ms;
  animation-name: opacityInbg;
  animation-iteration-count: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity:0.5;
}

.modalIconTitle{
  color:var(--color-migso-blue);
  margin-right: 10px;
}

.modalBlockBg{
  background-color: var(--border-color-gray-00);
}

.modalLabel{
  color:var(--color-text-1);
  font-weight: 600;
}

.modalLabelFooter{
  color:var(--color-text-1);
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 8px;
}

.modalInputAlert{
  color: var(--color-bad) !important;
  border: 1px solid var(--color-bad) !important;
  font-weight: 500 !important;
}

.modalInputAlert:focus{
  box-shadow: 0 0 0 0.25rem rgb(229 87 102 / 25%) !important;
  border-color: var(--color-bad);
  color: var(--color-bad);
}

.modalInputWarning{
  color: var(--color-alert);
  border-color: var(--color-alert);
  font-weight: 500;
}

.modalInputWarning:focus{
  box-shadow: 0 0 0 0.25rem rgb(237 125 49 / 25%) !important;
  border-color: var(--color-alert);
  color: var(--color-alert);
}

.modalInputRow{
  margin-bottom: 10px;
  border:1px solid var(--border-color-gray-0-5);
  padding: 5px 5px 10px 10px;
  border-radius: 8px;
  background-color: var(--border-color-gray-00);
}

/* MODAL INPUT */
.filterDateInputBlock {
  margin-left: 30px;
  padding-right: 10px;
}
.filterDateInput {
  width: 95%;
  outline: none;
  border: 1px solid var(--border-color-gray-2);
  padding: 5px 3px;
  border-radius: 5px;
}
.filterNumberInputBlock {
  margin: 5px 0px;
}
.filterNumberInput {
  width: 100%;
  margin-left: -15px;
  border: 1px solid var(--border-color-gray-3);
  outline: none;
  border-radius: 5px;
  padding: 0px 5px;
}
.reverseFlex {
  flex-direction: row-reverse;
}
.modalInputColorName{
  width:210px;
  height:23px;
  margin-right:15px;
  padding:0px;
  cursor: pointer;
  border:none;
  border-radius: 0px;
  font-weight: 500;
}

.modalInputColorName:focus-visible{
  padding:5px 8px;
  box-shadow: 0 0 0 0.25rem rgb(2 168 219 / 25%) !important;
  border: 1px solid var(--color-migso-blue);
  outline: none;
  border-radius: 4px;
}

.modalInputGroupName{
  background-color: rgba(255,255,255,0);
  width:100%;
  height:32px;
  padding:0px 4px 0px 0px;
  cursor:text;
  border:none;
  border-radius: 0px 4px 4px 0px;
  font-weight: 500;
  color:var(--color-text-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalInputGroupNameWhite{
  color:white;
}

.modalInputGroupName:focus-visible{
  border: none;
  outline: none;
  border-radius: 4px;
}

.modalInputColor{
  width:40px;
  height:23px;
  margin-right:15px;
  padding:0px;
  cursor: pointer;
  border:none;
  border-radius: 0px;
}

.modalInputColorTitle{
  font-weight: 500;
  margin-bottom: 5px;
}

.modalInputColorTxt{
  width:80px;
  height:25px;
  margin-right:12px;
  padding:0px 8px;
  border-radius: 4px;
  font-size: 14px;
}

.modalInputColorIcon{
  font-size: 22px;
  color:var(--color-text-3);
  cursor: pointer;
  transition: 0.3s;
}

.form-check-input{
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--color-migso-blue);
  border-color: var(--color-migso-blue);
}

.modalInputLegend{
  font-weight: 600;
  font-size: 14px;
  margin-right: 15px;
  /* color:var(--color-text-2) */
}

.modalItemFloat{
  float:left;
  margin-bottom: 10px;
  margin-right: 10px;
}

.modalSeparator{
  border-bottom: 1px solid var(--border-color-gray-0);
  width: 100%;
  margin:10px 0px;
}

.modalSeparatorbg{
  border-top: 1px solid var(--border-color-gray-0);
  width: 100%;
  margin-top:10px;
  height: 9px;
  background-color: var(--border-color-gray-00);
}

.modalGroup{
  width: 100%;
  margin-bottom:20px;
}

.modalInputLightViewMode{
  border-radius: 6px;
  font-weight: 500;
  color:var(--color-text-1);
  font-size: 13px;
  padding:4px 8px;
  /* height:27.5px */
}
.modalReactSelect {
  width: 100%;
}
.modalInputLightViewModeEmpty{
  border:none;
  background-color: var(--border-color-gray-00);
  border-radius: 6px;
  padding:4px 8px;
  margin:2px 0px;
  height:23.5px;
  width:100%
}

.modalInputCollapseButton{
  padding:4px 8px;
  background-color: var(--border-color-gray-00);
  border-radius: 6px;
  background-position: calc(100% - 5px);
  font-weight: 500;
  color: var(--color-text-2);
  font-size: 13px;
  margin-top: 10px;
}
.modalInputCollapseButton:focus{
  box-shadow: none;
}

.modalInputLight{
  border:none;
  border-bottom: 1px solid var(--border-color-gray-0-5);
  border-right: 1px solid var(--border-color-gray-0-5);
  background-color: var(--border-color-gray-00);
  border-radius: 6px;
  background-position:calc(100% - 5px);
  font-weight: 500;
  color:var(--color-text-2);
  transition:0.3s
}
.modalInputLight::placeholder{
  color:var(--color-text-5)
}
.modalInputLight:focus{
  box-shadow: none;
  border-color:var(--color-migso-blue);
  color:var(--color-text-1);
  background-color: var(--border-color-gray-00-5);
}
.modalInputLight:hover{
  background-color: var(--border-color-gray-00-5);
}


.modalInputEnrichTxt{
  border:none;
  background-color: transparent;
  background-position:calc(100% - 5px);
  font-weight: 500;
  color:var(--color-text-2);
  transition:0.3s
}
.modalInputEnrichTxt::placeholder{
  color:var(--color-text-5)
}
.modalInputEnrichTxt:focus{
  box-shadow: none;
  border-color:var(--color-migso-blue);
  color:var(--color-text-1);
  background-color: var(--border-color-gray-00-5);
}
.modalInputEnrichTxt:hover{
  background-color: white;
}

.modalInputLight2{
  border: 1px solid var(--border-color-gray-0-5);
  background-color: var(--border-color-gray-00);
  border-radius: 6px;
  background-position:calc(100% - 5px);
  font-weight: 500;
  color:var(--color-text-2);
  transition:0.3s
}
.modalInputLight2::placeholder{
  color:var(--color-text-5);
  font-weight: 400;
}
.modalInputLight2:focus{
  box-shadow: none;
  border-color:var(--border-color-gray-1);
  color:var(--color-text-1);
  background-color: var(--border-color-gray-00);
}
.modalInputLight2:hover{
  background-color: var(--border-color-gray-00);
}

.modalSelectLight{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23aaaaaa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.modalSelectLight:hover{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2302a8db' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.modalSelectLight:focus{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2302a8db' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}



.modalSelectLoading{
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-text-2);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none;
}

.modalSelectBlock{
  width: 100%;
  padding:10px 20px;
}

.modalGroup:last-child{
  margin-bottom:0px;
}

.modalGroupTitle{
  font-weight: 600;
  font-size: 14px;
  margin-right: 15px;
  min-width:150px;
  max-width:150px;
}

.modalGroupTitleBig{
  font-weight: 600;
  font-size: 14px;
  margin: 0px 15px;
  min-width:140px;
  max-width:140px;
}

.modalGroupTitleTop{
  font-weight: 600;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.modalClearIcon{
  margin-left:10px;
  color:var(--color-bad);
  cursor: pointer;
}

.modalItemIcon{
  color: var(--border-color-gray-1);
  margin-right:8px;
}

.modalBody{
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}

.modalBlockInfo{
  width:100%;
}

.modalPreview{
  margin-bottom: 10px;
  font-weight: 500;
  padding: 5px 10px;
}

.modalSpecificInfo{
  background-color: var(--border-color-gray-0);
  width:100%;
  padding:10px 15px;
  border-radius: 6px;
}

.modalDetail{
  background-color: var(--border-color-gray-0);
  width:100%;
  padding:5px;
  border-radius: 6px;
  white-space: pre;
  min-height: 33px;
  max-height: 112px;
  overflow: auto;
}

.modalDetailItem{
  background-color: white;
  width: fit-content;
  font-size: 12px;
  padding:2px 5px;
  float: left;
  margin: 0px 5px 5px 0px;
  border-radius: 4px;
  font-weight: 500;
  color:var(--color-text-1);
  border:1px solid var(--border-color-gray-0-5)
}

.modalConfirmationTxt{
    white-space: nowrap;
    background-color: var(--color-bad);
    cursor: pointer;
    padding: 2px 6px;
    border-radius: 8px;
    color: white;
    margin-left: 10px;
    transition: 0.3s;
}

.modalConfirmationTxt:hover, .modalConfirmationTxt:focus{
  color: white;
  box-shadow: 0 0 0 0.25rem rgb(229 87 102 / 25%) !important;
}

.modalBlockInfoLink{
  background-color: var(--border-color-gray-0);
  border: 1px solid var(--border-color-gray-0-5);
  padding: 5px 10px;
  border-radius: 4px;
}

.modalSwitchLinkButton{
  margin: 0px 15px;
  cursor: pointer;
  transition: 0.3s;
}

.modalConfirmationTxtIcon{
  margin-right: 4px;
}

.modalOptionBlock{
  border: 1px solid var(--border-color-gray-0);
  width: 100%;
  padding:10px;
  border-radius: 8px;
  margin-bottom: 15px;
  transition: 0.3s all ease;
}

.modalBodyBlockOver:hover{
  box-shadow: 0px 0px 4px 0px rgb(100,100,100);
}

.modalOptionBlock:last-child{
  margin-bottom: 0px;
}

.modalOptionBlockChildren{
  margin:20px 20px 10px 20px;
  animation-duration: 300ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}

.modalOptionBlockTitle{
  background-color: var(--border-color-gray-00);
  width:100%;
  padding:10px ;
  border-bottom: 1px solid var(--border-color-gray-0);
  border-radius: 8px 8px 0px 0px;
  color: var(--color-text-3);
  font-weight: 500;
}

.modalOptionBlockTitleReset{
  transition: 0.3s;
  cursor: pointer;
}

.modalOptionSubBlock{
  width: 100%;
  padding:10px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.modalOptionSubBlock:last-child {
  margin-bottom: 10px;
}

.modalOptionsSubBlockNoMargin:last-child {
  margin-bottom: 0px!important;
}
.modalOptionSubBlockGroup{
  width: 100%;
  padding:0px;
  border-radius: 8px;
}

.modalOptionSubBlockBg{
  width: 100%;
  padding:10px 20px;
  background-color: var(--border-color-gray-00);
}

.modalOptionBlockTitleIcon{
  margin-right:6px;
  color:var(--color-text-2);
  opacity: 0.4;
}

.modalBgInputStart{
  padding:10px;
  background-color: var(--color-migso-blue-light2);
}

.modalBgInputFinish{
  padding:10px;
  background-color: var(--color-good-bg);
}

.modalOptionInputValue{
  padding:0px 5px;
  width: 40px;
}

.modalOptionInputValue:focus{
  box-shadow: none;
}

.modalBodyBlockButtons{
  background-color: var(--border-color-gray-00);
  border-top:1px solid var(--border-color-gray-0);
  padding-right: 5px;
}

.modalBodyBlockButton{
  padding:2px 7px 2px 3px;
  font-size:14px;
  margin: 10px;
}

.modalBodyBlockInputIcon{
  color:var(--border-color-gray-1-5);
  margin-right: 15px;
  transition:0.3s;
  cursor: pointer;
}

.modalBodyColorPicker{
  position: fixed;
  background-color:white;
  padding:0px 0px 5px 5px;
  max-width: 105px;
  color:white;
  z-index:101;
  border-radius: 4px;
  box-shadow: 1px 1px 4px 0px rgb(50,50,50);
  animation-duration: 500ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}

.modalBodyColorPickerItem{
  width: 20px;
  height: 20px; 
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
  border: 1px solid var(--border-color-gray-2)
}

.modalTable{
  width:calc(100% - 40px);
  margin: 10px 20px;
  border: 1px solid var(--border-color-gray-0);
}

.modalTable tr th {
  font-weight: 500;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  color:var(--color-text-2)
}

.modalTableRow{
  border-top: 1px solid var(--border-color-gray-0)
}

.modalTableRow:nth-child(1){
  background-color: var(--border-color-gray-00);
}

.modalTableRowIcon{
  font-size: 22px;
  color: var(--border-color-gray-2);
  cursor: pointer;
  transition: 0.3s;
}

.modalTableRowIconConfirm{
  background-color:var(--color-bad) ;
  color: white;
  padding:0px 5px;
  margin: 0px 5px;
  border-radius: 4px;
  white-space: nowrap;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0,0.5);
}

.modalValueCurrentSessionUpdated{
  width: calc(100% - 40px);
  padding:10px 20px 10px 5px;
  margin:5px 20px 15px 20px;
  background-color: var(--color-good-bg);
  color:var(--color-good);
}

.modalValueCurrentSessionNotUpdated{
  width: calc(100% - 40px);
  padding:10px 20px 10px 5px;
  margin:5px 20px 15px 20px;
  background-color: var(--color-alert-bg);
  color:var(--color-alert);
}

.modalColumnTitle{
  font-weight: 500;
  margin-bottom: 20px;
  color:var(--color-text-3)
}

.modalSelectLegend{
  background-color: var(--border-color-gray-0);
  border:1px solid var(--border-color-gray-1);
  border-radius: 5px;
  width:100%;
  margin-right: 15px;
  padding: 6px 8px;
}

.modalErrorBlock{
  background-color: var(--color-bad-bg);
  color: var(--color-bad);
  border: 1px solid var(--color-bad);
  margin: 20px;
  border-radius:6px;
  width:calc(100% - 40px);
  padding:10px 20px;
}

.modalAlertBlock{
  background-color: var(--color-alert-bg);
  color: var(--color-alert);
  border: 1px solid var(--color-alert);
  margin: 20px;
  border-radius:6px;
  width:calc(100% - 40px);
  padding:10px 20px;
}

.modalCorrectionBlock{
  color: var(--color-text-2);
  margin: 0px 20px 20px 20px;
  border-radius:6px;
  width:calc(100% - 40px);
}

.modalInputGroupName{
  background-color: rgba(255,255,255,0);
  width:100%;
  height:32px;
  padding:0px 4px 0px 0px;
  cursor:text;
  border:none;
  border-radius: 0px 4px 4px 0px;
  font-weight: 500;
  color:var(--color-text-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalInputGroupNameWhite{
  color:white;
}

.modalInputGroupName:focus-visible{
  border: none;
  outline: none;
  border-radius: 4px;
}

/* HOME ------------------------------------------------------------- */


.homeWelcome {
  height: calc(100vh - 80px);
  width: 100%;
  /* backgroundImage: "url(" + picVagueBg + "), linear-gradient(130deg, #0070C0 0%, #4291DC 29%, #00CFD1 55%, #69FFD8 92%, #00E6AA 100%)", */
  background-image: linear-gradient(130deg, #0070C0 0%, #4291DC 29%, #00CFD1 55%, #69FFD8 92%, #00E6AA 100%);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  padding-bottom: 50px;
}

.gradientText {
  background-image: linear-gradient(175deg, #0070C0 20%, #4291DC 50%, #00CFD1 75%, #69FFD8 92%, #00E6AA 100%);
  background-clip: text;
  color: transparent;
  font-size: clamp(20px, 4vw, 70px);
  font-family: Sarabun, sans-serif;
  font-weight: 900;
}


.homeWelcomePage {
  width: 100%;
  max-height: calc(100vh - 80px);
  padding: 5% 10%;
  position: relative;
}

.homeHalfPageContainer {
  z-index: 1;
  width: 50%;
  height: 100%;
}

.homeButton {
  color: #fff;
  width: fit-content;
  padding: 5px 30px;
  border-radius: 5px;
  font-family: "Sarabun";
  font-size: clamp(10px, 1.5vw, 30px);
  font-weight: 600;
  box-shadow: 0px 0px 20px rgba(201, 201, 201, 0.3);
  cursor: pointer;
  z-index: 1;
}

.homeButton:hover {
  transform: scale(1.04);
  transition: 500ms;
}

.homeStepNumberTriangle {
  width: 10%;
  height: 10%;
}

.homeStepTitle {
  font-family: Sarabun;
  font-weight: 800;
  font-size: clamp(10px, 2vw, 60px);
  color: var(--color-migso-blue);
}

.homeStepTitleContainer {
  height: 10%;
  width: 100%;
  gap: 20px;
  margin-bottom: 2%;
}

.homeStepTextContainer {
  width: 80%;
  height: 100%;
}

.homeStepText {
  color: var(--border-color-gray-2);
  font-family: Sarabun;
  font-weight: 600;
  font-size: clamp(12px, 1.4vw, 50px);
}

.homeStepBulletTriangle {
  width: 40%;
  height: 40%;
  filter: brightness(0) saturate(100%) invert(61%) sepia(81%) saturate(316%) hue-rotate(103deg) brightness(101%) contrast(84%);
}


.homeStepBulletBox {
  height: 80%;
  width: 70%;
  background-color: #fff;
  padding: 5% 3%;
}

.homeStepTableContainer {
  width: 100%;
  height: 90%;
  gap: 50px;
}

.homeStepContentContainer {
  width: 100%;
  height: 30%;
}

.homeStepTriangleContainer {
  height: 100%;
  width: 15%;
}

.homeImgBox {
  width: 40vw;
  height: 60vh;
}

.homeStepLine {
  height: 2px;
  width: 100%;
  background-color: var(--border-color-gray-2);
  margin-top: 5px;
  border-radius: 6px;
}

.homeQualitiesTableContainer {
  width: 40vw;
  height: 70vh;
}

.homeQualitiesTitle {
  font-size: clamp(20px, 3.2vw, 70px);
  font-family: Sarabun, sans-serif;
  font-weight: 900;
  color: var(--color-migso-blue);
}

.homeQualitiesTitleContainer {
  width: 100%;
  height: 10%;
  margin-bottom: 10%;
}

.homeQualitiesLineContainer {
  width: 80%;
  height: 20%;
}

.homeQualitiesLine {
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.homeQualitiesSecurityIcon {
  width: 45%;
  filter: brightness(0) saturate(100%) invert(59%) sepia(46%) saturate(5342%) hue-rotate(160deg) brightness(95%) contrast(98%);
  opacity: 0.5;
}

.homeQualitiesGreenIcon {
  width: 45%;
  filter: brightness(0) saturate(100%) invert(61%) sepia(81%) saturate(316%) hue-rotate(103deg) brightness(101%) contrast(84%);
  opacity: 0.5;
}

.homeQualitiesTextContainer {
  width: 80%;
  height: 100%;
}

.homeQualitiesTopicBigContainer {
  width: 100%;
  height: 25%;
}

.homeQualitiesTopicSmallContainer {
  height: 80%;
  width: 100%;
}

.homeQualitiesBigBlockContainer {
  width: 100%;
  height: 70%;
  gap: 5px;
}

.homeQualitiesTopicIconContainer {
  width: 20%;
  height: 100%;
}

.homeQualitiesText {
  color: var(--color-text-3);
  font-family: Sarabun;
  font-weight: 600;
  font-size: clamp(12px, 1.3vw, 30px);
}

.homeLeapIcon {
  margin: auto;
  height: 20%;
  width: 20%;
  filter: invert(59%) sepia(46%) saturate(5342%) hue-rotate(160deg) brightness(95%) contrast(98%);
}

.homeLeapIconContainer {
  width: 90%;
  height: 15%;
  z-index: 1;
  gap: 2%;
}

.homeLeapIconBlock {
  width: 18%;
  height: 100%;
}

.homeLeapTextContainer {
  width: 90%;
  height: 45%;
  z-index: 1;
  margin: 5% 0 6%;
}

.homeLeapTextBlock {
  width: 18%;
  height: fit-content;
  background-color: rgb(248, 249, 251);
  padding: 1%;
}

.homeLeapTextTitle {
  font-size: clamp(10px, 1.3vw, 90px);
  font-family: Sarabun;
  font-weight: 800;
  color: var(--color-migso-blue);
  margin-bottom: 5%;
}

.homeLeapText {
  font-size: clamp(8px, 1.2vw, 60px);
  font-family: Sarabun, sans-serif;
  color: var(--color-text-3);
  font-weight: 500;
}

.homeBoxShadow {
  box-shadow: 0px 0px 10px #E9E9E9;
  border-radius: 12px;
  z-index: 1;
}

.homePatternBackground {
  background-image: url(../../../00-Core/02-Pictures/cvtStar.svg), url(../../../00-Core/02-Pictures/cvtTriangle5.svg), url(../../../00-Core/02-Pictures/cvtRect.svg), url(../../../00-Core/02-Pictures/cvtCircle.svg);
  background-repeat: no-repeat;
  background-size: 50px 50px, 80px 80px, 60px 60px, 90px 90px;
  background-position: 1vw 30vh, 10vw 3vh, 50vw 10vh, 85vw 8vh;
  opacity: 0.1;
  filter: brightness(0) saturate(100%) invert(46%) sepia(85%) saturate(579%) hue-rotate(169deg) brightness(81%) contrast(99%);
  z-index: 0;
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
}

.homePatternBackgroundTop {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.homePatternBackground2 {
  background-image: url(../../../00-Core/02-Pictures/cvtCircle.svg), url(../../../00-Core/02-Pictures/cvtRect.svg), url(../../../00-Core/02-Pictures/cvtStar.svg), url(../../../00-Core/02-Pictures/cvtTriangle6.svg);
  background-repeat: no-repeat;
  background-size: 30px 30px, 60px 60px, 80px 80px, 40px 40px;
  background-position: 5vw 40vh, 10vw 3vh, 92vw 30vh, 80vw 8vh;
  opacity: 0.1;
  filter: brightness(0) saturate(100%) invert(46%) sepia(85%) saturate(579%) hue-rotate(169deg) brightness(81%) contrast(99%);
  z-index: 0;
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
}


.homeTopFeatures{
  padding-bottom: 60px;
}
.homeCustomerPain{
  padding-bottom: 50px;
  background-color: white;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0,0.3);
}

.homeWelcomeBlockTxt{
  margin-left: 10%;
  width:45%;
  padding-top: 30px
}
.homeTopFeaturesBlockTxt{
  margin-left: 10%;
  width:45%;
  padding-top: 20px
}
  .homeWelcomeBlockTxtTitle{
    color:white;
    font-size: 30px;
  }

  .homeWelcomeBlockTxtHighlight{
    margin: 30px 20px;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    background-color: var(--color-migso-darkBlue);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-transform: skewX(-10deg);
    transform: skewX(-10deg);
  }

  .landingPage_applicationName {
    height: fit-content;
    font-family: 'Sarabun', cursive;
    font-size: clamp(20px, 2.2vw, 50px);
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    /* text-shadow: 1px 1px 2px grey; */
  }

.landingPage_catchPhrase{
  color:rgb(255, 255, 255,0.7);
  font-size: 22px;


  font-family: "Alliance No.1",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";   
}

  .homeWelcomeBlockTxtDesc{
    color:white;
    opacity:0.8;
    font-size: 16px;
  }
  .homeWelcomeBlockTxtDesc a{
    color: var(--color-migso-yeallow);
  }
 

  .homeTopButton{
    font-weight: 500;
    cursor: pointer;
    color:var(--color-migso-blue);
    transition: 0.3s;
    opacity:0.8
  }
  .homeTopButton:hover{
    opacity:1
  }


.homeTopFeaturesList .accordion-button:not(.collapsed) {
  color: white;
  background-color: var(--color-migso-green);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.homeTopFeaturesList .accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.homeTopFeaturesList .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.homeTemplateItem{
  padding:8px 15px;
  border-bottom: 1px solid var(--border-color-gray-0);
  cursor: pointer;
  transition: 0.3s;
  color:var(--color-text-2);
}
.homeTemplateItem:last-child{
  border-bottom: none;
}
.homeTemplateItemSelected{
  padding:8px 15px;
  border-bottom: 1px solid var(--color-migso-blue-light);
  cursor: pointer;
  transition: 0.3s;
  background-color: var(--color-migso-blue-light);
  color:var(--color-migso-blue);
}

.homeTemplateItem:hover{
  background-color: var(--color-migso-blue);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0,0.3);
  color:white;
  border-bottom-color:var(--color-migso-blue);
}

.homeTemplateItemProject{
  font-weight:400;
  font-style: italic;
  color: var(--color-text-4);
  margin-left: 5px;
  font-size:12px;
}

.homeTemplateItem:hover .homeTemplateItemProject{
  color:white;
}
.homeTemplateItemInfo{
  font-size: 11px;
  color:var(--color-text-4);
  margin-right: 10px;
  white-space: nowrap;
  float:left;
  margin-top: 3px;
}

.homeTemplateItem:hover .homeTemplateItemInfo, .homeTemplateItemSelected .homeTemplateItemInfo{
  background-color: white;
  padding:2px 6px;
  border-radius: 4px;
  font-weight: 500;
  color:var(--color-text-2);
}

.homeTemplateItem .spinner-grow{
  color: var(--color-migso-blue);
}
.homeTemplateItem:hover .spinner-grow{
  color: white;
}


/* UPDATER ------------------------------------------- */

.updaterInput{
  width:100%;
  border:none !important;
  background-color: rgba(0,0,0,0);
  font-weight: 500;
  z-index:1000;
}

.updaterInput:focus-visible{
  outline: none;
}

.updaterInputGroup{
  background-color: var(--border-color-gray-0);
  border-radius: 6px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0,0.5);
  animation-duration: 300ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}


/* CONTEXT MENU ------------------------------------------------------------------ */

.contextMenuContent{
  position: fixed;
  z-index:2001;
  background-color: white;
  border-radius: 6px;
  border:1px solid var(--border-color-gray-1);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0,0.5);
  transition: all 0.5s ease 0s;
  animation-duration: 300ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}
.contextMenuTitle{
  padding: 8px 10px;
  border-bottom:1px solid var(--border-color-gray-1);
  text-align: left;
  font-weight: 700;
  color:var(--color-text-1);
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.contextMenuSeperator{
  border-bottom:1px solid var(--border-color-gray-0);
  margin:5px 0px;
}
.contextMenuAction{
  font-weight: 600;
  color:var(--color-text-1);
  padding:3px 10px 5px 8px;
  transition: 0.1s ;
  cursor: pointer;
}
.contextMenuAction:hover{
  background-color: var(--border-color-gray-0);
}
.contextMenuActionDelete:hover{
  color:var(--color-bad) !important;
}

.contextMenuConfirmDelete{
  background-color: var(--color-bad);
  color:white ;
  border-radius:  6px;
  margin: 5px;
  padding: 5px ;
}
.contextMenuConfirmDelete button{
  padding:0px 5px;
  background-color: white;
  border:none;
  font-weight: 600;
  border-radius: 5px;
  margin-left:8px;
}
.contextMenuConfirmDelete button:hover{
  color: var(--color-bad);
}
.contextMenuConfirmDelete:hover{
  background-color: var(--color-bad);
  color:white ;
}
.contextMenuConfirmDelete .contextMenuActionIcon{
  color:white ;
}
.contextMenuConfirmDelete:hover .contextMenuActionIcon{
  color:white !important;
}

.contextMenuActionIcon{
  font-size: 20px;
  margin-right:5px;
  color:var(--border-color-gray-1);
  transition: 0.1s ;
}
.contextMenuHighLight{
  color:var(--color-good) !important;
}

.contextMenuActionIconSelected{
  color:var(--color-migso-blue) !important;
}
.contextMenuAction:hover .contextMenuActionIcon{
  color:var(--color-migso-blue);
}
.contextMenuActionDelete:hover .contextMenuActionIcon{
  color:var(--color-bad) !important;
}

.contextMenuBg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: 0.3s;
  animation-duration: 500ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}





/* TOASTS ---------------------------------------- */

.appToast{
  position:fixed;
  bottom: 15px;
  left: 15px;
  width: 400px;
  z-index: 1051;
  animation-duration: 200ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
  border-radius: 10px;
  padding:10px;
  font-weight: 600;
  color:white;
  cursor: pointer;
  box-shadow:0px 0px 8px 0px rgba(0, 0, 0,0.5);
}

/* ALERT --------------------------------------------- */

.alertContent{
  font-size: 14px;
  padding:8px 15px 8px 10px;
  border-radius: 6px;
}
.alertIcon{
  margin-right: 10px;
}
.alertIconGlobal{
  color:var(--color-migso-yeallow)
 }
 .alertContentGlobal{
   background-color: var(--bg-color-selected-2);
   border:1px solid rgba(252, 199, 8,0.3)
 }
.alertIconInfo{
 color:var(--color-migso-yeallow)
}
.alertIconInfoLigth{
  color:var(--border-color-gray-1-5)
 }
.alertContentInfo{
  background-color: var(--bg-color-selected-2);
  border:1px solid rgba(252, 199, 8,0.3)
}
.alertContentInfoModalFooter{
  width: 65%;
  border-radius: 8px;
  padding: 5px;
  color:var(--color-text-2);
}
.alertContentDangerModalFooter{
  width: 100%;
  border-radius: 8px;
  padding: 5px;
  background-color: var(--color-bad-bg);
  color:var(--color-text-2);
  font-weight: 600;
  border:1px solid rgba(229, 87, 102,0.5);
}
.alertIconModal{
  margin-right: 12px;
  font-size: 22px;
  color:var(--color-migso-blue)
}

.alertContentSuccess{
  background-color: var(--color-good-bg);
  border:1px solid rgba(0, 176, 79,0.3);
}
.alertIconInfoSuccess{
  color:var(--color-good)
 }

 .alertContentDanger{
  background-color: var(--color-bad-bg);
  color:var(--color-bad);
  border:1px solid rgba(229, 87, 102,0.5);
}
.alertIconInfoDanger{
  color:var(--color-bad);
 }

 .alertSpinner{
  height: 20px;
  width: 20px;
  margin-right: 10px;
  color:var(--color-migso-yeallow);
 }



.cardSubTitle{
  font-weight:500;
  color:var(--color-text-2)
}

.cardOptionsBlock{
  background-color: var(--border-color-gray-00);
  border:1px solid var(--border-color-gray-0-5);
  border-radius: 8px;
  margin: 10px 0px 15px 0px;
  max-height: 400px;
  overflow: auto;
  
}
.cardOptionsBlockTitle{
  color:var(--color-text-3);
  padding: 10px 20px;
}

.cardOptionsBlockInputBlock{
  width: 100%;
  margin-bottom: 10px;
  padding:0px 20px;
  color:var(--color-text-2)

}

.cardOptionsBlockInputBlockTxt{
  font-weight: 500;
  margin-left: 10px;
}

.cardOptionsBlockButton{
  color: #fff;
  background-color: var(--color-migso-blue);
  border-color: var(--color-migso-blue);
  padding: 0px 8px 0px 8px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
}
.cardOptionsBlockButton:hover{
  color: #fff !important;
  background-color: var(--color-migso-blue-hover) !important;
  border-color: var(--color-migso-blue-hover) !important;
}
.cardOptionsBlockButton:focus{
  box-shadow: 0 0 0 0.25rem rgb(74 209 153 / 25%) !important;
}

.cardOptionsBlockTxt{
  margin:0px 20px 15px 20px;
  font-weight: 500;
  color:var(--color-text-2)
}
.cardImage{
  animation-duration: 500ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}

/* ORGANIZE GROUPS MODAL -------------------------------- */

.organizeGroupsBody{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.organizeGroupGroupContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px 20px 20px;
  transition: 0.3s all ease-in-out;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.organizeGroupSubGroupContainer {
  width: 50%;
  border-left: 1px solid var(--border-color-gray-1);
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.organizeGroupTitle {
  font-weight: 600;
  padding: 0.2em;
  font-size: 1rem;
}

.organizeGroupItem {
  margin: 5px 0px;
  border: 1px solid var(--border-color-gray-1);
  height: 34px;
  border-radius: 4px;
  transition: 0.3s;
  background-color: white;
}

.organizeGroupItemName{
  font-weight: 500;
  color:var(--color-text-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%
}

.organizeGroupItemDragIndicator {
  color: var(--color-migso-blue);
  font-size: 20px;
  margin: 5px ;
}

.organizeGroupItemSwitch{
  font-size: 20px;
  cursor: pointer;
  color:var(--color-text-4);
  margin-right: 4px;
  transition:0.3s
}
.organizeGroupItemSwitchClose:hover{
  color:white !important
}

.organizeGroupItemPalette{
  font-size: 18px;
  cursor: pointer;
  color:var(--color-text-5);
  margin-right: 4px;
  transition:0.3s
}

.organizeGroupItemPaletteMngt{
  background-color:var(--border-color-gray-00);
  border:1px solid var(--border-color-gray-1);
  margin-top: -6px;
  margin-bottom: 5px;
  border-radius: 0px 0px 4px 4px;
}

.organizeGroupNbSub{
  background-color: white;
  min-width: 18px;
  max-width: 18px;
  min-height:18px;
  max-height:18px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 500;
  background-color: var(--color-migso-blue-light);
  color:var(--color-text-2);
  margin: 0px 5px;
}


/* SEELCT ----------------------------------------------- */

.basic-multi-select{
  width:100%;
}

.css-1pahdxg-control{
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 /25%) !important;
  border-color: #86b7fe !important;
}

.css-1s2u09g-control{
  border: 1px solid #ced4da !important;
}
.css-1s2u09g-control:hover{
  border-color: #ced4da !important;
}

/* STRUCTURE BLOCK ----------------------- */

#structureContent{
  animation-duration: 800ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
  width: 100%;
  position: relative;
  height: fit-content;
  max-height: 100%;
  padding:30px;
  transition: all 0.5s ease 0s; 
}
.structureBlock{
  background-color: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0,0.2);
  border-radius: 10px;
  transition:0.3s;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin:0px 20px;
}

.structureBlockProject{
  border-radius: 10px;
  width: 100%;
  transition:0.3s;
  position: relative;
  background-color:var(--border-color-gray-0);
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 20%);
  overflow-y: auto;
  overflow-x: auto;
}

.structureBlockProjectBody{
  max-height: calc(100vh - 195px);
  background-color:var(--border-color-gray-0);
}

.structureBlockTitle{
  padding: 10px 15px;
  background-color: var(--border-color-gray-00);
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid var(--border-color-gray-0);
  font-size: 16px;
  font-weight: 700;
  color: var(--color-text-3);
  position: relative;
  z-index: 101;
  margin:0px 0px -1px 1px;
}

.structureBlockTitleFirstCell{
  border-right: 1px solid var(--border-color-gray-1);
  height:100%;
  padding:0px 10px 0px 15px;
  font-size: 14px;
  font-weight: 700;
  position: sticky;
  left: 0;
  z-index: 1000;
  background-color: white;
}


@media only screen and (max-width: 1200px) {
  .structureBlockTitleCellTitle {
    display: none;
  }
}


.structureBlockTitleCellIcon{
  color:var(--color-migso-blue);
  opacity:0.5;
  font-size: 22px;
  margin-right: 5px;
}

.structureBlockTitleCellLegend{
  color:var(--border-color-gray-2);
  font-size: 10px;
  text-align: center;
}

.structureBlockTitleIcon{
  font-size: 25px;
  margin-left:-4px;
  margin-right:10px;
  color:var(--color-text-3);
}


.structureBlockInfo{
  padding: 5px 10px 10px 10px;
  font-weight: 500;
  color:var(--color-text-2);
}
.structureBlockInfoIcon{
  margin-right: 8px;
  color:var(--color-text-4);
}

/* STRUCTURE --------------------------- */

.structureRow{
  font-weight: 600;
  padding: 0px;
  animation-duration: 300ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
  transition: 0.3s;
  position:relative;  
  box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.7);
  background-color: white;
}

.structureRowTitle{
  padding: 10px 10px;
  background-color: white;
  height:100%;
  transition: 0.3s;
  border-right: 1px solid var(--border-color-gray-1);
}
.structureRowTitle a{
  color:var(--color-migso-blue)
}

.structureRowLeftMargin{
  position:sticky;
  margin-left: -20px;
  background-color:var(--border-color-gray-0);
  width: 20px;
}

.structureBlockTitleCell{
  width:100%;
  height: 100%;
  color:var(--color-text-3);
  font-weight: 500;
  padding: 0px 4px 0px 4px;
  border-right: 1px solid var(--border-color-gray-0);
  position:relative;
  transition: 0.6s;
}
.structureBlockTitleCell:last-child{
  border-right: none;
}

.structureRowTitleIcon{
  display: flex;
  align-items: center;
  padding: 2px;
  height: 20px;
  border-radius: 10px;
  margin: 0px 14px 0px 8px;
  border-color:white;
}

.structureRowTitleIconDisable{
  background-color: var(--border-color-gray-1);
  border-color: white ;
  cursor: default;
}

.structureRowTitleIconBack{
  font-size:24px;
  color: var(--color-text-4);
  cursor: pointer;
  margin-right: 10px;
  transition: 0.3s;
  border: 1px solid var(--border-color-gray-0-5);
  border-radius: 24px;
  background-color: white;
}
.structureRowTitleIconBack:hover{
  color: var(--color-migso-blue);
}

.structureRowWbsName{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color:var(--color-text-2)
}
.structureRowWbsNamehasChildren {
  cursor: pointer;
}
.structureRowWbsNamehasChildren:hover {
  color: var(--color-migso-blue-hover);
}


.structureRowDetailContent{
  width:100%;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
  border-radius: 6px;
  margin:10px 20px ;
  position: relative;
  animation-duration: 300ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}

.structureRowDetailContentFooter{
  background-color: var(--border-color-gray-00-5);
  border-top: 1px solid var(--border-color-gray-1);
  padding: 10px 20px;
  border-radius: 0px 0px 6px 6px;
}

.structureRowDetailContentBody{
  padding:20px
}

.structureRowDetailContentClose{
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--color-text-2);
  color:white;
  border-radius: 12px;
  padding:4px;
  cursor: pointer;
  top:-6px;
  transition:0.3s;
  left:calc(100% - 16px);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
}
.structureRowDetailContentClose:hover{
  background-color: var(--color-bad-hover);
}

.structureRowLink{
  cursor: pointer;
  color:var(--color-text-2);
  transition: 0.3s;
}
.structureRowLink:hover{
  color:var(--color-migso-blue);
}

.structureRowSummary:hover{
  background-color: var(--border-color-gray-0);
}
.structureRowSummary:hover .structureRowTitle{
  color: var(--color-migso-blue)
}
.structureRowSummarySelect{
  background-color: var(--border-color-gray-0);
}
.structureRowSummarySelect .structureRowTitle{
  color: var(--color-migso-blue)
}

.structureRowSummaryTitle:hover{
  background-color: var(--color-migso-blue);
}
.structureRowSummaryTitle:hover .structureRowTitle{
  color: white
}
.structureRowSummaryTitleSelect{
  background-color: var(--color-migso-blue);
}
.structureRowSummaryTitleSelect .structureRowTitle{
  color: white
}
.structureRowFooter{
  padding: 20px ;
  background-color: var(--border-color-gray-00);
  border-top:1px solid var(--border-color-gray-1);
  margin-top: 30px;
}
.structureTopButton:hover{
  color:var(--color-migso-blue);
  cursor: pointer;
  transition:0.3s
}

.colorLvl-0{border-bottom-color:var(--border-color-gray-3) !important;}
.colorLvl-1{border-bottom-color:var(--border-color-gray-2-5)  !important;}
.colorLvl-2{border-bottom-color:var(--border-color-gray-2)  !important;}
.colorLvl-3{border-bottom-color:var(--border-color-gray-1-5)  !important;}
.colorLvl-4{border-bottom-color:var(--border-color-gray-1)  !important;}
.colorLvl-5{border-bottom-color:var(--border-color-gray-0-5)  !important;}
.colorLvl-6{border-bottom-color:var(--border-color-gray-0)  !important;}
.colorLvl-7{border-bottom-color:var(--border-color-gray-00-5)  !important;}
.colorLvl-8{border-bottom-color:var(--border-color-gray-00)  !important;}

/* MARKDOWN DOC ---------------------------- */
.blockMarkDown h1{
  margin: 20px;
  color: var(--color-text-1);
  font-size: 24px;
  font-weight: 600;
}

.blockMarkDown h2{
  margin: 20px;
  color: var(--color-text-2);
  font-size: 18px;
  font-weight: 700;
}

.blockMarkDown p{
  margin: 20px;
  color: var(--color-text-2);
  font-size: 14px;
}

.blockMarkDown h3{
  margin: 20px;
  padding-bottom: 10px;
  color: var(--color-text-2);
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px dashed var(--border-color-gray-1);
  width: fit-content;
}

.blockMarkDown h4{
  margin: 20px;
  padding-bottom: 0px;
  color: var(--color-text-2);
  font-size: 14px;
  font-weight: 700;

}

.blockMarkDown blockquote p{
  margin: 0px 20px;
  border-left: 0.25rem solid var(--border-color-gray-1);
  padding: 0 1rem;
  color: var(--color-text-4);
}

.blockMarkDown code{
  display : inline-block;
  padding: 0 0.5rem;
  background-color: var(--border-color-gray-0);
  border-radius: 0.25rem;
  color:var(--color-text-1)
}

.blockMarkDown pre code{
  margin: 0px 20px 0px 20px;
  width: calc(100% - 40px);
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #152939;
  overflow-x: auto;
  border-radius: 0.25rem;
  padding: 1rem 0px 1rem 1rem;
  color: white;
}

.blockMarkDown a{
  color: var(--color-migso-blue);
  font-weight: 500;
}

.blockMarkDown a:hover{
  color: var(--color-migso-blue-hover);
}

.blockMarkDown ul {
  margin:20px;
  padding: 10px 30px ;
  background-color: var(--border-color-gray-00-5);
  border: 1px solid var(--border-color-gray-0);
  border-radius: 6px;
}
.blockMarkDown ul ul {
  margin:10px 0px;
  padding: 0px 30px;
  border: none
}
.blockMarkDown ul ul li{
  color:var(--color-text-2)
}
.blockMarkDown ul ul li li{
  color:var(--color-text-3)
}

.blockMarkDown hr{
  margin:30px 0px;
  background-color: var(--border-color-gray-2);
}

.blockMarkDown img{
  margin:0px 20px 10px 20px;
  padding: 20px;
  width:calc(100% - 40px);
  border: 1px solid var(--border-color-gray-0);
  border-radius: 6px;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
}

.blockMarkDown table{
  margin:20px;
  width: calc(100% - 40px);
  color: #212529;
  vertical-align: top;
  border: 1px solid var(--border-color-gray-1);
}

.blockMarkDown table>:not(caption)>*>*{
  border-bottom-width:0px;
  padding: 0.5rem 0.5rem;
  background-color: transparent;
}

.blockMarkDown table>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--border-color-gray-00-5);
}

.blockMarkDown table>:not(:first-child) {
  border-top: 1px solid var(--border-color-gray-1);
}

/* INPUT PHONE NUMBER ---------------------------------------------------- */

.PhoneInput {
	display: flex;
	align-items: center;
  padding:0px 20px 20px 20px
}
.PhoneInputInput {
	flex: 1;
	min-width: 0;
  font-size: 14px;
  padding: 6px 8px;
  margin-top: 3px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.PhoneInputInput:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.PhoneInputCountryIcon {
	width: 35px;
	height: 35px;
}
.PhoneInputCountryIcon--square {
	width: 1em;
}
.PhoneInputCountryIconImg {
	display: block;
	width: 100%;
	height: 100%;
}
.PhoneInputInternationalIconPhone {
	opacity: 0.8;
}
.PhoneInputInternationalIconGlobe {
	opacity: 0.65;
}
.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: 8px;
}
.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}
.PhoneInputCountrySelect[disabled] {
	cursor: default;
}
.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: 8px;
	height: 8px;
	margin-left: 5px;
	border-style: solid;
	border-color: inherit;
	border-top-width: 0;
	border-bottom-width: 1px;
	border-left-width: 0;
	border-right-width: 1px;
	transform: rotate(45deg);
	opacity: 0.45;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--color-migso-blue);
}

/* PASSWORD */

/* INPUT */
.global_read_only_text-area:disabled {
  background-color: var(--border-color-gray-0);
}

.global-input-box:disabled {
  background-color: var(--border-color-gray-0);
}

/* COMPONENT: Table */
.table_row_selectable {
  cursor: pointer;
}

/* COMPONENT: EditDeleteButtons */
.EditDeleteButtons_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: fit-content;
}
@keyframes enterFromRight {
  from {
      margin-right: -2em;
      opacity: 0;
  }

  to {
      margin-right: 0em;
      opacity: 1;
  }
}
.EditDeleteButtons_button {
  animation-name: enterFromRight;
  animation-timing-function: ease;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  font-size: 14px;
  padding: 5px;
  margin: 0px 2px;
  height: fit-content;
  max-height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-text-1);
  background-color: #fff;
  border: 1px solid var(--border-color-gray-1);
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.EditDeleteButtons_button:hover {
  cursor: pointer;
}

.EditDeleteButtons_edit_button:hover {
  color: var(--color-migso-blue-hover) !important;
  background-color: #fff !important;
  border-color: #0297c6 !important;
  border-color: var(--color-migso-blue-hover) !important;
}

.EditDeleteButtons_delete_button_enabled {
  color: var(--color-bad) !important;
  background-color: #fff !important;
  border-color: var(--color-bad) !important;
}

.EditDeleteButtons_delete_button_disabled {
  color: grey;
  background-color: #fff !important;
  border-color: grey !important;
}

.EditDeleteButtons_delete_button:focus {
  box-shadow: 0 0 0 0.25rem rgb(229 87 102/25%) !important;
}

.EditDeleteButtons_delete_button_sure {
  height: 28px;
  font-size: 0.7rem;
  background-color: red;
  color: white;
}

/* BigComponent Header components  */
.BigComponentHeaderComponent {
  margin-left: 10px;
}

/* InfoDotTag */
.InfoDotTag {
  border: 1px solid var(--border-color-gray-0);
  height: 30px;
  font-weight: 700;
  border-radius: 15px;
  background-color: white;
  color: var(--color-text-1) !important;
  justify-content: space-between;
  padding: 2px 5px 0px 12px ;
  font-size: 11px;
}

.InfoDotTag_rightDot {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  font-weight: 500;
  margin-top: -2px;
  padding-top: 1px
}

/*/ ////////////////////////////////////////////////////////////
// BIG COMPONENT                                     //
//////////////////////////////////////////////////////////////*/

.bigComponent {
  box-shadow: 0px 0px 4px #888888;
  border-radius: 10px;
  animation-duration: 200ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
  overflow-y: visible;
  background-color: white;
}

.bigComponentProjectCard{
  background-color: var(--border-color-gray-00);
  padding: 15px 20px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--border-color-gray-0);
  box-shadow:0px 1px 2px 0px rgb(0 0 0 / 20%);
}

.bigComponentFullScreen{
  position: fixed;
  top: 0;
  z-index: 1000;
}

.bigComponentBody {
  padding: 20px 0px;
  background-color: white;
  height: 100%;
  width:100%;
  border-radius: 0px 0px 10px 0px;
}

.bigComponentBodySection{
  padding: 0px 20px;
  border-right: 1px solid var(--border-color-gray-0-5);
}
.bigComponentBodySection:last-child{
  border-right: none;
}

.bigComponentBodyGreyBlock{
  /* background-color: var(--border-color-gray-00-5); */
  /* width: calc(50% - 25px); */
  margin-left: 15px;
  padding: 0px 15px ;
  border-radius: 6px;
}

.bigComponentBodyMiniBlockTitleUnit{
  font-size: 11px;
  font-style: italic;
  color:var(--color-text-5);
  white-space: nowrap;
}

.bigComponentBodyMiniBlockTitle{
  color:var(--color-text-2);
  font-weight:700;
  margin-bottom:10px;
  width:100%;
}

.bigComponentBodyMiniBlock{
  /* border:1px solid var(--border-color-gray-0-5); */
  /* padding: 10px 15px 15px 15px; */
  border-radius:6px;
  /* box-shadow:0px 1px 2px 0px rgb(0 0 0 / 20%); */
  overflow: auto;
}

.bigComponentBodyMiniBlockInfo{
  border:1px solid var(--border-color-gray-0-5);
  border-top: none;
  padding: 10px 15px ;
  border-radius:0px 0px 6px 6px;
  overflow: auto;
  font-size: 12px;
  color:var(--color-text-3);
  max-height:calc(100% - 140px);
  white-space: break-spaces;
}

.bigComponentBodyMiniBlockInfoProject{
  border:1px solid var(--border-color-gray-0-5);
  border-radius:0px 8px 8px 8px; 
  font-size: 12px;
  height: 200px;
  color:var(--color-text-3);
  width: 100%;
  white-space: break-spaces;
  border-top: none;
}


.bigComponentNavItem{
  padding:5px 10px;
  font-size: 13px;
  color:var(--color-text-4)
}

.biComponentNavBar{
  border-bottom:1px solid var(--border-color-gray-0-5);
  border-radius:6px;
  position:relative;
  overflow: auto;
}
.biComponentNavBarCard{
  border-bottom:1px solid var(--border-color-gray-0-5);
  border-radius:6px;
  position:relative;
  overflow: hidden;
}

.biComponentNavBar::-webkit-scrollbar, 
.biComponentNavBar::-webkit-scrollbar-thumb,
.bigComponentBodyMiniBlockInfo::-webkit-scrollbar, 
.bigComponentBodyMiniBlockInfo::-webkit-scrollbar-thumb{
  border-radius: 0px 6px 6px 0px;
}

.bigComponentNavItem:hover{
  color:var(--color-migso-blue) !important;
  border-color: var(--border-color-gray-00-5) !important;
  border-bottom-color: #dee2e6 !important;
}
.bigComponentNavItem:focus{
  color:var(--color-migso-blue) !important
}

.bigComponentNavItem.active{
  font-weight: 500 !important;
  color:var(--color-text-2) !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.bigComponentNavItem.active:hover{
  color:var(--color-text-2) !important;
  cursor:default;
  border-color: #dee2e6 !important;
  border-bottom-color: #ffffff !important;;
}
.bigTemplate {
  background-color: white;
  border-radius: 8px;
}
.bigComponentHeaderProjectTitleIcon {
  margin-right: 10px;
  color: var(--color-migso-blue);
}
.bigComponentHeader {
  background-color: var(--border-color-gray-00-5);
  padding: 0px 10px;
  border-bottom: 1px solid var(--border-color-gray-1);
  font-weight: 700;
  color: var(--color-text-2);
  z-index: 51;
  position:relative;
  /* border-radius: 10px 10px 0px 0px; */
}
.bigComponentHeaderIcon {
  margin-right: 8px;
  font-size: 22px;
}


.bigTemplate_header_projectTitle {
  padding: 10px;
}

.bigTemplate_header_projectTitle_icon {
  margin-right: 10px;
  color: var(--color-migso-blue);
}

.bigTemplate_header_projectTitle_title {
  font-weight: bold;
}

.bigTemplate_header_dimension {
  background-color: var(--border-color-gray-00-5);
  padding: 10px;
  border-bottom: 1px solid var(--border-color-gray-1);
  font-weight: 700;
  color: var(--color-text-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bigTemplateTitleAndComponents {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.bigTemplateTitle_Components_Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.bigTemplateTitle_text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bigTemplateTitle_icon {
  margin-right: 8px;
  font-size: 22px;
  margin-left: 4px;
  color:var(--border-color-gray-3)
}

.bigTemplateTitle_buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* AFTER CREATING CANCEL ICON BTN */
.big_template_header_after_create_close_icon_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color-gray-1);
  padding: 0.2em;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.big_template_header_after_create_close_icon_btn:hover {
  background-color: var(--color-grey-light);
  border-color: var(--color-migso-blue-hover);
}

.close_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  border: 1px solid var(--border-color-gray-2);
  color: white;
}

.close_icon {
  font-size: 1.2rem;
}



.keyboard_arrow_down_container {
  position: absolute;
  bottom: -15px;
  animation-duration: 600ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
}

.arrow_down {
  width: 0; 
  height: 0; 
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 15px solid var(--color-migso-blue);
}

.wbsRightComponent{
  width: 100%;
  min-width: 500px;
  /* max-height: calc(100vh - 140px); */
  margin: 30px 30px 0px 0px;
}

.wbsRightComponent::-webkit-scrollbar, 
.wbsRightComponent::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.wbsElementBigContainer {
  margin: 10px;
}
.wbsNoItemBlock {
  height: 60px;
  margin-top: 30px;
  padding: 0px 30px;
  font-weight: 600;
  color: var(--color-text-3);
  background: var(--border-color-gray-00);
  border: 1px solid var(--border-color-gray-1);
  border-radius: 8px;
  transition: 0.3s;
}
.wbsNoItemBlock:hover {
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
}
.wbsNoItemBlockIcon{
  color:var(--color-migso-green);
  font-size: 30px;
}
.smallComponent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.smallComponent-wrap {
  width: fit-content;
}
.smallComponent-wrap {
  border-radius: 4px;
  color: white;
  width: fit-content;
  padding: 4px 12px;
}

.bigComponentCommandBarBlock{
  background: white;
  padding: 4px 8px;
  margin: 6px 15px;
  border: 1px solid var(--border-color-gray-1);
  border-radius: 6px;
  
}

.bigComponentCommandBarBlockLegend{
  color: var(--color-text-3);
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  margin-right: 3px;
  min-width: 112px;
}

.bigComponentCommandBarBlockLegendHighlight{
  color: var(--color-text-2);
  font-weight: bold;
  margin: 0px 0px 0px 4px;
}

.bigComponentCommandBarBlockButton{
  background-color: var(--color-migso-blue);
  color:white;
  font-size: 18px;
  border-radius: 10px;
  padding:2px 0px 0px 0px;
  height:18px;
  width:18px
}
.bigComponentCommandBarBlockButton:hover{
  background-color:var(--color-migso-blue-hover);
  color:white
}
.bigComponentCommandBarBlockButtonDisabled{
  background-color: var(--color-text-4);
  cursor:default;
}
.bigComponentCommandBarBlockButtonDisabled:hover{
  background-color: var(--color-text-4);
}


/*/ ////////////////////////////////////////////////////////////
//              BIG COMPONENT EXPANDED MODE                   //
//////////////////////////////////////////////////////////////*/



.tree_big_component_row {
  padding: 20px;
}

.big_component_container_full_screen {
  position: fixed;
  z-index: 1000;

  top: 88px;
  left: 0;

  width: 100%;
  height: calc(100% - 110px);

  border-radius: 10px;
}

.big_component_container_full_screen .bigComponent {
  height: 100%;
}

.big_component_container_full_screen .bigComponentBody {
  max-height: none;
  min-height: 0px;
  height: calc(100% - 100px);
}

/*/////////////////////////////////////////////////////////////
//                         ADD BLOCK                        //
/////////////////////////////////////////////////////////////*/

.addBlock {
  flex-direction: column;
}

.addBlockWithColumns {
  display: flex;
  flex-grow: 1;
  gap: 20px;
  overflow-x: scroll;
}

.addBlockColumn {
  background-color: var(--border-color-gray-0);
  border: 1px solid var(--border-color-gray-1);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.addBlock_title {
  height: 50px;
  font-size: 16px;
  font-weight: 700;
}

.addBlock_input {
  min-height: 60px;
}

.addBlock_title_follow_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


/* ACTIONS ------------------------------------------------------- */

.actionMiniTooltipTitle{
  color: var(--color-migso-yeallow);
  margin-top:10px
}
.actionMiniTooltipTitle:first-child{
  margin-top: 0px;
}
.actionMiniTooltipSubTitle{
  font-weight: 400;
  margin-left:5px
}

/* ACTION CARD INFOS ---------------------------------------------- */

.actionCardInfos{
  width:50%;
  height: 100% ;
  margin-left: 20px;
}
.actionCardInfosTitle{
  margin: 1px 0px 0px 10px;
  padding-bottom: 2px;
  font-size: 16px;
  width:fit-content;
  font-weight: 500;
  word-break: break-word;
}
.actionCardInfosTitleEdition{
  width: 100%;
  height: 100%;
  margin-left: 8px;
  margin-top:-2px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--border-color-gray-0-5) !important;
  padding: 0px 10px;
  border-radius: 4px;
  animation-name: opacityIn;
  animation-duration: 300ms;
  animation-iteration-count: 1;
}
.actionCardInfosPrivateIcon{
  color:var(--color-migso-yeallow) !important
}
.actionCardInfosPrivateIcon:hover{
  color:var(--color-migso-yeallow-hover) !important
}
.cardInfosBody{
  border: 1px solid var(--border-color-gray-0-5);
  border-radius: 8px;
  width:100%;
  height: calc(100% - 42px);
  margin-top: 10px;
}
.actionCardInfosBodyLeft{
  padding:10px;
  background-color: var(--border-color-gray-00-5);
  max-width: 120px;
  min-width: 120px;
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid var(--border-color-gray-0);
}
.actionCardInfosBodyLeftStatus{
  font-weight: 500;
  padding: 2px 10px;
  color: white;
  border-radius: 15px;
  white-space:nowrap;
}
.actionCardInfosProgressBar{
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  width:10px;
  margin:15px 5px 15px 5px;
  height: 100%;
  border-radius: 6px;
}
.actionCardInfosProgressBarDate{
  position:absolute;
  left:-5px;
  transition: 0.3s;
}
.actionCardInfosProgressBarDateDot{
  width:20px;
  border-radius: 10px;
  height: 20px;
  margin-right: 5px;
  background-color:var(--border-color-gray-3);
  font-size: 16px;
  cursor: help;
  color:white;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.20);
  border: 1px solid rgba(255, 255, 255, 0.7)
}
.actionCardInfosProgressBarDateLegend{
  font-size: 10px;
  font-weight: 500;
  background-color: white;
  padding:0px 4px;
  border:1px solid var(--border-color-gray-0-5);
  border-radius: 5px;
}

/* ACTION CARD DETAILS //!!! COULD BE COMMON ------------------ */

.cardDetailsNoData{
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text-3)
}

.actionCardDetails{
  width:50%;
  height: 100%;
  margin: 0px 20px;
}
.cardDetailsBlockInfo{
  border:1px solid var(--border-color-gray-0-5);
  border-radius:0px 8px 8px 8px;
  overflow: auto;
  font-size: 12px;
  color:var(--color-text-3);
  width:100%;
  white-space: break-spaces;
}
.cardDetailsBlockInfo::-webkit-scrollbar, 
.cardDetailsBlockInfo::-webkit-scrollbar-thumb{
  border-radius: 6px 6px 6px 6px;
}
.cardDetailsTitleCounter{
  width: 16px;
  height:16px;
  font-size: 10px;
  border-radius: 8px;
  margin-right: 6px;
  font-weight: 700;
  background-color: var(--border-color-gray-1);
}
.cardDetailsComment{
  position:sticky;
  top:0px;
  background-color: white;
  padding:1px;
  border-bottom: 1px solid var(--border-color-gray-0);
}
.cardDetailsCommentInputBlock{
  margin: 10px;
  padding: 0px 4px;
  background: white;
  border: 1px solid var(--border-color-gray-0-5);
  border-radius: 5px !important;
  width: calc(100% - 20px)!important;
}
.cardDetailsCommentInputBlockUpdating{
  background-color: var(--bg-color-selected-2);
}
.cardDetailsCommentInput{
  margin: 8px;
  padding: 0px 4px;
  border-radius: 5px;
  width: calc(100% - 16px)!important;
  border: none;
  color: var(--color-text-2);
  font-size: 14px;
}
.cardDetailsCommentInput:focus {
  outline: none;
  border: none;
}
.cardDetailsCommentInput::placeholder {
  font-style: italic;
  color: var(--color-text-5);
}
.cardDetailsCommentInputValidate {
  color: var(--color-text-4);
  cursor: pointer;
  border-radius: 5px;
  font-size: 22px;
  padding: 3px;
  transition: 0.3s ;
  animation-name: opacityIn;
  animation-duration: 300ms;
  animation-iteration-count: 1;
}
.cardDetailsCommentInputValidate:hover {
  font-weight: 500;
  color: var(--color-migso-blue);
}
.cardDetailsCommentItem {
  margin: 12px 10px;
  padding: 0px 4px;
  background: var(--border-color-gray-00-5);
  border: 1px solid var(--border-color-gray-0-5);
  border-radius: 5px;
  width: calc(100% - 20px);
}
.actionCardDetailsEvent{
  margin: 10px;
  padding: 4px 6px;
  background: var(--border-color-gray-0);
  border: 1px solid var(--border-color-gray-1);
  border-radius: 5px;
  width: calc(100% - 20px)!important;
}

/* ACTION CHART --------------------------------------------------------- */

.actionsChartContainer {
  width: 100%;
  height: 100%;
  background: white;
  margin: 0px 20px;
  border: 1px solid var(--border-color-gray-0);
  border-radius: 8px;
  /* transition:0.5s width */

}
.actionsChartHeader {
  width: 100%;
  min-height: 42px;
  position: relative;
  padding: 0px 5px 0px 10px;
  color: var(--color-text-2);
  position:relative
}
.actionsChartHeaderTitle {
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
}
.actionsChartHeaderIcon{
  color: var(--color-text-5);
  margin-right: 10px;
}
.actionsChartContainer:hover > .actionsChartHeader > .actionsGraphExpandArrow  {
  display: block;
}
.actionsChartBody{
  width: 100%;
  height: calc(100% - 42px);
  padding: 0px 15px 0px 10px;
}
.leftComponentExpandButton{
  position: absolute;
  top:-5px;
  right: -6px;
  border: 1px solid var(--border-color-gray-1);
  background-color: white;
}
/* ACTION TABLE -- //!!! COULD BE COMMON -------------------------------- */

.actionTableContainer {
  max-height: 100%;
  margin: 0px 20px;
  position: relative;
  transition:0.5s width;
}
.actionTableContainer:hover >  .actionsTableExpandArrow {
  display: block;
}
.actionTableContainerHeader{
  padding: 10px 10px 10px 5px;
  background-color: var(--border-color-gray-00-5);
  border:1px solid var(--border-color-gray-0);
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
  position:relative
}
.tableSearch{
  border-radius: 20px;
  font-size: 12px;
}
.tableSearch:focus{
  box-shadow: none;
  border-color: #ced4da
}
.tableSearchIcon{
  margin-left: -50px;
  margin-right: 10px;
  user-select: none;
  color: var(--border-color-gray-2);
  font-size: 22px;
  transition: 0.3s;
}
.tableTotal{
  margin-left: 10px;
  white-space: nowrap;
  color: var(--color-text-2);
  font-weight: 600;
  font-size: 12px;
}
.tableTotalValue{
  background-color: var(--color-text-2);
  color: var(--border-color-gray-00-5);
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 10px;
  font-weight: 600;
  font-size:12px;
  margin-left: 5px;
}
.tableSearchIconClose:hover{
  color: var(--color-bad);
}
.actionsTableLegendTag {
  padding: 2px 5px;
  white-space: nowrap;
  font-size: 12px;
  color: white;
  border-radius: 15px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  margin-right: 5px;
  transition:0.3s
}
.actionsTableLegendTag:hover{
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.5);
}
.actionsTableLegendTagNoHover:hover{
  box-shadow: none;
}
.actionsTableLegendTagSelected{
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.5);
}
.actionTable {
  width:100%  ;
  padding:0px 10px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  max-height: calc(100% - 92px);
  background-color: var(--border-color-gray-00-5);
  border:1px solid var(--border-color-gray-0);
  border-top: none;
  border-bottom: none;
}
.actionTable::-webkit-scrollbar {
  border-radius: 10px;
}
.actionTable::-webkit-scrollbar-thumb {
  border-radius: 8px;
}
.actionTableCreateButton {
  color:var(--color-text-2);
  background-color: var(--border-color-gray-00-5);
  border:1px solid var(--border-color-gray-0);
  border-top: none;
  cursor:pointer;
  padding: 2px 10px 10px 10px ;
  border-radius: 0px 0px 8px 8px;
}
.actionTableCreateButtonContent{
  color: var(--color-text-4);
  border-radius: 0px;
  transition:0.3s
}
.actionTableCreateButtonContent:hover{
  background-color: white;
  color: var(--color-text-4) !important; 
}
.actionTableCreateButtonContent:focus{
  box-shadow: none !important;
}
.actionTableSelectBox, .actionTableSelectBoxActive {
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  margin-top: 13px;
  border: 1px solid var(--border-color-gray-1);
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 10px;
  font-weight: 600;
  color: white;
}
.actionTableSelectBox:hover {
  background-color: var(--border-color-gray-0);
}
.actionTableSelectBoxActive {
  background-color: var(--color-migso-blue);
  border-color: white;
}
.actionTableSelectBoxMiActive {
  background-color: var(--color-text-4);
  border-color: white;
}
.actionTableSelectBoxMiActive:hover  {
  background-color: var(--color-text-3);
}

.actionTableSelectBoxActive:hover {
  background-color: var(--color-migso-blue-hover);
}


.actionTableItemContainer {
  background: white;
  margin-bottom: 2px;
  border-top: none;
  padding: 0px 0px 0px 8px;
  transition: 0.3s;
  border-bottom: 1px solid white;
}
.actionTableItemContainerClick{
  cursor: pointer;
  padding: 9px 8px 9px 0px;
  margin-left: 8px;
  width:100%;
}
.actionTableItemContainer:hover{
  background-color: var(--bg-color-selected-2);
}
.actionTableItemContainerSelected{
  background-color: var(--bg-color-selected-2);
}
.actionTableItemDisplayId {
  margin-left: 2px ; 
  margin-top: 2px;
  color: var(--color-text-4);
  font-size: 12px;
  font-weight: 500;
}
.actionTableItemName {
  color: var(--color-text-3);
  font-weight: 500;
  margin-left: 10px;
  word-break: break-word;
  /* max-width: 70%; */
}
.actionTableItemLockIcon {
  font-size: 18px;
  margin-top: 2px;
  color: var(--color-migso-yeallow);
  cursor: pointer;
  margin-left: 8px;
}
.actionTableItemLinkIcon {
  font-size: 16px;
  padding: 2px;
  outline: 1px solid var(--color-migso-blue);
  color: var(--color-migso-blue);
  margin-right: 5px;
  border-radius: 12px;
  opacity: 0.8
}
.actionTableItemNew{
  font-size: 10px;
  font-weight: 500;
  background-color: white;
  padding:0px 4px;
  border:1px solid var(--border-color-gray-0-5);
  border-radius: 5px;
  margin:3px 8px 0px 8px;
  color:var(--color-good)
}
.actionTableItemWeek{
  background: var(--border-color-gray-1);
  padding: 2px 4px;
  font-weight: 500;
  font-size: 11px;
  color: var(--color-text-3);
  border-radius: 5px;
  margin-left: 3px;
}
.actionTableExpandButton{
  position: absolute;
  top:-5px;
  left: -6px;
  border: 1px solid var(--border-color-gray-1);
  background-color: white;
  z-index: 49;
}


/* ADD EDIT CREATED MODIFIED NEXT TO BIG HEADER BUTTONS CONTAINER */
.add_edit_created_modified_container {
  display: flex;
  flex-direction: column;
  min-width: 15vw;
  width: fit-content;
  font-size: 10px;
  margin: -10px 0px;
}


.action-big-add-edit-block {
  animation-name: rightToLeftWidth;
  overflow-x: hidden;
  flex-grow: 1;
  animation-duration: 800ms;
  animation-iteration-count: 1;

}


.action-big-add-edit-title {
  padding: 0.5em;
  border-bottom: 1px solid lightgray;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-edit-title {
  font-size: 1rem;
  font-weight: 600;
}

.add-edit-created-modified-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  margin-right: 1em;
}

.action-big-add-edit-inputs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.actionBig_addEdit_inputs_containerColumn{
  display: flex;
  flex-direction: column;
  border: 2px solid var(--border-color-gray-0);
  border-radius: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  transition: 3s all;
}




.action-big-add-edit-input-container {
  width: 100%;
  display: flex;
  /* background-color: green; */
  flex-direction: row;
  justify-content: flex-start;
  transition: all 0.3s ease-in-out;
  /* z-index: 999; */
}

.action-big-add-edit-status-preview-container {
  display: flex;
  flex-direction: row;
  transition: all 0.3s ease-in-out;
  padding:0.5em;
  width: fit-content;
  margin-bottom: 20px;
  align-items: center;
  align-self: center;
  border-radius: 0.5rem;
  border: 3px solid var(--border-color-gray-0);
}

/* ALERT ICON FOR RO ITEM CLOSED */

.closed_ro_alert_icon_container {
  margin-left: 1em;
  padding: 0.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.30rem;
  color: white;
  background: var(--color-alert);
}

.actions-data-display-graph-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* min-width: 50%; */
  min-height: 300px;
  z-index: 0;
  transition: 0.3s all ease-in-out;
}


.actions-list-container {
  display: flex;
  flex-direction: column;
  /* border: 1px solid var(--border-color-gray-0); */
  border-radius: 4px;
  background: white;
  transition: all 0.3s ease-in-out;
  border-top: none;

  border-radius: 8px 0px 0px 0px;
}

.actions-list-container-top-functions {
  display: flex;
  flex-direction: row;
  position: sticky;
  justify-content: flex-start;
  align-items: center;
  z-index: 50;
  /* background-color: var(--bg-app); */
  padding: 0.3em 0.5em;
}

.actions-list-container-expand-table-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size:0.9rem;
  margin-right: -1em;
  padding: 0.2em 0;
  padding-right: 0.5em;
  min-width: 16vw;
  height: 80%;
  border:1px solid lightgrey;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: white;
  color: var(--color-text-1);
  border-radius: 0.4rem;
  
}

.actions-list-container-expand-table-button:hover {
  border-color: var(--border-color-gray-4);
  color: var(--color-migso-blue-hover);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0,0.2);
  
}
.action-list-container-status-select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.3em;
}

.action-list-container-name-search-input-container {
  margin-left: 5em;
  transition: all 0.3s ease-in-out;
}

.actions-list {
  width:98%;
  padding: 4px 30px;
  background-color: var(--border-color-gray-00);
  display: table;
  border-bottom: 1px solid var(--border-color-gray-0);
  height: fit-content;
}

.actions-list-header {
  /* grid-area: head-fixed; */
  min-width: 100%;
  position: sticky;
}


.actions-list-item-title {
  font-weight: 700;
  color: #5a5a5a;
  color: var(--color-text-2);
}

.actions-list-item-info {
  margin:2px 0px;
  padding: 0.3em 0.5em;
  text-align: left;
  border: 2px solid var(--border-color-gray-0);

  text-align: center;
  justify-content: center;
  align-items: center;
  /* background-color: var(--border-color-gray-1); */
  background: var(--color-grey-light);
}

.actions-list-item-info-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.8rem;
}

.actions-list-content-container {
  display: inline-block;
  max-height: 24vh;
  overflow-y: auto;
  overflow-x: hidden;
}


.ellipsis-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions_details_table_tag_container{
  display: flex;
  flex-direction: row;
  
  /* background-color: var(--color-grey-light); */
  background-color: white;
  padding: 0.3em;
  border-radius: 0.25rem;
  align-items: center;
  margin-left: 5px;
  transition: 0.2s all ease-in-out;
}
.actions_details_table_tag_container:hover {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0,0.3);
  background-color: lightblue;
}

.action_big_table_tags_container {
  display: flex;
  flex-direction: row;
  padding: 0.5em 0.1em;
  /* border-left: 1px solid var(--border-color-gray-1);
  border-right: 1px solid var(--border-color-gray-1); */
  /* background-color: var(--bg-app); */
}

.actions_details_tag_total_text{
  font-size: 0.9rem;
  padding: 0em 0.3em;
  font-weight: 700;
}

.actions_big_table_add_btn_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.table_add_btn {
  background:var(--color-good);
  width: fit-content;
  margin-right: 1em;
  padding: 0.2em 0.5em;
  border-radius: 0.25rem;
  cursor: pointer;
  color: white;
  font-size: 0.9rem;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0,0.2);
  font-weight: 600;
}

.table_add_btn:hover {
  background: var(--color-good-hover);
}

.tag_legend_sum_count {
  padding: 0em 0.3em;
  text-align: center;
  width: fit-content;
  background-color: var(--color-grey-light);
  color: var(--color-text-1);
  border-radius: 1rem;
  border: 1px solid var(--border-color-gray-3);
  display: flex;
  justify-content: center;
  align-items: center;
}


.actions-list-item {
  animation-duration: 800ms;
  animation-name: opacityIn;
  animation-iteration-count: 1;
  padding: 0.2em;
  transition: 0.1s all ease-in-out;
}

.actions-list-item:hover {
  background-color: lightblue;
}

.actions-list-item-btns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 60%;
}

.actions-list-item-btn {
  font-size: 14px;
  padding: 5px;
  height: fit-content;
  max-height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #333;
  color: var(--color-text-1);
  background-color: #fff;
  border: 1px solid var(--border-color-gray-1);
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.actions-list-item-btn:hover{
  cursor: pointer;
}


#actions-list-item-btn-edit:hover{
  color: var(--color-migso-blue-hover)!important;
  background-color: #fff!important;
  border-color: #0297c6!important;
  border-color: var(--color-migso-blue-hover)!important;
}
  
#actions-list-item-btn-delete{
  color: var(--color-bad) !important;
  background-color: #fff !important;
  border-color: var(--color-bad) !important;
}
  
  
#actions-list-item-btn-delete:focus{
  box-shadow: 0 0 0 .25rem rgb(229 87 102/25%)!important;
}
  

td {
  border: none;
}

.actions-list-item-span {
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* .ellipsis-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.action_table_item_options_view_option_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3em 0em;
  cursor: pointer;
}

.action_table_item_options_view_option_container:hover {
  background:var(--border-color-gray-00);
  color: var(--color-good);
}
.action_table_item_options_view_option_container:hover > .view_icon {
  color: var(--color-good);
}

.view_icon{
  margin-left: 0.4em;
  font-size: 1.3rem;
  color:var(--border-color-gray-3);
  
}

.view_txt {
  margin-left: 0.3em;
  font-weight: 500;
}

.chartsTooltipBullet{
  width:10px;
  height:10px;
  border-radius: 5px;
  margin-right:5px;
}

.chartsTooltipValue{
  font-weight: 600;
  color:var(--color-text-1);
  font-size: 14px;
  margin-left:5px;
}

.chartsTooltipContent{
  background-color: white;
  box-shadow: 0px 2px 4px #888888 ;
  border-radius:6px;
  font-size: 13px;
  color:var(--color-text-2);
}

.chartsTooltipLabel{
  background-color: var(--border-color-gray-0);
  border-bottom: 1px solid var(--border-color-gray-1);
  padding:5px 10px;
  color: var(--color-text-2);
  font-weight: 700;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
}

.chartsTooltipValueContent{
  padding:5px 10px;
}

.dataMappingHeaderFileClose{
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--color-migso-blue);
  color:white;
  border-radius: 8px;
  padding:2px;
  cursor: pointer;
  top:-4px;
  left:calc(100% - 9px);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
  transition:0.3s
}

.dataMappingHeaderFileClose:hover{
  background-color: var(--color-migso-blue-hover);
}
.perfo_small_container {
  border: 1px solid var(--border-color-gray-0-5);
  width: 60px;
  height: 30px;
  padding: 0px 5px;
  border-radius: 15px;
  align-items: center;
  user-select: none;
  background-color: white;
}

.perfo_small_status {
  font-size: 20px;
}

.perfo_small_colored_dot {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 10px;
}

.perfo_small_tooltip {
  display: flex;
  flex-direction: column;
}

.risk_small_tooltip {
  display: flex;
  flex-direction: column;
  z-index: 99999;
}


.change_project_dim_text {
  margin: 0 auto;
  text-align: center;
  padding: 1em 2em;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 0.5em;
}

.change_project_dim_btn {
  padding: 0.5em 1em;
  background-color: lightblue;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#confirm_discard_changes_btn {
  background: var(--color-good);
  font-weight: 500;
}

#confirm_discard_changes_btn:hover {
  background: var(--color-good-hover);
}

/* SMALL SHORTCUT COMPONENT */
.smallComponent_gov_container{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:4px 10px;
}

.smallComponent_gov_color{
  margin:auto;
  width:10px;
  height: 10px;
  border-radius: 20px;
  margin-right:5px;
}

/* RISK COMPONENT ------------------------------------------------------------------ */

/* MATRIX + TABLE CONTAINER */
.risks-opportunities-data-display-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 1em;
}

/* Classes for all Components in RiskBig.js */
.ellipsis-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.risk-opportunity-item-btns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 60%;
}

.risk-opportunity-item-btn {
  font-size: 14px;
  padding: 5px;
  height: fit-content;
  max-height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #333;
  color: var(--color-text-1);
  background-color: #fff;
  border: 1px solid var(--border-color-gray-1);
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  cursor: pointer;
}


#item-edit-btn:hover{
  color: var(--color-migso-blue-hover)!important;
  background-color: #fff!important;
  border-color: #0297c6!important;
  border-color: var(--color-migso-blue-hover)!important;
  }
  
#item-delete-btn{
      color: var(--color-bad) !important;
      background-color: #fff !important;
      border-color: var(--color-bad) !important;
  }
  
  
#item-delete-btn:focus{
      box-shadow: 0 0 0 .25rem rgb(229 87 102/25%)!important;
}


.ellipsis-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.align_center_cell {
  display: flex;
  justify-content: center;
  align-content: center;
}

.risk-opportunity-table-container {
  display: flex;
  flex-direction: column;
  transition: width 0.2s ease-in-out;
}

.table-top-functions-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.expand-table-button {
  margin-bottom: 10px;
  width: fit-content;
  padding: 0.3em 1em;
  border-radius: 0.25rem;
  border:1px solid var(--border-color-gray-2);
  /* background-color: var(--color-grey-light); */
  /* background: lightgrey; */
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size:0.8rem;
  min-width: 10vw;
  cursor: pointer;
}

.expand-table-button:hover {
  background-color: #dedede;
}


.risk-opportunity-matrix-container {
  min-height: 53vh;
  /* border: 1px solid grey; */
  height: 100%;
  width: 50%;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.matrix-outer-legend-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  left: 10px;
  bottom: 0px;

  font-size: 0.7rem;
  font-weight: bold;
}


.legend-arrow {
  font-size:1.1rem;
  color: var(--color-primary-1);
}


.matrix-container{
  position: absolute;
  height: 90%;
  width: 95%;
  right: -5px;
}

.matrix-relative-container {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  position: relative;
}

.left-matrix-legends-container {
  position: absolute;
  left: -40px;
  display: flex;
  height: 89%;
}

.left-matrix-legends {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.left-legend {
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  transform:rotate(-90deg);
}

.bottom-matrix-legends-container {
  position: absolute;
  left: 24px;
  bottom: -25px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* BOTTOM ARROW CONTAINER */
.bottom_arrow_container {
  position: absolute;
  bottom: -12px;
  right: -20px;
  z-index: 2;
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom_arrow {
  width: 0; 
  height: 0; 
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  
  border-left: 15px solid grey;
}

/* LEFT ARROW CONTAINER */

.left_arrow_container {
  position: absolute;
  top: -20px;
  left: -12px;
  z-index: 2;
  width: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_arrow {
  width: 0; 
  height: 0; 
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  
  border-bottom: 15px solid grey;
}


.bottom-matrix-legends {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottom-legend {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
}



.matrix {
  height: 100%;
  width: 100%;
  /* background-color: green; */
  position: absolute;
  top: -2px;
  right: -5px;
  border-left: 3px solid grey;
  border-bottom: 3px solid grey;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}


.matrix-item-risk-item {
  background-color: white;
  padding: 0.1em 0.2em;
  display: flex;
  flex-direction: row;
  border-radius: 0.25rem;
  align-items: center;
  width: fit-content;
  /* cursor: move; */
  cursor: pointer;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
}

.ro-id {
  width:fit-content;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0em 0.2em;
  color: rgb(90,90,90);
}

.matrix-item-risk-item-escalated-borders {
  height: 70%;
  width: 20px;
  margin-right: 0.2em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ro_matrix_item_arrow {
  font-size: 1rem;
  color: red;
}

.ellipsis-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ACTIONS CONTAINERS */
.actions_container {
  width: fit-content;
  /* background-color: red; */
  height: 100%;
  display: flex;
  flex-direction: row;

}
.actions_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  padding: 0.2em 0.6em;
  font-size: 0.6rem;
  border-radius: 1rem;
  margin: 2px 1px;
}

#in_progress_actions{
  background-color: var(--color-grey-light);
  color: var(--color-text-1);
  /* border: 1px solid var(--border-color-gray-2); */
}

#late_actions {
  background-color: var(--color-bad);
  color: white;
}


.matrix-item {
  border: 1px solid var(--border-color-gray-0);
  position: relative;
}


.matrix-item-criticity {
  position: absolute;
  bottom:0;
  left:0;
  color: var(--color-text-2);
  padding: 0.2em;
  font-size: 0.8rem;
}

.matrix-item-risks-container {
  display: grid;
  grid-template-columns: fit-content(30%) fit-content(30%);
  /* grid-template-columns: repeat(auto-fill,auto); */
  /* grid-auto-columns: auto; */
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 2px;
  padding: 0.4em 0.1em;
  width: 90%;
  float: right;
  overflow-y: auto;
  overflow-x: hidden;
}



.risk-opportunity-header-toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: white;
  border: 1px solid lightgrey; */
  border-radius: 0.5rem;
  margin-left: 1em;
  /* padding: 0.2em 0.2em; */
  padding: 0.5em;
  transition: all 0.3s ease-in-out;
}

.risk-opportunity-header-toggle-text {
  font-size: 0.8rem;
  color: var(--color-text-3);
  transition:all 2s ease-in-out;
}

.risk-opportunity-header-toggle-text-span {
  margin-left: 0.3em;
  color: var(--color-text-2);
  font-weight: bold;
  font-size: 0.9rem;
}

.escalation_toggle_container {
  width: fit-content;
  min-width:50px;
  max-width: 70%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 1rem;
  border: 1px solid var(--border-color-gray-2);
  background: var(--bg-app);
  position: relative;
}

.escalation_toggle_option_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1em;
  border-radius: 1rem;
  margin-left: 0.2em;
  color: rgba(0,0,0,0.5);
  cursor: pointer;
  background: transparent;
  z-index: 1;
  transition: 0.2s all ease-in-out;
}

.icon {
  font-size: 1rem;
}

.selected {
  position: absolute;
  background: var(--color-primary);
  color: white;
  height: 100%;
  width: 30%;
  padding: 0.1em;
  z-index: 0;
  transition: 0.3s all ease-in-out;
  border-radius: 1rem;
}

.selected_text_color {
  color: white;
}

.create-edit-table-container {
  height: 100%;
  width: 100%;
  padding:0.5em;
  display: flex;
  flex-direction: column;
  margin-top: -0.8em;
}

.create-edit-table-header {
  padding: 0.2em 1em;
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.create-edit-table-header-text-container {
  font-size: 0.8rem;
  font-weight: 400;
  margin-right: 0.3em;
}

.create-edit-table-header-text-container > span {
  font-weight: bold;
}

.create-edit-table-inputs-container {
  display: flex;
  flex-direction: row;
  height: 100%; 
  width: 100%;
  min-height: 250px;
  
  min-width: 50vw;
  margin-top: 0.3em;
  justify-content: space-between;
}

.inputs-container-column {
  width: 32%;
  height: 100%;
  /* max-height: 110%; */
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 0.25rem;
  border: 2px solid var(--border-color-gray-1);
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  transition: 0.2s all ease-in-out;
  position: relative;
}


.inputs-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0.5em;
  /* border-bottom: 1px solid var(--border-color-gray-1); */
  padding: 0.3em;
  align-items: center;
  max-height: 200px;
}


.add-edit-id-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 1rem;
  
  font-weight: bold;
  padding: 0 0.5em;
}

.ellipsis-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-container {
  transition: 0.2s all ;
}

.input-label {
  font-size: 0.8rem;
  margin-left: 0.5em;
  padding: 0 1em;
}

.criticity-label {
  font-weight: 600;
  font-size:0.9rem;
  background-color: var(--color-grey-light);
  border-radius: 0.5rem;
  border: 1px solid var(--border-color-gray-1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 28px;
  margin: 0 auto;

}

.inner-inputs-container-column {
  width: 100%;
  height: 40%;
  
  border-radius: 0.25rem;
  border: 2px solid var(--border-color-gray-1);
  display: flex;
  flex-direction: column;
  padding: 0.1em 0.3em;
  overflow-y: auto;
  transition: 0.2s all ease-in-out;
}


.actions-table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 0.3em;
}

.no-actions {
  font-size: 1.1rem;
  font-weight: bold;
}

.add-btn {
  height: fit-content;
  background: var(--color-good);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 1.3rem;
  padding: 0.05em;
}

.add-btn:hover {
  background: var(--color-good-hover);
}


.impact-inputs-container {
  display: flex;
  width: 100%;
  height: 60%;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  transition: all 0.3s ease-in-out;
}

.impact-input-container {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* MANDATORY STAR SPAN */
.mandatory_star {
  color:red;
  margin-left: 0.2em;
}

/* CRITICITY TABLE CONTAINER */
.criticity_input_table_container {
  margin-top: 0.5em;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 150px;
}


/* TABLE */
.criticity_input_table {
  display: table;
  width: 100%;
  height: 60%;
  /* margin-left: 0.2em; */
}

/* TABLE HEADER */
/* .criticity_input_table_header {
  /* position: sticky;
  top: 0; */


/* TABLE HEADER ITEM */
.cricity_input_table_header_item {
  width: calc(85%/2);
  text-align: center;
  border: none;
}

/* BODY */
.criticity_input_table_body {
  min-height: 100px;
  width: 100%;

}

/* BODY ITEM */
/* .criticity_input_table_body_item {
  /* background-color: red; */


.criticity_input_table_body_item:hover {
  background: fixed;
}

.criciticity_input_table_body_item_cell {
  border: none;
}

.criticity_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.legend {
  font-weight: bold;
  font-size: 1rem;
}


.risk-create-edit-action-table-container {
  background-color: rgba(0,0,0,0.7);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90vh;

  top: 53%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /*  */

  transition: all 0.3s ease-in-out;
}

.create-edit-action-table {
  height: fit-content;
  min-width: 800px;
  width: 80vw;
  position: fixed;
  
  /* This code will make this modal always be on the center of screen */
  /* Even without knowing the modals width and height */
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /*  */
}


.risks-actions-list-item {
  /* display: inline-table; */
  width: 100%;
  table-layout: fixed;
}


/* .risks-actions-list-item-span {
  padding: 0.3em 0.3em;
  border: 2px solid var(--border-color-gray-0);
} */


.risks-actions-list-item-btns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 60%;
}


.risks-actions-list-item-btn {
  font-size: 13px;
  padding: 4px;
  height: fit-content;
  max-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #333;
  color: var(--color-text-1);
  background-color: #fff;
  border: 1px solid var(--border-color-gray-1);
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.risks-actions-list-item-btn:hover{
  cursor: pointer;
}

#risks-actions-list-item-btn-edit:hover{
color: var(--color-migso-blue-hover)!important;
background-color: #fff!important;
border-color: #0297c6!important;
border-color: var(--color-migso-blue-hover)!important;
}

#risks-actions-list-item-btn-delete{
  color: var(--color-bad) !important;
  background-color: #fff !important;
  border-color: var(--color-bad) !important;
}


#risks-actions-list-item-btn-delete:focus{
  box-shadow: 0 0 0 .25rem rgb(229 87 102/25%)!important;
}


.ellipsis-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.actions-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.actions_table_add_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* height: 10%; */
  align-items: center;
  padding: 0.3em 0.5em;
}

.add_btn {
  height: fit-content;
  background: var(--color-good);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 0.9rem;
  padding: 0.1em 0.5em;
}

.add_btn:hover {
  background: var(--color-good-hover);
}

.table-container {
  display: table;
  height: 85%;
  border: none;
}

.action-table-header {
  margin:2px 0px;
  padding: 0.3em 0.5em;
  text-align: left;
  border: 2px solid var(--border-color-gray-0);
  font-size: 0.7rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* background-color: var(--border-color-gray-1); */
  background: var(--color-grey-light);
  
}

.action-table-header > tr > td {
  padding: 0em 0.3em;
  width: 50%;
  border:none;
}

.ro-action-table-header-row {
  display: flex;
  justify-content: center;
  
}

.ro-action-table-body {
  display: inline-block;
  max-height: 10vh;
  overflow-y: auto;
  overflow-x: hidden;
}



/* BACKGROUND */
.risk_big_delete_associated_actions_outer_container {
  position: fixed;
  
  /* This code will make this modal always be on the center of screen */
  /* Even without knowing the modals width and height */
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /*  */

  height: 94vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0,0,0,0.4);
  z-index: 10;
}

/* MODAL  */
.risk_big_delete_associated_actions_inner_container {
  height: 50vh;
  min-height: 400px;
  z-index: 999;
  width:35%;
  min-width: 550px;
  background-color: var(--color-grey-light);
  border-radius: 0.25rem;

  display: flex;
  flex-direction: column;
}

/* HEADER CONTAINER */
.actions_management_modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0.5em;
  border-bottom: 1px solid var(--border-color-gray-2);
  height: fit-content;
}

/* HEADER ICON AND TITLE CONTAINER */
.actions_management_modal_header_icon_title_container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* HEADER HAND STOP ICON */
.stop-icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 0.4em;
  color: var(--color-primary);
  transform: rotateY(180deg);
}

/* HEADER TITLE */
.actions_management_modal_header_title {
  font-size: 1rem;
  padding: 0 0.5em;
  font-weight: bold;
}

/* HEADER CLOSE BTN */
.actions_management_modal_header_close_btn_container {
  color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--border-color-gray-4);
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  padding: 0.1em;
}

.actions_management_modal_header_close_btn_container:hover {
  background-color: rgba(0,0,0,0.5);
  color: var(--color-grey-light);
}

/* MODAL BODY */
.actions_management_modal_body_container {
  height: 80%;
  max-height: 400px;

  overflow-y: auto;
  overflow-x: hidden;
  padding: 1em;
}



/* TITLE */
.modal_title {
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  /* padding: 1em; */
}

/* TABLE TITLE */
.table_title_container {
  margin-top: 1.5em;
  font-size: 1rem;
  font-weight: 500;
}

.table_title_star {
  color: red;
}

/* BOTTOM BTNS */
.actions_management_modal_bottom_btns_container {
  width: 100%;
  display: flex;
  margin-top: 1em;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid var(--border-color-gray-2);
  padding: 0.5em 0;
}

/* BTN */
.actions_management_modal_bottom_btn {
  padding: 0.4em 0.8em;
  margin-right: 1em;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 0.25rem;
  color: white;
}

/* CANCEL BTN */
#cancel_btn {
  background-color: rgba(0,0,0,0.2);
}

#cancel_btn:hover {
  background-color: rgba(0,0,0,0.4);
}

/* APPLY BTN */
#apply_btn {
  background-color: var(--color-migso-blue-hover);
  font-weight: 500;
}

#apply_btn:hover {
  background-color: var(--color-migso-black);
}

/* CONFIRM CANCEL BTN */
#confirm_cancel_btn {
  background-color: var(--color-bad);
  font-weight: 600;
}

#confirm_cancel_btn:hover {
  background-color: var(--color-bad-hover);
}


/* DEFAULT MANAGEMENT */

/* OUTER CONTAINER */
.default_management_outer_container {
  position: absolute;
  
  /* This code will make this modal always be on the center of screen */
  /* Even without knowing the modals width and height */
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /*  */

  height: 94vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0,0,0,0.2);
  z-index: 1000;
}

/* INNER CONTAINER */
.default_management_inner_container {
  height: 20vh;
  min-height: 200px;
  z-index: 1001;
  width:20%;
  min-width: 300px;
  /* background-color: var(--color-grey-light); */
  background-color: var(--color-grey-light);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

/* CLOSE BTN */
.default_management_container_close_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 0.3em;
  background-color: var(--color-alert);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.close_btn {
  padding: 0.1em;
  border-radius: 0.25rem;
  cursor: pointer;
  color: var(--color-grey-light);
  border: 1px solid var(--border-color-gray-0);
  transition: 0.2s all ease-in-out;
}

.close_btn:hover {
  background-color: var(--color-grey-light);
  color: black;
}

/* TEXT */
.default_info_text {
  padding: 1em 1.5em;
  padding-bottom: 2.5em;
  /* margin-top: 0.5em; */
  background-color: var(--color-alert);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}   

/* BTN CONTAINER */
.default_management_container_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
}

.info_btn {
  width: fit-content;
  background-color: var(--color-good);
  padding: 0.5em 2em;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.25rem;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  /* color: var(--color-grey-light); */
  color: white;
}

#confirm_info_btn:hover {
  background-color: var(--color-good-hover);
}

/* ITEM ROW CONTAINER */
.actions_management_table_item_container{
  width: 100%;
  transition: 0.2s all ease-in-out;
  min-height: 40px;
}

.actions_management_table_item_container:hover {
  background: fixed;
}

/* CELL */
.actions_management_table_item_cell {
  border-right: 1px solid var(--border-color-gray-2);
  flex: 1;
  max-width: 20%;
  text-align: center;
  transition: 0.2s all ease-in-out;
}

/* OPTION CONTAINER */
.option_container {
 display: flex;
 justify-content: center;
 align-items: center;
 height: 100%;
}

/* OPTION */
.option {
  width: 23px;
  height: 23px;
  border-radius: 0.7rem;
  border: 1px solid var(--border-color-gray-3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ICON INSIDE OPTION */
.check_icon {
  color: white;
  font-size: 1rem;
}

/* R&O INPUT */
.actions_management_table_item_ro_id_input {
  width: 20%;
}


/* ELLIPSIS ONE LINE */
.ellipsis_one_line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link_to_another_ro_input_container {
  margin-top: 0.1em;
}

/* ACTIONS TABLE */
.actions_table_container {
  width: 100%;
  margin-top: 0.3em;
}

@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

.ro_actions_details_table_container {
  width: 45%;
  min-height: 520px;
  border-radius: 0.25rem;
  overflow: hidden;

  animation: fadeInFromNone 0.3s ease-in-out;
}

/* HEADER */

.ro_actions_details_header {
  background-color: yellow;
  padding: 1em 0em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* DISPLAY ID */
.display_id {
  margin-left: 1em;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 1em;
  background-color: white;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0,0.3);
  border-radius: 0.25rem;
}

/* HEADER BUTTONS CONTAINER */
.header_buttons_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* COMMON HEADER BUTTON PROPERTIES */
.header_btn {
  padding: 0.1em;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 1.2rem;
}

/* EDIT BUTTON */
#edit_btn {
  background-color: white;
  color: var(--color-text-1);
  border-radius: 0.25rem;
  border: 1px solid var(--border-color-gray-2);
}

#edit_btn:hover {
  background-color: var(--color-grey-light);
}

/* CLOSE BUTTON */
#close_btn {
  border-radius: 1rem;
  background-color: var(--color-text-2);
  color: white;
  /* border: 1px solid white; */
}

#close_btn:hover {
 background-color: var(--color-text-1);
}


/* TAGS CONTAINER */
.ro_actions_details_tags_legends_container {
  display: flex;
  flex-direction: row;
  padding: 0.5em 0.1em;
  border-left: 1px solid var(--border-color-gray-1);
  border-right: 1px solid var(--border-color-gray-1);
  /* background-color: var(--bg-app); */
}

/* TAG CONTAINER */
.ro_actions_details_table_tag_container {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 0.3em;
  border-radius: 0.25rem;
  align-items: center;
  margin-left: 5px;
  transition: 0.2s all ease-in-out;
}

.ro_actions_details_table_tag_container:hover {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0,0.3);
  background-color: lightblue;
}

/* COUNT */
.actions_details_tag_legend_sum_count {
  padding: 0em 0.5em;
  background-color: var(--color-grey-light);
  color: var(--color-text-1);
  border-radius: 1rem;
  border: 1px solid var(--border-color-gray-3);
  display: flex;
  justify-content: center;
  align-items: center;
}


/* EMPTY ACTIONS DETAILS TABLE */
.empty_actions_details_container {

  height: fit-content;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid var(--border-color-gray-4);
  border-left: 1px solid var(--border-color-gray-4);
  border-right: 1px solid var(--border-color-gray-4);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;

}

.empty_text {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0em 0.3em;
}

.ro_actions_details_tag_total_text {
  font-size: 0.9rem;
  padding: 0em 0.3em;
  font-weight: bold;
}
.actions_details_table_item_container:hover {
  background-color: lightblue;
}

.align_center_row_cell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar_responsible_container {
  padding: 0.5em;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  font-weight: 600;
}


.text_align_center {
  text-align: center;
}

/* PERFORMANCE */

.perfo-add-edit-status-target-icon {
  min-width: 50px;
}

.perfo-add-edit-status-and-trend {
  display: flex;
  flex-direction: row;
  gap: 160px;
  margin-top: 20px;
  margin-left: 5px;
  margin-bottom: 10px;
}

.perfo-add-edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.perfo-free-text-area-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-width: 400px;
  gap: 10px;

}

.perfo_free_text_area_column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.perfo-free-text-area,
.perfo-free-text-area .global-input-content,
.perfo-free-text-area .input-container {
  display: flex;
  flex-grow: 1;
  max-height: none;
}

.perfo-free-text-areas-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.perfo-free-text-areas-label-star {
  color: var(--color-bad);
  padding-left: 10px;
}

.perfo-free-text-areas-label-icon {
  margin-right: 10px;
}

.bigComponentBody .global-input-box {
  margin-left: 10px;
}

.performance_add_edit_review_status {
  display: flex;
  align-items: center;
  margin-left: 7px;
}

.perfo_add_edit_key_message_container,
.perfo_add_edit_key_message_container .global-input-content,
.perfo_add_edit_key_message_container .input-container {
  display: flex;
  flex-grow: 1;
  max-height: none;
}

.perfo_add_edit_last_update_header {
  display: flex;
  flex-direction: column;
  
  margin: -5px 10px -5px auto;

  font-size: 12px;
  font-weight: normal;
}

.perfo-list-governance-colored-dot {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 10px;
  display: inline-block;
}

.perfo-list-status-trend {
  display: flex;
  flex-direction: row;
}

.perfo-list-status-trend span {
  margin-left: 10px;
}

/* GOVERNANCE ------------------------------------------------------------------------------ */



/* to be deleted */
.governance-list-reviewType {
    display: flex;
    align-items: center;
}

.governance-list-reviewType-coloredDot {
    width: 10px;
    height: 10px;
    border-radius: 20px;
    margin-right: 10px;
}

.governance-list-leadTime {
    color: var(--color-text-4);
}

.governance-add-edit-recurrence {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
    padding-left: 6px;
    padding-right: 65px;
    justify-content: space-between;
}

.governance-list-children-actions-forbidden {
    color: var(--color-text-4);
    font-style: italic;
}

/* Governance Status Header Component */
.governanceBig-header-status {
    display: flex;

    margin-top: -6px;
    margin-bottom: -6px;
    margin-left: 30px;
    padding: 3px 10px;

    background-color: var(--border-color-gray-0);

    border: 1px solid var(--border-color-gray-3);
    border-radius: 10px;
}

.governanceBig-header-status-statusInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.governanceBig-header-status-cancelButton .global-button {
    background-color: var(--border-color-gray-1);
    border-color: var(--border-color-gray-2);
}

.governance_list_recurrent_icon {
    font-size: 22px;
    
    margin-right: 5px;
}

.smallComponent_gov_container{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:4px 10px;

  
}

.smallComponent_gov_color{
  margin:auto;
  width:10px;
  height: 10px;
  border-radius: 20px;
  margin-right:5px;
}

.smallInfo {
  font-size: small;
}

.smallComponent_gov_tooltip_infos {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}








.shortcut_big_container {
  opacity: 80%;
  transition: 0.2s opacity ease-in-out;
}

.shortcut_big_container:hover {
  opacity: 100%;
}

.shortcutList {
  background: white;
  width: 100%;
}

.shortcutListItem_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  background: white;
  width: 100%;
  overflow-y: scroll;
  max-height: 240px;
}

.shortcut_noItem_content:hover {
  cursor: pointer;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

#shortcutListItem_button_add {
  color: var(--color-good);
  font-size: 24px;
  border: 0px;
}
#shortcutListItem_button_add:hover {
  color: var(--color-good-hover);
  font-size: 24px;
  border: 0px;
}

#addBlock_preview {
  min-width: 200px;
}

.shortcut_edit_table_container {
  width: 100%;
  margin: 0 auto;
  position:relative;
  padding-bottom: 2.5em;
  transition: 0.1s all;
}
.shortcut_edit_table_dragging {
  border: 2px var(--border-color-gray-3) dashed;
}

.shortcut_edit_add_line_container {
  /* background-color: var(--color-text-3); */
  border-top: 1px solid var(--color-text-3);
  border-bottom: 1px solid var(--color-text-3);
  height: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  animation-name: opacityIn;
  animation-duration: 300ms;
  animation-iteration-count: 1;

}
.shortcut_edit_add_line_container:hover{
  border-top-color: var(--color-text-1);
  border-bottom-color: var(--color-text-1);
}
.shortcut_edit_add_line_container:hover > .add_icon_container {
  background-color: var(--color-text-1);
}
.add_icon_container{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--border-color-gray-1);
  border-radius: 1rem;
  background: var(--color-text-3);
  color: white;
  padding: 0.1em;
  transition: 0.2s all ease-in-out;
}


/* .hover_header {
  /* width: 10px;
  background-color: red; } */

.header_no_border {
  border: none;
  background-color: red;
}
.mandatory_star {
  color: red;
  font-size: 0.7rem;
}




.shortcut_edit_footer_btn {
  background-color: var(--color-good);
  color: white;
  font-weight: 600;
}

.shortcut_edit_footer_btn:hover {
  background-color: var(--color-good-hover);
  color: white;
}

.hover_menu_icon_container {
  position:absolute;
  z-index: 2;
  left: -30px;
}

.shortcut_edit_table_headers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0em 2.4em;
}

.shortcut_edit_modal_header {
  padding: 0.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
}

.shortcut_edit_modal_text_header {
  width: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shortcut_edit_modal_icon_header {
  width: 10%;
}

.shortcut_edit_modal_color_header {
  width: 3%;
  font-size: 0.8rem;
  text-align: center;
}

.shortcut_edit_modal_item_container, .selected_shortcut{
  /* animation-name: opacityIn;
  animation-iteration-count: 1;
  animation-duration: 200ms; */
  border: none;
  width: 100%;
  transition: 0.3s all;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
  padding: 0em 2em;
}

.shortcut_edit_modal_item_container > td, .selected_shortcut > td {
  padding: 0em 0.5em;
}

.selected_shortcut {
  background: var(--color-grey-light);
  border-color: black;
}

.selected_shortcut:hover{
  background: var(--color-grey-light);
  /* background: fixed; */
}

.shortcut_edit_modal_item_container:hover {
  background: var(--color-grey-light);
}

.shortcut_edit_modal_item_container > td {
  border: none;
}


.edit_modal_item_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position:absolute;
}

.menu_icon {
  color: black;
  cursor: grab;
}

.delete_icon {
  color: var(--color-bad);
  font-size: 1.7rem;
  background-color: white;
  padding: 0.1em;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.delete_icon:hover {
  background:var(--color-bad-hover);
  color: var(--color-grey-light);
  /* color: var(--color-bad-hover); */
}

.edit_modal_item_text_input_container {
  width: 15%;
}

.edit_modal_item_icon_input_container {
  width: 10%;
}

.edit_modal_item_color_input_container {
  width: 3%;
  min-width: 40px;
}

#edit_modal_item_delete_btn, #edit_modal_item_drag_btn {
  animation-duration: 300ms;
  animation-iteration-count: 1;
}


@keyframes slideDeleteFromRight {
  from {
      margin-right: -3em;
      opacity: 0;
  }
  to {
      margin-right: 0em;
      opacity: 1;
  }
  
  
}

#edit_modal_item_delete_btn {
  animation-name: slideDeleteFromRight;
  right: 7px;
}


@keyframes slideDragFromLeft {
  from {
      margin-left: -2em;
      opacity: 0;
  }

  to {
      margin-left: 0em;
      opacity: 1;
  }
}


#edit_modal_item_drag_btn {
  animation-name: slideDragFromLeft;
  left: 7px;
}
.shortcutListItem {
  padding: 8px 10px;
  background-color: white;
  min-width: 170px;
  border: 1px solid var(--border-color-gray-00);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px;
  transition: 0.1s all ease-in-out;
}
.shortcutListItem:hover {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0,0.3);
  border-color: var(--color-migso-blue)!important;
}

.shortcutListItem_info {
  display: flex;
  align-items: center;
  padding: 2px 0;
}
.shortcutListItem_info:hover {
  cursor: pointer;
  /* color: var(--color-primary-2); */
}

.shortcutListItem_name {
  margin: 2px 10px;
}

.shortcutListItem_buttons {
  flex-direction: row;
  position: absolute;
  margin-right: 100px;
  background-color: white;
  border-radius: 3px;
  animation: shortcutEditButtonsAppears 0.1s linear;
}

.shortcutListItem_button {
  margin: 1px 2px;
  font-size: 14px;
  padding: 5px;
  color: var(--color-text-1);
  background-color: #fff;
  border: 1px solid var(--border-color-gray-1);
  border-radius: 0.25rem;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.shortcutListItem_button:hover {
  cursor: pointer;
}

@keyframes shortcutEditButtonsAppears {
  from {
      transform: scale(0);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 1;
  }
}

/* EDIT DELETE BUTTONS SPECIFIC */
.shortcutListItem_buttons .edit_delete_button_container {
  width: 65px;
}

.portfolio{
  width:100%;
  overscroll-behavior: none;
}


.portfolio-content{
  
  border: 1px solid var(--border-color-gray-1);
  
}

.portfolioTree{
  
  background-color: var(--border-color-gray-1);
  width:100%;
  

  transition: 1s all ease-in-out;

  overflow: hidden;
}

.short-nav{
  font-size:12px;
  color:var(--color-text-3);
  font-weight: 400;
  font-style:normal;
  margin: 10px 30px 0px 30px;
}

.short-nav-item{
 text-decoration: underline;
}

.control-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* CONTROL ITEM UPPER PART (ICON AND TITLE) */
.control_item_upper {
  display: flex;
  flex-direction: row;

}
/* CONTROL ITEM SUBTITLE */
.control_item_subtitle{
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 0.7rem;
  color: var(--color-text-4);
}

/* HEADER */
.tree-container-header{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  border-bottom: 2px solid var(--border-color-gray-4);
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: white;
  width:100%;
  
}
.tree-header-title{
  padding-left:10px;
  color:var(--border-color-gray-1);
  width:50px;

}

.tree-header-title:hover{
  color:var(--color-migso-blue)
}
.tree-header-columns{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex:1;

  /* border-right: 1px dashed var(--border-color-gray-3); */
  padding: 0px;
  font-weight: 700;
  height: 60px;


}

.tree-header-column-icon{
  margin-right: 8px;
  font-size: 22px;
  margin-left: 10px;
}

.tree-header-column-portfolioName{

  font-size: 18px !important;
  color:var(--color-migso-blue) !important;

}

.tree-header-column-name{
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  height: 25px;
  font-family: 'Oswald', cursive;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text-2);
}

.tree-bsSwitch{

  height:fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tree-bsSwitch-bsItem{
  height:25px;
  width:30px;
  background-color: white;
  /*border:1px solid var(--border-color-gray-1);*/
  display: flex;
  justify-content: center;
  align-items: center;
  padding:1px 2px;

}

.tree-bsSwitch-bsItem:hover{
  cursor:pointer;
}

.tree-bsSwitch-bsItem:first-child{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tree-bsSwitch-bsItem:last-child{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tree-bsSwitch-bsItem-notSelected{
  box-shadow:inset 0.5px 0.5px 2px  var(--color-text-3);
  color:var(--color-text-3)
}

.tree-bsSwitch-bsItem-selected{
  box-shadow: 0.5px 0.5px 2px  var(--color-text-3);
}


/* ACTION SMALL FILTER AND MODAL CSS */
.action-small-filter-icon-container {
  padding: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  border-radius: 0.5rem;
}

.action-small-filter-icon-container:hover {
  border: 1px solid var(--border-color-gray-3);
}



/* BOARD ------------------------------------- */

.boardSectionStructureBlock {
  margin: 0px 15px;
  height: 270px;
  min-height: 270px;
  overflow-y: hidden;
}

.boardTitleIcon {
  color: var(--color-migso-blue);
}

.boardSectionTitle {
  color: var(--color-text-2);
  margin-left: 25px;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 20px;
}

.boardStructureBlockInfo {
  height: 225px;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
}

.boardSectionLeftInfos {
  height: 225px;
  border-right: 1px solid var(--border-color-gray-0);
  padding: 10px;
  color: var(--color-text-3);
  text-align: center;
  width: 90px;
  min-width: 90px;
}

.boardSectionLeftInfosNumber{
  font-weight: 600; 
  font-size: 24px;
}

.boardSectionLeftInfosNumberWithBackground {
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  margin-bottom: 5px;

}

.boardSectionLeftInfosText {
  font-weight: 400;
  font-size: 10px;
}

.boardListItem {
  border-bottom: 1px solid var(--border-color-gray-0);
  background-color: white;
  width: 100%;
  padding: 5px 5px;
}

.boardListItem:hover {
  background-color: var(--border-color-gray-00);
}

/* WHEN HEIGHT IS TO SMALL, NO SCROLL */
@media (max-height: 800px) {
  .boardBrowsingStructureBlock {
    height: unset !important;
  }

  .boardBrowsingBlockContent {
    height: unset !important;
  }
}

.boardBrowsingStructureBlock {
  height: calc(100vh - 560px);
  max-height: none;
  width: 100%;
  transition: none;
  min-width: 600px;
}

.boardBrowsingBlockContent {
  height: calc(100vh - 605px);
  flex-wrap: wrap;
  gap: 20px;
  overflow: auto;
  padding: 20px;
  padding: 20px;
}

/* CREATE FORM */
.portfolioCreateFormDeleteButton {
  height: 34px;
}

/* PROJECT LIST */
.boardRoundedImage {
  background-color: var(--border-color-gray-0);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 20px;
  color: var(--color-text-5);
}

.boardProjectListOrganizationBlock:not(:last-child) {
  margin-bottom: 50px;
}

.boardProjectListOrganizationBlockTitle{
  cursor: pointer;
  transition: 0.3s;
}
.boardProjectListOrganizationBlockTitle:hover{
  color:var(--color-migso-blue)
}

.boardProjectListItem {
  border: 1px solid var(--border-color-gray-0);
  border-radius: 5px;
  background-color: white;
  width: 170px;
  height: 40px;
  color: var(--color-text-3);
  font-weight: 600;
  padding: 0px 10px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
  cursor: pointer;
}

.boardProjectListItem:hover {
  background-color: var(--border-color-gray-00);
}

.boardProjectListStatusLabel {
  color: white;
  padding: 1px 5px;
  border-radius: 5px;
  font-weight: 500;
  box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 40%);
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
}

/* PORTFOLIO LIST */

.boardPortfolioListCardLoader{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.4);
  border-radius: 8px;
  animation-name: opacityIn;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  cursor: progress;
}
.boardPortfolioListCardLoaderTxt{
  color:white;
  font-weight: 600;
  margin-top: 5px;
}

.boardPortfolioListCard {
  width: 180px;
  height: 220px;
  border: 1px solid var(--border-color-gray-0);
  border-radius: 8px;
  padding: 5px;
  background-color: white;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
  position:relative
}
.boardPortfolioListCardSelected{
  border: 1px solid var(--color-migso-blue);
}


.boardPortfolioCardEditButton {
  color: var(--color-text-3);
  width: 30px;
  height: 30px;
}

.boardPortfolioListNewCard {
  background-color: var(--border-color-gray-00);
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.boardPortfolioListNewCard:hover {
  color: var(--color-good);
  transition: color .2s ease-in-out;
  cursor: pointer;
}

.boardPortfolioCertifiedLogo {
  position: absolute;
  user-select: none;
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  color: var(--color-migso-blue);
  background-color: white;
  border-radius: 15px;
  top: -3px;
  right: -3px;
}

/* CREATE FORM */
.boardPortfolioCreateFormColumnTitle {
  padding: 5px 10px;
  background-color: var(--border-color-gray-0);
  border-radius: 5px;
  color: var(--color-text-2);
  font-weight: 700;
}

.boardPortfolioCreateFormInput {
  margin-top: 20px;
}

.boardPortfolioCreateFormDragableBox {
  height: 42px;
  border-radius: 5px;
  padding: 0px 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  width: 100%;
}

/* PROJECT USER MANAGEMENT --------------------------------------------------------------------------------- */
.projectUserManagementBody{
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 265px);
  border-top: 1px solid var(--border-color-gray-0-5)!important;
}

.projectUserManagementTable {
  padding: 0px 0px;
  border-collapse: separate;
  border-spacing: 0 0px;
  position: relative;
  width:100%
}

.projectUserManagementTableHeader {
  border-bottom: 1px solid var(--border-color-gray-1)!important;
  padding: 5px 15px;
  position: sticky;
  top:0;
  background-color: white;
  height:38px;
  text-align: center;
}
.projectUserManagementTable thead tr th{
  border-right: 1px solid var(--border-color-gray-0);
}
.projectUserManagementTable thead tr th:last-child{
  border-right: none;
}
.projectUserManagementTable tbody tr td {
  padding: 10px 15px;
  border-right: 1px solid var(--border-color-gray-0);
}
.projectUserManagementTable tbody tr td:last-child {
  border-right: none;
}

.projectUserManagementTable tbody tr {
  border-bottom: 1px solid var(--border-color-gray-0-5);
}
.ProjectUserManagementCommandBar {
  padding:  20px;
  width: 100%;
}
.ProjectUserManagementCommandBarLeft {
  color: var(--color-text-3);
  background-color: var(--border-color-gray-0);
  font-size: 14px;
  padding:5px 15px;
  font-weight: 500;
  border-radius: 6px;
  white-space: nowrap;
  margin-left: 20px;
}
.ProjectUserManagementCommandBarRight {
  background-color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 20px;
  border: 1px solid #ced4da
}
.ProjectUserManagementCommandBarRightButton {
  cursor: pointer;
  margin: 0 5px;
  transition: 0.3s;
  color: var(--color-migso-blue);
}
.ProjectUserManagementCommandBarRightButton:hover {
  color: var(--color-migso-blue-hover);
}
.projectUserManagemenentRightNumbersContainer {
  padding: 0px 10px;
}
.projectUserManagemenentRightNumber, .projectUserManagementSelectedRightNumber {
  cursor: pointer;
  padding: 0px 6px;
  border-radius: 15px;
  transition: 0.3s;
  margin: 0 2px;
}
.projectUserManagemenentRightNumber:hover {
  background:var(--color-migso-blue);
  color: white;

}
.projectUserManagementSelectedRightNumber {
  background:var(--color-migso-blue);
  color: white;
}
.projectUserTag {
  white-space: nowrap;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 10px;
}
.manageUsersModalButtons {
  min-width: 250px;
}
.manageUsersCloseButton {
  cursor: pointer;
  transition: 0.3s;
}
.manageUsersCloseButton:hover {
  color: var(--color-bad-hover);
}
.manageUsersTableSelectBox, .selectedManageUsersTableSelectBox {
  width: 20px;
  margin: 0 auto;
  height: 20px;
  border: 1px solid var(--border-color-gray-3);
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;
}
.manageUsersTableSelectBox:hover {
  background-color: var(--border-color-gray-0);
}
.selectedManageUsersTableSelectBox {
  background-color: var(--color-migso-blue);
  animation-name: opacityIn;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0,0.2);
}
.selectedManageUsersTableSelectBox:hover {
  background-color: var(--color-migso-blue);
}
.projectUserManagementSearchBarBlock {
  width: 100%;
}
.addUserManagementSearchBarBlock {
  width: 70%;
  min-width: 200px;
}
.addUsersBlockSearchButton {
  margin-left: -35px;
  height: 100%;
  padding: 0px 5px 0px 0px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s;
}
.addUsersBlockSearchButton:hover{
  color: var(--color-migso-blue);
}
.projectUserManagementSearchBar {
  /* outline: none; */
  border: 1px solid var(--border-color-gray-3);
  border-radius: 5px;
  /* box-shadow: 0px 1px 1px 1px rgba(0, 0, 0,0.1); */
}
.projectAddUsersBlockBottomBtnsContainer{
  padding: 10px;
}
.addUsersBlockUpdateBtn {
  background: var(--color-migso-blue);
  color: #fff;
  margin:0 5px;
  transition: 0.2s all ease-in-out;
}
.addUsersBlockCloseBtn{
  background: var(--border-color-gray-2);;
  color: #fff;
}
.addUsersBlockUpdateBtn:hover {
  background: var(--color-migso-blue-hover);
}
.ProjectUserManagementCommandBarRightButtonDisabledButton {
  cursor: not-allowed;
  color: var(--color-text-4);
  margin: 0 5px;
}

.projectUserManagementTableItem:nth-child(odd) {
  background-color: var(--border-color-gray-00-5);
}
.noUsersProjectUserManagement {
  background: rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  color: var(--color-text-3);
  font-weight: 500;
}

.projectUserManagementRefreshButton{
  color:var(--color-bad);
  cursor: pointer;
}
.projectUserManagementRefreshButton:hover{
  color:var(--color-bad-hover);
}



#appAlerts{
  width:calc(100% - 20px);
  display:flex;
  justify-content: center;
  flex-direction: column;
  margin:10px;


}

.homeAlert-block{
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin:10px;
}

.alertFrame{
  flex:1;
  width:calc(100% - 20px);
  margin:2px 10px;
}


.homeAlert-headerWrap{
  width:100%;
  padding:20px;


  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

}

#welcomeAlert{
  font-size:22px;
  font-weight:500;

  color:var(--color-text-1);

}

.homeAlert-username{
  font-weight: 600;
  font-family: 'Fira Sans', sans-serif;
  color:var(--color-migso-blue)

  
}

/*_____QUOTE____________________*/
.homeAlert-quote-wrap{
  width:fit-content;
  max-width:50%;
  background-color: var(--border-color-gray-00);
  border-radius: 5px;
  display:flex;
  flex-direction: column;
  margin: 2px 2px 2px 30px;
  padding:2px 10px 2px 10px;

}
.homeAlert-quote{
  font-size:12px;
  color:var(--border-color-gray-4);
  font-style: italic;

  
}

.homeAlert-author{
  font-size:12px;
  color:var(--border-color-gray-1);
  
  width:100%;
  display:flex;
  justify-content: flex-end;
}

#homeHeader{
  width: 100%;
  height: fit-content;
  position: sticky !important;
  top: 0px !important;
  z-index: 900;
  /* opacity: 50; */
  padding-top: 25px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  border-bottom: 1px solid var(--border-color-gray-1);

  }
.homeHeader_phrase{
  font-size: 30px;
  color:var(--color-text-2);
  margin:4px;
  margin-top:30px;
  font-weight: 600;
}

.homeHeader_menu{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 40px;
  width:calc(100% - 50px);
}

.homeHeader_orgList{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin:4px;
  flex:4

}

.homeHeader_org{
  margin:4px;
  padding:2px 10px;
  width:fit-content;
  height: fit-content;
  border-radius: 20px;
  background-color: var(--color-migso-blue);
  color:white;
  border: 1px solid var(--color-migso-blue);

}

.homeHeader_org:hover{
  cursor:pointer;
  margin:4px;
  padding:2px 10px;
  width:fit-content;
  height: fit-content;
  border-radius: 20px;
  background-color: var(--color-migso-blue-light);
  color:var(--color-migso-blue);
  border: 1px solid var(--color-text-2);

}


  #homeHeader-title {
      display:flex;
      align-items: center;
    height: auto; /* 36px */
    width: 239px;
    font-family: "Inter", sans-serif;
    font-weight:700;
    color: #ffffff;
    font-size: 14px;
    margin: 0px 0px 0px 28px;
    flex:1
    }

  .homeHeader_searchAndToggle{
    display:flex;
    justify-content: flex-end;
    flex:1;
    padding:10px;

  }

.homeHeader_search{
 width:100%
}

.homeHeader_toggle{
  width: fit-content;
  display: flex;
  align-items: center;
}

.bodyCard{
  transition: box-shadow .3s;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height:100%;
  width: 100%;
}

.bodyCard:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
  
}

.bodyCard-title{
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  
}

.bodyCard-keyMessage{

  
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 10px;
  height: 100%;
}

#bodyCard-keyMessage-grid{
  height: 100% !important;
  margin-top: 20px;
}

.bodyCard-keyMessage-text{

  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:normal;
  font-size:12px;
  font-style:italic;
  color:var(--color-text-2)


}

.bodyCard-keyMessage-text-list{
  height:100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
}

.bodyCard-ragStatus{
  padding: 4px 0px;
  bottom:0;
  width:fit-content;
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;
}

.portfolioCar-footer{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--border-color-gray-00);
  border-top: 1px solid rgba(0,0,0,.125);
  height:36px;
  width:100%;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.portfolioCar-footer-text{
  font-size:.875em;
  font-style: italic;
  color: #6c757d!important;
  width:fit-content;
  white-space: nowrap;
  padding:2px 6px;

}

.portfolioCar-updateDate{
  margin-left:10px;
  font-style: normal;
  font-weight: 700;
  
}

.projectCard_projectStatus{
  position: absolute;
  font-size: 30px;
  top:6px;
  right:6px;

}
#projectCard_projectStatus_background{
  top:10px;
  right:10px;
  border-radius: 30px;

}
.bodyCard-image{
  height: 220px;
  width: 100%; /*170px;*/
  justify-content:center;
  align-items: center;
  display: flex;
  overflow: hidden;
  border-top-left-radius:8px;
  border-top-right-radius:8px;
  position: relative;
  border-bottom: 1px solid var(--color-migso-blue);
}

.img-shadow{
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% + 2px);
  height: 60px;
  opacity: .4;
  background: linear-gradient(0deg,rgb(206, 206, 206) 10%,transparent);
}

.bodyCard-image-editIcon{
  position:absolute;
  bottom:5px;
  right:5px;
  color:var(--color-text-4);
  z-index:900;
  display:none;
}

.bodyCard-image-editIcon:hover{

  color:var(--color-migso-blue);
  
}


.bodyCard-image:hover .bodyCard-image-editIcon{
  display:block;
}

.portfolio-img{
  object-fit: cover;
   height:100%;
  width: 100%;
  
}

.card-img-top {
  border-radius: 0px;
}




.portfolioCard-container-grid{
  border:1px solid var(--border-color-gray-1);
  height:100%;
  border-radius:8px;
}

.bodyCard-wrap{
  height:150px;
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
}
.projectCard_tagFigures_wrap{
  width:100%;
}

/*/////////////////////////////////////////////////////////////////////////
LIST                
////////////////////////////////////////////////////////////////////////*/



.portfolioCard-container-list{
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 0;
  max-width: 100%;
  height:300px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: box-shadow .3s;
  flex-wrap: wrap;

}

.portfolioCard-container-list:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

/*   PICTURE AND NAME */
.portfolioCard-pictureAndName{
  flex-direction: column;
  display: flex;
  align-items: stretch;
  width: 200px;
  border-right: 1px solid var(--border-color-gray-0);
  height: 100%;
  justify-content: space-between;
}
#bodyCard-image-list{
  border-top-left-radius: 8px !important ;
  border-radius:0px;
}


/* .portfolioCard-picture-item{
  /* height:180px; } */
.portfolioCard-picture{
  height:180px;
}

.portfolioCard-name{
  width:100%;
  height: 40px;
  position: relative;
}


.portfolioCard-name-item {
  border: 1px solid var(--color-migso-blue);
  border-radius: 30px;
  color: var(--border-color-gray-5);
  font-weight: 700;
  height: 30px;
  max-width: 180px;
  padding: 4px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  top: -15px;
  background-color: var(--border-color-gray-0);
}

#portfolioCard-name-item-list{
  top: -20px;
}

/*   REVIEWS */
.portfolioCard-reviews{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  border-right:1px solid var(--border-color-gray-0);
  margin:10px 4px;
}

.portfolioCard-title{
  width:100%;
  flex:1
}
.portfolioCard-review{
  flex-direction:column ;
  flex:3
}

.portfolioCard-review-label{
  margin:4px 10px;
}

.portfolioCard-review-date{
  padding:2px 20px;
  border-radius: 30px;
  background-color: var(--border-color-gray-2);
}

/*   WORKFLOW and KPIs */
.portfolioCard-workflowAndKpis{
  flex:3;
  flex-direction:column;
  height: 100%;
}

.portfolioCard-workflowAndKpis-item{
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
}
.portfolioCard-workflowAndKpis-title{

  width:100%;
  height:30px;
}
.portfolioCard-workflow-content{
  flex:4
}

.portfolioCard-workflow-wrapItem{
  margin:4px 20px;
  height:60px;
  width:80px;
  /*border:1px solid var(--border-color-gray-0);
  border-radius:5px;*/
  flex-direction: column;
  
}

.portfolioCard-workflow-count{
  padding:6px;
  margin:6px;
  border-radius:40px;
  width:30px;
  height: 30px;

}

.portfolioCard-status-content{
  height:70px;

}

.portfolioCard-buttonDetail{
  display: flex;
  justify-content: flex-end;
  width:100%;
  padding:2px 10px;
}


#portfolioCard-grid{
width: 240px;;
height: 408px;
margin: 20px;
text-align: left;
position: inherit;
border-radius: 8px;
}

#portfolioCard-list{
  width: calc(100% - 40px);
height: auto;
margin: 20px ;
position: inherit;

}







/*OLD CSS */
.bodyList-header{
  text-align: center;
}

.bodyList-reviews{
  padding-top: 10px
}

.bodyList-kpi{
  padding-top: 10px;
  justify-content: center;
}

.bodyList:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.bodyList-title{
  font-weight: 700;
  white-space: nowrap;
}

.card-header:first-child{
  border-radius: 20px;
  background-color:transparent;
  text-align: center;
  border: 1px solid rgba(0, 0,0, .125);
}


.keyMsgInput{
  display: block;
  /*width: 100%;*/
  padding: .375rem .75rem;
  /*font-size: 1rem;*/
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.bodyList{
  transition: box-shadow .3s;
}

.bodyList-header{
  text-align: center;
}

.bodyList-reviews{
  padding-top: 10px
}

.bodyList-kpi{
  padding-top: 10px;
  justify-content: center;
}

.bodyList:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.bodyList-title{
  font-weight: 700;
  white-space: nowrap;
}

.card-header:first-child{
  border-radius: 20px;
  background-color:transparent;
  text-align: center;
  border: 1px solid rgba(0, 0,0, .125);
}

.homeBody-grid-container{

  display:flex;
  
  flex-direction: row;
  justify-content: flex-start;
  width:100%;
  
  flex-wrap: wrap;
  
  margin-top:20px;
  scroll-behavior: smooth;
  position:relative;
  top: 30px
  }
  
  .home-project-container{
      display:flex;
      flex-direction: column;
      justify-content: center;
      
      width:100%;
      margin: 10px 40px;
      border-bottom: 3dpx solid white;
  }
  
  
  .home_project_orgTitleWrap{
      display: flex;
      flex-direction: row;
  
      align-items: center;
      justify-content: space-between;
      margin-left:60px;
      padding:10px 20px;
      background-color: white;
      border-radius:5px;
      border: 1px solid var(--border-color-gray-1);
  
  
  }
  
  .home_project_orgTitleWrap_side{
      display: flex;
      align-items: center;
      
  }
  .home_project_title{
      font-size: 18px;
      font-weight: 650;
      color:var(--color-text-2);
      margin:2px 20px;
  }
  
  .home_project_orgInfo{
    display: flex;
    flex-direction: column;
    margin-left:50px;
    padding-left:10px;
    border-left: 2px solid var(--border-color-gray-0);
  }
  
  .home_orgStatusAndProjects{
      display: flex;
      flex-direction: row;
  }
  .home_project_tags{
      background-color: var(--border-color-gray-1-5);
      color: white;
      border: 1px solid var(--border-color-gray-1-5);
      padding: 0px 5px;
      border-radius: 4px;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      font-size: 14px;
      font-family: "Segoe UI",Tahoma,Geneva,Verdana,sans-serif;
      height: 25px;
  
  }
  .home_project_tag_label{
      font-weight: 500;
      font-size: 14px;
      font-family: "Segoe UI",Tahoma,Geneva,Verdana,sans-serif;
  }
  .home_project_labelAdmin{
      font-weight: 500;
      font-size: 14px;
      font-family: "Segoe UI",Tahoma,Geneva,Verdana,sans-serif;
  }
  
  #home_project_tagAdminUsers{
      color: var(--color-migso-blue);
      font-weight: 500;
      font-size: 14px;
      font-family: "Segoe UI",Tahoma,Geneva,Verdana,sans-serif;
      background-color:rgb(0,0,0,0);
      
  }  
  
  .home_project_settings{
      font-size: 30px;
      color: var(--border-color-gray-1);
      cursor:pointer
  }
  
  .home_project_settings:hover{
      font-size: 31px;
      color: var(--border-color-gray-2)
  }
  
  .home-project-content{
      display: flex;
      flex-direction: row;
      
  }
  
  #home-project-content-grid{
  
      margin-left: 40px;
  }
  
  #home-project-content-list{
  
      display: flex;
      flex-direction: column;
      width:100%
  }
  
  .home-project-noProject{
      border:1px solid var(--border-color-gray-0);
      
      border-radius: 5px;
      padding:4px;
      margin:20px 20px 20px 60px;
      color:var(--color-text-4);
      background-color:var(--border-color-gray-00);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      font-weight: 600;
  }
  
  #home-project-noProject-grid{
      width: 240px;
      height: 408px;
  }
  #home-project-noProject-list{
      width: calc(100% - 40px);
      
  }
  .home-project-noProject-icon{
      padding:1px 4px;
      color:var(--color-bad-bg)
  }
  




.alertCard-header{
    display:flex;
    flex-direction:row;
    width:100%;
    margin-bottom:6px;
}

.alertCard-header-title{
    display:flex;
    flex:1;
    width:auto;
    justify-content: flex-start;
    margin:2px 10px 2px 10px;
}

.alertCard-header-search{
    display:flex;
    flex:1;
    width:auto;
    justify-content: flex-end;
    align-items: center;
    margin:2px 0px 2px 0px;
}

.navbar{
    justify-content: flex-end !important;
}


/*____ TABLE __________*/
.alertFrame-table{

    border:0px;
    table-layout: auto;
    width:100%;

}

    .alertFrame-table-header{
        margin:0px;
        padding:0px;
        height:26px;
        position:sticky;
        top:0;
        background-color: var(--border-color-gray-00);
    }

    /* .alertFrame-table-header-itemWrap{

    } */

    .alertFrame-table-header-item{
        margin:0px;
        padding:1px 6px;
        font-size:12px;
        font-weight:600;
        color:var(--border-color-gray-2);
        display:flex;
        justify-content: flex-start;

    }

.alertFrame-table-body{
    font-size:12px;
    color:var(--color-text-1)
}
    .alertFrame-table-body-late{
        color:var(--color-bad);
        display:flex;
        flex-direction: column;
        min-width: 100px;
        margin-bottom:-1px;
        
        
    }

    .alertFrame-table-body-latedetails{
        color:var(--color-text-3);
        font-size:10px;
    }

    /* HELP ------------------------------------ */

    .help{
      position: absolute;
      z-index:1200;
      bottom:40px;
      right:20px;
      display:flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
  }
  
  .help-button{
      width:150px;
      height:30px;
      border-radius:100px;
      background-color:var(--color-migso-blue);
      border:1px solid rgba(0, 0, 0,0.2);
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0,0.3);
      display:flex;
      justify-content: center;
      align-items: center;
      color:white
  
  }
  
  .help-icon{
      font-size:27px;
      font-weight: 300;
      margin-right:8px;
      /* color:#e557657c */
  }
  
   .help-text{
      padding-bottom:2px;
      font-weight: 650;
      font-size:14px;
      font-family: 'Oswald', cursive;
      letter-spacing: 1px;
  } 
  
  .help-button:hover{
      background-color: white;
      border:1px solid rgba(0, 0, 0,0.5);
      cursor:pointer;
      color:var(--color-migso-blue) !important;
      
  }
  
  
  /* MENU */
  
  .help-menu{
  
      height:fit-content;
      width:fit-content;
      background-color:var(--border-color-gray-0);
      border:1px solid var(--border-color-gray-0);
  
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
  
      margin: 4px 0px;
  }
  
  .help-menu-header{
      font-size:14px;
      font-weight: 600;
      font-style:italic;
      background-color:var(--border-color-gray-00);
      color:var(--color-migso-blue);
      display:flex;
      align-items: center;
      justify-content: center;
      width:100%;
  
  }
  
  .help-menu-title{
      background-color:var(--border-color-gray-1);
      color:var(--color-text-2);
     padding:0px 10px;
     min-height:2px;
  }
  
  
  .help-menu-item{
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid var(--border-color-gray-1);
      margin:0px;
      padding:4px 20px;
      color:var(--color-text-3);
      background-color:rgba(255, 255, 255, 0.911);
  }
  
  .help-menu-item:hover{
      cursor: pointer;
      color:#ffffff;
      background-color: var(--border-color-gray-1);
      /* font-weight:600; */
  }
  
  .help-menu-item-text{
      color:var(--color-text-3)
  }
  .help-menu-item-text:hover{
      color:white
  }
  .send_email_btn {
    background-color: var(--color-good);
    font-weight: 400;
    cursor: pointer;
    color: white;
}

.send_email_btn:hover {
    background-color: var(--color-good-hover);
    color: white;
}

/* INPUTS CONTAINER */
.email_modal_inputs_container {
    width: 100%;
}

/* HIDE FILE INPUT */
input[type="file"]{
    display: none;
}

.append_files_container {
    display: flex;
    flex-direction: row;
    padding: 1em;
    background-color: var(--color-grey-light);
    width: fit-content;
    align-items: center;
    border: 1px solid var(--border-color-gray-2);
    border-radius: 0.2rem;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    max-height: 70px;
}

.append_files_container:hover {
   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0,0.3);
   color: var(--color-migso-blue);
}

.append_files_container_title {
    padding: 0em 0.2em;
    font-size: 0.9rem;
}

.files_upload_block_container {
    display: flex;
    flex-direction: row;
}

.files_list_container {
    flex: 1;
    display: grid;
   
}


.file_card {
    max-width: 100%;
    height: fit-content;
    background-color: var(--color-grey-light);
    margin: 0.3em 0.5em;
    padding: 0.3em;
    border: 1px solid var(--border-color-gray-2);
    color: black;
    font-weight: 500;
    border-radius: 0.2rem;
    transition: 0.1s all ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.file_card:hover {
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0,0.3);
}

.delete_file_icon {
    color: var(--color-bad);
    cursor: pointer;
    border-radius: 0.25rem;
    transition: 0.2s all ease-in-out;
}
.delete_file_icon:hover {
    color: var(--color-bad-hover);
    background-color: var(--border-color-gray-2);
}



/* TO BE DELETED ----------------------------------------------- */


  /*##################################################################
  #####_____________________END COMMON__________________________######
  ##################################################################*/
  
  /* * {
    -webkit-box-sizing: border-box !important;
       -moz-box-sizing: border-box !important;
            box-sizing: border-box !important;
  }
  *:before,
  *:after {
    -webkit-box-sizing: content-box !important;
       -moz-box-sizing: content-box !important;
            box-sizing: content-box !important;
  } */
  
  .table-light{
    width: 100%;
    position: sticky !important;
    top:0px !important;
    z-index: 1;
    opacity: 50;
    background-color:var(--color-migso-blue);
  }
  
  #searchBar{
    overflow: hidden;
  }
  

  
  thead button {
    border: 0;
    border-radius: none;
    font-family: inherit;
    font-weight: 700;
    font-size: inherit;
    padding: 0.5em;
    margin-bottom: 1px;
    white-space: nowrap;
    background-color: transparent;
  }

  
  thead button.ascending::before {
    content: '⬇';
    display: inline-block;
    
  }
  
  thead button.descending::before {
    content: '⬆';
    display: inline-block;
  }
  
  tbody td {
    padding: 0.5em;
    border-bottom: 1px solid #ccc;
  }
  
  tbody tr:hover {
    background-color: #eee;
  }

  i.material-icons {
    display: none;
    color:rgb(255, 255, 255);
  }


  i.material-icons:hover {
    cursor:pointer;
    color:rgb(104, 104, 104)
  }



  tr:hover i.material-icons {
    display: inline-block;

  }



  .table-responsive{
    white-space: nowrap;
    overflow-x: visible;
  }

  .table-light{
    --bs-table-bg: white;
  }



  #appActionsModal{
    max-height: 400px;
    overflow: scroll;
    padding: 0px;
    -ms-overflow-style: none;  /* IE and Edge hide scrollbar */ 
    scrollbar-width: none;  /* Firefox hide scrollbar */ 
  }

  #lateDate{
    color: var(--color-bad);
  }



  /* DESIGN BOX TO BE USE IN SEVERRAL PLACES IN APP */
  .template-box{

    border: 1px solid var(--border-color-gray-1);
    border-radius: 5px;
    box-shadow: 2px 2px 7px -1px rgb(112 112 112 / 79%);
  }

  .bodyWrap{
    margin:0 20px;
  }


  .arrow-back{
    color:var(--color-text-4)
  }

  .arrow-back:hover{
    cursor: pointer;
    color:var(--color-migso-blue)
  }


  .header1{

    font-size:16px;
    font-weight: 700;
    color:var(--color-migso-blue);
    padding-bottom: 3px;
  }

/* COMMON Title */
.SectionTitle {
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  padding-left: 10px;
  border-bottom: 1px solid var(--color-migso-blue);
  color: var(--color-migso-blue);
}


/* ACTIONS */



.actionsDetailsStatusFilterRemove {
  color: var(--color-bad);
  position: absolute;
  right: -7px;
  top: -10px;
  font-size: 20px;
  z-index: 2;
  display: none;
}

.actionsDetailsStatusTagContainer {
  border-radius: 15px;
  padding: 3px;
  margin: 5px 6px;
  padding: 2px;
  background: var(--color-grey-light);
}



.actionBigActionsTableSearchBarBlock {
  /* min-width: 250px; */
  width: 50%;
  margin-right: 5px;
  min-width: 80px;
}
.actionBigActionsTableSearchBar {
  border: 1px solid var(--border-color-gray-2);
  border-radius: 15px;
  outline: none;
  height: 35px;
  transition: 0.2s all ease-in-out;
  padding: 0px 10px;
}
.actionBigActionsTableSearchBar:focus {
  border: 1px solid var(--color-migso-blue); 
}
.actionBigActionsTableSearchBar::placeholder {
  font-style: italic;
  color: var(--color-text-5);
}
.actionBigActionsTableSearchIcon {
  margin-left: -30px;
  color: var(--color-text-5);
  background: white;
  z-index: 2;
}


.tableItemCheckbox { 
  height: 18px;
  width: 18px;
  min-width:18px;
  border: 1px solid var(--border-color-gray-3);
  margin: 0 2px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
}
.tableItemCheckbox:hover {
  background: var(--border-color-gray-2);
  transform: scale(1.2);
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-5px);} 
  60% {transform: translateY(-5px);} 
} 
.tableItemNewText {
  width: 30px;
  /* height: 100%; */
  color: var(--color-good);
  font-size: 10px;
  margin: 0px 5px;
  font-weight: 700;
  text-align: center;
  align-items: center;

  /* animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; */
}
.actionBigTableItemDot {
  width: 10px;
  height: 10px;
  background: var(--color-bad);
  border-radius: 2px;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0,0.3);
}


.tableItemName {
  color: var(--color-text-4);
  width: 100%!important;
  max-width: 100%!important;
  padding: 5px 2px;
  overflow-y: visible;
  height: 100%;
}

.actionBigTableItemStatusTagContainer {
  position: relative;
}
.actionBigTableItemStatusTag {
  width: 13%;
  min-width: 80px;
  text-align: center;
  padding: 3px 0px;
  margin: 0 5px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 5px;
  font-size: 13px;
}
.actionBigTableItemSelectableStatusTag {
  transition: 0.2s all ease-in-out;
  padding: 2px 0px;
  border: 1px solid transparent;
}
.actionBigTableItemSelectableStatusTag:hover{
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0,0.3);
  border-color: var(--color-migso-blue);
}
.actionBigTableItemStatusDropdownList {
  position: absolute;
  width: 100%;
  border-radius: 5px;
  background: white;
  z-index: 999;
  padding: 4px 0px;
  border: 1px solid var(--border-color-gray-2);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0,0.3);
}
.actionBigTableItemStatusDropdownList:hover {
  border-color: var(--color-migso-blue);
}
.actionBigTableItemStatusDropdownListButton {
  background: var(--color-grey-light);
  margin: 0px 4px;
  margin-top: 3px;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.1s all ease-in-out;
}
.actionBigTableItemStatusDropdownListButton:hover {
  color: white;
  background: var(--color-migso-blue);
}


.actionBigTableItemResponsible {
  /* flex: 1; */
  position:relative;
}




.actionsTableExpandArrow, .actionsGraphExpandArrow, .bigComponentExpandArrow{
  display: none;
  font-size: 14px;
  position: absolute;
  padding: 2px;
  border-radius: 15px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  border: 1px solid var(--border-color-gray-2);
  transition: 0.3s all ease-in-out;
}
.actionsGraphExpandArrow:hover, .actionsTableExpandArrow:hover, .bigComponentExpandArrow:hover{
  background: var(--color-migso-blue);
  color: white;
}
.actionsTableClearFilters {
  font-weight: 500;
  cursor:pointer;
  padding: 2px 5px;
  border-radius: 5px;
  transition: 0.2s all ease-in-out;
  background: var(--color-bad);
  color:var(--color-grey-light);
}
.actionsTableClearFilters:hover {
  background: var(--color-bad-hover);
  color:white;
}
.actionBigTableInnerContainer {
  background: var(--border-color-gray-0);
  margin: 0 auto;
  width: 100%!important;
  height: 100%;
  padding: 0 5px;
  /* overflow-y: auto; */
  position: relative;
}

.actionBigTableInnerContainer::-webkit-scrollbar {
  display: inline-block;
  width: 8px;
  height: 8px;

  background-color: var(--border-color-gray-00);

  border-radius: 10px;

  transition: 0.3s;
}

.actionBigTableInnerContainer::-webkit-scrollbar-thumb {
  background-color: var(--border-color-gray-2);
  border-radius: 8px;
}

.actionBigTableInnerContainer::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-migso-blue);
}
.ellipsisOneLine {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-y: visible;
}
.actionBigTableItemConnectionIcon {
  color: white;
  margin: 0 3px;
  background: var(--color-migso-blue);
  font-size: 16px;
  padding: 3px;
  border-radius: 100%;
}
.actionsBigViewModeContainer {
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  /* background: lightblue; */
}
.actionBigViewModeProgressBarContainer {
  margin-top: 25px;
  flex: 1;
  width: 14px;
  background-color: var(--color-grey-light);
  position: relative;
}
.actionViewModeFilledProgressBar {
  width: 100%;
  height: 90%;
}
.actionBigViewModeDot {
  width: 22px;
  height: 22px;
  position: absolute;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0,0.3);
  border: 1px solid var(--border-color-gray-2);
  border-radius: 15px;
}
.actionBigViewModeDotText {
  margin-left: 35px;
  min-width: 100px;
  white-space: nowrap;
  width: fit-content;
  font-style: italic;
  font-size: 13px;
  color: var(--color-text-3);
  max-lines: 1;
}
.actionBigViewModeColumn {
  height: 100%;
  padding: 0px 20px;
}
.actionBigViewModeName {
  font-size: 20px;
  padding: 0px 12px;
}

.actionsViewModeTextAreasContainer {
  flex: 1;
  width: 100%;
  margin-top: 10px;
  border: 1px solid var(--border-color-gray-1)!important;
  border-radius: 5px;
}
.actionsViewModeTextAreaSelect {
  background: var(--color-grey-light);
  width: 100%;
}
.actionsViewModeTextAreaSelectOption {
  color: var(--color-text-4);
  cursor: pointer;
  padding: 5px 8px;
  transition: 0.1s all ease-in-out;
}
.actionsViewModeTextAreaSelectOption:hover {
  background: white;
  color: var(--color-text-2);
  font-weight: 400;
}
.actionsViewModeTextAreaSelectedOption {
  color: var(--color-text-2);
  background: white;
  font-weight: 400;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0,0.3);
  border-bottom: 2px solid var(--color-migso-blue);
}
.actionsViewModeTextAreaContent {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  max-height: 220px;
  position: relative;
}

.actionsViewModeTextAreaContent::-webkit-scrollbar {
  display: inline-block;
  width: 4px;
  height: 8px;

  background-color: var(--border-color-gray-00);

  border-radius: 10px;

  transition: 0.3s;
}

.actionsViewModeTextAreaContent::-webkit-scrollbar-thumb {
  background-color: var(--border-color-gray-2);
  border-radius: 8px;
}

.actionsViewModeTextAreaContent::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-migso-blue);
}

.viewModeDescriptionText {
  padding: 8px 5px;
  color: var(--color-text-3);
  flex: 1;
}
.flex1 {
  flex: 1;
}



.viewModeDescriptionEntry {
  margin: 10px;
  padding: 0px 4px;
  background: var(--border-color-gray-0);
  border: 1px solid var(--border-color-gray-1);
  border-radius: 5px;
  width: calc(100% - 20px)!important;
}
.outerViewModeDescriptionInput {
  width: 100%;
  height: 100%;
}

.actionsViewModeDescriptionBlock {
  max-width: 100%;
}
.viewModeBlockInput {
  background: white;
  border-radius: 5px!important;
}
/* .actionsBigViewModeBlockInputTextArea {
 
} */
.viewModeInput {
  border: none;
  color: var(--color-text-1);
  background: white;
  font-size: 14px;
}
.viewModeInput:focus {
  outline: none;
  border: none;
}
.viewModeInputTextArea {
  padding: 15px 3px;
}
.viewModeInput::placeholder {
  font-style: italic;
  color: var(--color-text-5);
}
.viewModeNoContent {
  width: 100%;
  font-size: 17px;
  height: 80%;
  font-weight: 600;
}
.viewModeConfirmButton {
  color: var(--color-migso-blue);
  cursor: pointer;
  border-radius: 5px;
  font-size: 22px;
  padding: 3px;
  transition: 0.2s all ease-in-out;
  animation-name: opacityIn;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  /* height: 100%!important; */
}
.viewModeConfirmButton:hover {
  background: var(--color-migso-blue-hover);
  color: white;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0,0.3);
}
.actionBigViewModeDependencyHeaders {
  width: 100%;
  padding: 3px;
}
.actionBigViewModeDependencyIconTitle {
  margin: 0px 3px;
}
.actionBigViewModeDependencyIcon {
  color: var(--color-migso-blue);
  font-size: 28px;
}
.actionBigViewModeDependencyTitle {
  font-size: 18px;
  font-weight: 500;
  margin-left: 4px;
}
.actionBigViewModeDependencyConnector {
  font-size: 12px;
  margin: 0 5px;
  font-weight: 500;
}
.actionBigViewModeDependencyTag {
  background: var(--color-escalated);
  padding: 2px 25px;
  margin: 0 3px;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
.actionBigViewModeDependencyInner {
  flex: 1;
  background: white;
  width: 97%;
  padding: 0px 8px;
  margin: 5px auto;
  /* border: 1px solid var(--border-color-gray-1); */
  border-radius: 5px;
}
.actionBigViewModeConnectedTableContainer {
  height: 80%;
  background: var(--border-color-gray-0);
  border: 1px solid var(--border-color-gray-1);
  flex: 1;
  margin: 30px 10px;
  border-radius: 8px;
}
.actionBigViewModeConnectedTableHeaders {
  width: 100%;
}
.actionBigViewModeConnectedTableHeaderTotalCount {
  padding: 4px 10px;
  margin: 8px;
}
.actionBigViewModeConnectedTableHeaderCount {
  background: var(--color-text-2);
  padding: 0px 6px;
  color: white;
  border-radius: 100%;
}
.actionBigViewModeConnectedTableContent {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.actionBigViewModeConnectedTableContent::-webkit-scrollbar {
  display: inline-block;
  width: 4px;
  height: 8px;
  background-color: var(--border-color-gray-00);
  border-radius: 10px;
  transition: 0.3s;
}

.actionBigViewModeConnectedTableContent::-webkit-scrollbar-thumb {
  background-color: var(--border-color-gray-2);
  border-radius: 8px;
}

.actionBigViewModeConnectedTableContent::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-migso-blue);
}


.actionBigViewModeConnectedTableItem {
  width: 100%; 
  border-bottom:1px solid var(--border-color-gray-1);
  padding: 10px 2px;
  background: white;
}
.actionBigViewModeConnectedTableItem:first-child {
  border-top: 1px solid var(--border-color-gray-1);
}
.actionBigViewModeConnectedTableItem:last-child {
  border-bottom: none;
}
.actionBigViewModeConnectedTableItemDot {
  width: 10px;
  height: 10px;
  margin: 0px 10px;
}
.actionBigViewModeConnectedTableItemDisplayId {
  margin: 0px 8px;
  font-weight: 600;
  font-size: 13px;
  max-width: 55px;
  width: 13%;
}
.actionBigViewModeConnectedTableItemName {
  flex: 1;
  font-size: 13px;
  color: var(--color-text-3);
  font-style: italic;
}
.actionBigViewModeConnectedTableBranchName {
  margin: 0px 5px;
  background: var(--border-color-gray-1);
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  max-width: 20%;
}

.ellipsisOneLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.syntheticEditableDropdownContainer {
  position: relative;
  border-radius: 5px;
  margin: 0 2px;
  transition: 0.1s all ease-in-out;
  padding: 5px 2px;

}
.syntheticEditableDropdownContainer:hover {
  background: var(--border-color-gray-0);
}
.syntheticEditableDropdownListContainer {
  position: fixed!important;
  /* width: calc(100% + 30px); */
  border-radius: 5px;
  background: white;
  z-index: 99999999999;
  /* bottom: 0; */
  padding: 4px 0px;
  border: 1px solid var(--border-color-gray-2);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0,0.3);

  height: fit-content;
  max-height: 100px;
  overflow-y: auto;
}

.syntheticEditableDropdownListContainer::-webkit-scrollbar {
  display: inline-block;
  width: 8px;
  height: 8px;

  background-color: var(--border-color-gray-00);

  border-radius: 10px;

  transition: 0.3s;
}

.syntheticEditableDropdownListContainer::-webkit-scrollbar-thumb {
  background-color: var(--border-color-gray-2);
  border-radius: 8px;
}

.syntheticEditableDropdownListContainer::-webkit-scrollbar-thumb:hover {
  /* background-color: var(--border-color-gray-4); */
  background-color: var(--color-migso-blue);
}


.synthethicEditableDropdownButton {
  background: var(--color-grey-light);
  margin: 0px 4px;
  margin-top: 3px;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.1s all ease-in-out;
}
.synthethicEditableDropdownButton:hover {
  color: white;
  background: var(--color-migso-blue);
}
.syntheticEditableDropdownSelectable {
  transition: 0.2s all ease-in-out;
  padding: 2px 0px;
  border: 1px solid transparent;
}
.syntheticEditableDropdownSelectable:hover{
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0,0.3);
  border-color: var(--color-migso-blue);
}
.statusTag {
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 3px;
  padding: 2px;
  border-radius: 5px;
  width: 100%;
}
.enrichTextEditorWrapper {
  width: 100%;
}
.public-DraftEditor-content{
  word-break: break-word;
  height: 100%;
  padding: 10px;
  min-height: 42px;
}
.userOptionLabel {
  font-weight: 500;
  margin-left: 2px;
  max-lines: 1;
  height: 100%;
}

.toggleIconModalTitle{
  color: rgba(3, 169, 219, 0.6);
  /* margin: 0px 0px 7px -10px; */
  font-size: 12px;
} 

.toggleIconActionIcon {
  color: var(--color-migso-yeallow);
  margin-right: 10px;
  cursor: pointer;
}
.toggleIconRiskOppIcon {
  color: var(--color-escalated)!important;
  /* margin-right: 10px!important;
  cursor: pointer; */
}
.toogleIconRiskOppIcon:hover {
  color: var(--color-migso-yeallow-hover)!important;
}
.enrichTextEditContainer {
  display: flex;
  flex-direction: column;
  width: 100%!important;
  max-width: 100%!important;
  padding: 0px 7px;
}
.public-DraftEditorPlaceholder-root {
  color: var(--color-text-5);
  position: absolute;
  top:0;
  left:0;
  padding: 10px
}

/* ENRICH TEXT CHECK --------------------------------------------------- */

.enrichTextEditor::placeholder {
  font-style: italic!important;
  font-weight: bold;
} 
.enrichTextEditor {
  width: 100%!important;
  height: 100%;
  background-color: red;
}
.enrichTextEditorHeader{
  width: 100%;
  position: absolute;
  top:0;
  left:0
}
.enrichTextEditContent{
  height:100%;
  width:100%
}
.enrichTextEditWrapper {
  width:100%;
}
.enrichTextEditWrapper .DraftEditor-root{
  overflow-y: auto;
  max-height: 100% ;
  height: 100%;
  position: relative;
}
.DraftEditor-root {
  padding: 0px!important;
}

.DraftEditor-editorContainer{
  height: 100%;
}
.enrichTextEditWrapper .DraftEditor-root::-webkit-scrollbar {
  border-radius: 8px;
}
.enrichTextEditWrapper .DraftEditor-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
}
.enrichTextTilte{
  font-weight: 500;
  color: var(--color-text-1);
  padding: 10px 15px;
  border-bottom: 1px solid var(--border-color-gray-0) ;
  height:42px
}
.enrichTextTilteSideBar{
  min-width: 100%;
  max-width: 100%;
  margin: 0px 0px 5px;
  color: rgba(3, 169, 219, 0.6);
  font-size: 12px;
  font-weight: 500;
}
@keyframes enterEnrichToolbar {
  from {
    opacity: 0;
    margin-top: -22px;
  }

  to {
    opacity: 1;
    margin-top: 0px;
  }
}
.enrichToolBarContainer {
  width: 100%;
  min-height:42px;
  max-height: 42px;
  padding: 5px 12px;
  border-bottom: 1px solid var(--border-color-gray-0);
  
  /* ENTER ANIMATION */
  animation-name: enterEnrichToolbar;
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}
.enrichToolBarSubContainer {
  margin-right: 7px;
  border-right: 1px solid var(--border-color-gray-2);
  position: relative;
}
.enrichToolBarSubContainer:last-child{
  border-right: none;
  margin-right: 0px;
}

/* NOT CHECK */

.commonToolbarItem {
  width: 20px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background-color: white;
  color: var(--color-migso-blue);
  border: 1px solid var(--color-migso-blue);
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition:0.3s
}
.commonToolbarItem:hover{
  color: white;
  background-color: var(--color-migso-blue);
  box-shadow: none;
}
.headingToolbarItem {
  width: 20px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background-color: var(--color-migso-blue);
  color: white;
  border: 1px solid var(--color-migso-blue);
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition:0.3s;
}
/* MEDIA QUERY */
@media screen and (max-width: 1130px) {
  .enrichToolBarContainer {
    padding: 5px 2px;
  }
  .enrichToolBarSubContainer {
    margin-right: 2px;
  }
  .commonToolbarItem, .headingToolbarItem {
    margin-right: 2px;
  }
}


.headingToolbarItem:hover {
  color: white;
  background-color: var(--color-migso-blue-hover);
  box-shadow: none;
}

.commonToolbarItem > .material-icons {
  font-size: 17px;
}
.headingToolbarItem {
  width: fit-content;
  /* height: 30px; */
  justify-content: space-between;
  border-radius: 5px;
  padding: 0px 3px;
  white-space: nowrap;
}
.commonActiveToolbarItem, .activeHeadingToolbarItem{
  color: white;
  background-color: var(--color-migso-blue);
  box-shadow: none;
  border: 1px solid var(--color-migso-blue);
  padding: 2px;
  border-radius: 5px ;
}

.inlineStyleDropdownMenuContainer {
  background:white;
  border: 1px solid var(--border-color-gray-1);
  position: absolute;
  /* min-width: 100px; */
  left: calc(100% - 20px);
  top: calc(100% + 5px);
  z-index: 9999;
}
.inlineStyleDropdownEntry, .inlineStyleActiveDropdownEntry {
  padding: 5px 8px;
  transition: 0.1s all ease-in-out;
  width: 100%;
  color: var(--color-text-2);
  font-weight: 500;
}
.inlineStyleDropdownEntry:hover{
    background: var(--color-grey-light);
}
.inlineStyleActiveDropdownEntry {
  background: var(--color-migso-blue);
  color: white;
}
.headingStylesSelectListContainer {
  position: absolute;
  background: white!important;
  bottom: 100%;
  left: 0;
  height: fit-content;
  z-index: 9999999;
  border: 1px solid var(--border-color-gray-1);
  width: 300px;
  border-radius: 5px;
}
.selectListheadingToolbarItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0px 3px;
  width: 100%; 
  transition: 0.1s all ease-in-out;
}
.selectListheadingToolbarItem:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.selectListheadingToolbarItem:hover {
  background-color:var(--color-migso-blue);
  color: white;
}
.activeSelectListHeadingToolbarItem {
  background-color: var(--color-migso-blue);
  color: white;
}
.commonToolbarItemShortcut {
  background: var(--color-grey-light);
  font-size: 11px;
  color: var(--color-text-3);
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid var(--border-color-gray-1);
}
.colorStylesToolbarMainItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-migso-blue);
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s all ease-in-out;
  /* margin-right: 7px; */
  height:22px
}
.colorStylesToolbarMainItem:hover {
  background-color: var(--color-migso-blue-hover);
  color: white;
}

.colorStylesToolbarMainText {
  margin-top: -3px;
  margin-left: 5px;
  font-size: 12px;
  position: relative;
  padding: 0px 3px;
}
.colorStylesToolbarMainText::after {
  content: '';
  height: 4px;
  position: absolute;
  left: 0;
  right:0;
  bottom: -2px;
  background: var(--bottom-border-color);
}
.colorStylesSelectListContainer {
  /* height: 100px;
  width: 100px; */
  position: absolute;
  background-color: white;
  border: 1px solid var(--border-color-gray-2);
  top: 100%;
  border-radius: 5px;
  padding: 4px;
  z-index: 1000;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
.colorStyleSelectListItem {
  animation-name: opacityIn;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  height: 30px;
  width: 30px;
  border: 1px solid var(--border-color-gray-1);
  cursor: pointer;
  transition: 0.1s all ease-in-out;
}
.colorStyleSelectListItem:hover {
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0,0.5);
  border-color: var(--border-color-gray-4);
}

@keyframes extendFromTop {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}


/* -------- BUG REPORT ------------ */
/* Bottom Support tab */
.bugReportTab {
  background-color: #02A8DB;
  position: absolute;
  z-index: 10000;
  width: 120px;
  height: 35px;
  bottom: 0;
  right: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}

.bugReportTab:hover {
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  background-color: #02A8DB;
  color: white;
}

/* Issue Content */
.supportIssueContentTitle {
  color: var(--color-migso-blue);
  font-weight: bold;
  margin-bottom: 10px;
}

.supportIssueContentText {
  color: var(--color-text-2);
}

.supportZoneContainer {
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;
}


/* FILTERS -------------------------------------------- */

.filterContainer{
  padding:20px
}

.filterHeader{
  background-color: var(--border-color-gray-00-5);
  border-bottom: 1px solid var(--border-color-gray-1);
  padding:12px 10px 8px 20px;
  margin-top: -8px;
}

.filterHeaderTitle{
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text-2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filterHeaderClose{
  color: var(--color-text-3);
  padding:0px;
  transition:0.3s
}

.filterHeaderClose:hover{
  color: var(--color-migso-blue-hover) !important;
}

.filterHeaderClose:focus{
  box-shadow: none !important;
}

.filterGroupContent{
  padding:5px 10px
}



.filterCheckBoxTxtAvatar{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left:5px;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-text-2);
}

.filterGroupButtonInfo{
  font-size: 11px;
  background-color: var(--color-migso-green);
  color:white;
  padding: 1px 0px 0px 0px;
  width:20px;
  height:20px;
  border: 1px solid var(--border-color-gray-0);
  border-radius: 15px;
  margin-right: 10px;
  position: relative;
}

.filterChildrenSubGroupTitle {
  font-weight: 600;
  font-size: 13px;
  margin: 10px 0px 5px 0px;
  text-decoration: underline;
  color:rgba(3, 169, 219, 0.6);
  text-transform: capitalize;
}

.filterReset{
  position:absolute;
  right:-8px;
  top:-6px;
  font-size:14px;
  color:var(--color-bad);
  background-color: white;
  border-radius: 9px;
  transition:0.3s;
}
.filterReset:hover{
  color:var(--color-bad-hover);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
}


.filterSearch{
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='%2303A9DB99'%3e%3cpath fill-rule='evenodd' d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  padding-left: 30px;
}

.filterSubGroupOverflow{
  max-height: 150px;
  overflow-y: auto ;
  overflow-x: hidden;
}

.filterSubGroupOverflow::-webkit-scrollbar{
  border-radius: 8px;
}

.filterSubGroupOverflow::-webkit-scrollbar-thumb{
  border-radius: 8px;
}
.filterNoResults{
  color: var(--color-text-4);
  font-size: 12px;
  margin: 5px;
}
.filterTagReset{
  position:absolute;
  opacity: 0;
  right:calc(50% - 8px);
  top:calc(50% - 8px);
  font-size:16px;
  color:var(--color-bad);
  background-color: white;
  border-radius: 9px;
  transition:0.3s;
}
.filterSectionAvatar{
  transition: 0.3s;
}
.filterSectionAvatar:hover{
  background-color: var(--color-bad) !important;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
}
.filterChildren {
  border-left: 1px solid var(--color-migso-blue);
  margin: 5px 8px 5px 30px;
  height: 100%;
}
.bigComponentSideBarDivider {
  border-bottom:1px solid var(--border-color-gray-1);
}

.riskMatrixContainer, .riskTableContainer, .detailedRoActionsTableContainer {
  height: 100%;
  margin: 0px 10px;
  border: 1px solid var(--border-color-gray-1)!important;
  background-color: var(--border-color-gray-00)!important;
  border-radius: 5px;
  position:relative;
  transition: 0.2s all ease!important;
}

.riskMatrixContainer:hover > #risk_matrix_expand_arrow {
  display: block;
}
.riskTableContainer:hover > #risk_table_expand_arrow {
  display: block;
}
.riskTableHeaders {
  width: 100%;
  padding-top: 8px;
  min-height: 40px;
}
/* SEARCH BAR */
.searchBarBlockCss {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid var(--border-color-gray-1);
  border-radius: 5px;
  width: 80%;
}
.searchBarInputCss {
  border: none;
  background: white;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  width: 90%;
}
.searchBarInputCss::placeholder {
  color: #bbb;
  font-style: italic;
}
.searchBarInputCss:focus {
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
}
.searchBarButton {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}
.searchBarButton:hover {
  opacity: 1;
}
.riskTableToggleTitle {
  margin: 0px 5px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600!important;
  transition: 0.2s all ease;
  /* animation-name: opacityIn;
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-iteration-count: 1; */
}
.riskTableInnerContainer {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  max-height: 80%!important;
  height: fit-content;
}
.riskTableInnerContainer::-webkit-scrollbar {
  display: inline-block;
  width: 8px;
  height: 8px;

  background-color: var(--border-color-gray-00);

  border-radius: 10px;

  transition: 0.3s;
}
.riskTableInnerContainer::-webkit-scrollbar-thumb {
  background-color: var(--border-color-gray-2);
  border-radius: 8px;
}
.riskTableInnerContainer::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-migso-blue);
}

.riskTable {
  width: 100%;
  margin-right: auto;
  overflow-x: visible;
  /* overflow-y: auto;
  max-height: 400px!important; */
  padding: 8px 5px;
  height: fit-content;
}
.riskTableItemContainer {
  padding: 10px;
  margin: 2px 0px;
  width: 100%;
  background: white;
  cursor: pointer;
  transition: 0.3s all ease;
}
.riskTableItemContainer:hover {
  background-color: var(--bg-color-selected-2);
}
.riskTableItemContainer:last-child {
  /* border-bottom: 1px solid var(--border-color-gray-1); */
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.riskTableItemContainer:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.riskTableItemEscalatedStatus {
  color: var(--color-bad);
  margin: 0px 8px;
  font-size: 23px;
  min-width: 25px;
  height: fit-content;
}
.riskTableItemDisplayId {
  height: 100%;
  margin: 0px 3px 0px 8px;
  font-weight: 500;
  color: var(--color-text-4);
}
.riskTableItemName {
  flex: 1;
  height: 100%;
  margin: 0px 3px;
}
.riskTableItemStrategyTag {
  padding: 2px;
  font-size: 12px; 
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}
.riskItemCriticityTagContainer {
  max-width: 40px;
}
.riskTableItemCriticityTag, .riskTableSmallCriticityTag {
  color: white;
  padding: 3px 10px;
  text-align: center;
  border-radius: 5px;
}
.riskTableSmallCriticityTag {
  padding: 1px 5px;
  font-size: 12px;
  max-width: 40px;
  width: 23px;
  font-weight: bold;
}

.riskMatrix {
  display: grid;
  grid-gap: 10px;
  grid-auto-rows: 23%;
  grid-auto-columns: 25%;
  flex: 1;
}

.riskMatrixCelContainer {
  border: 1px solid blue;
}
.viewModeEntrySelectBox, .viewModeSelectedCheckBox {
  width: 22px;
  height: 22px;
  background: var(--border-color-gray-00);
  cursor: pointer;
  margin: 0px 5px;
  transition: 0.2s all ease;
  border: 1px solid var(--border-color-gray-2);
  border-radius: 5px;
}
.viewModeEntrySelectBox:hover {
  background: var(--color-migso-blue);
  transform: scale(1.2);
}

.viewModeSelectedCheckBox {
  background: var(--color-migso-blue);
}
.viewModeSelectedCheckBox:hover {
  background: var(--color-bad);
  transform: scale(1.2);
}
.viewModeCommentEntryEditBtn, .viewModeEditingCommentEntry {
  transition: 0.2s all ease;
  border-radius: 5px;
  cursor: pointer;
}
.viewModeEditingCommentEntry {
  background: var(--color-migso-blue);
  color: white;
  cursor: pointer;
  transform: scale(1.2);
}
.viewModeCommentEntryEditBtn:hover {
  background: var(--color-migso-blue);
  color: white;
  transform: scale(1.2);
}
.viewModeEditError {
  border-color: var(--color-bad);
}
.riskMatrixLegendsContainer {
  height: 100%;
  max-width: 30px;
}
.riskMatrixSmallLegend {
  flex: 1;
  font-size: 9px;  
  width: 100%;
  padding: 8px;
  font-weight: 500;
}
.riskMatrixInnerRightSide {
  flex: 1;
  height: 100%;
  padding-right: 10px;
}
.riskMatrixLegend {
  color: var(--color-text-3);
  font-style: italic;
  background: var(--border-color-gray-1);
  padding: 0px 4px;
  border-radius: 5px;
  font-size: 10px;
}
.riskMatrixInnerRightSideLikelihoodContainer {
  /* padding: 5px 0px; */
  width: 100%;
}
.riskMatrixBottomLikelihoodContainer {
  width: 100%;
  padding-bottom: 5px;
}

.riskMatrix {
  flex:1;
  width: 100%;
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
}
.riskMatrixToogle {
  position: relative;
  margin-right: 5px;
}
.riskMatrixToogleSelectedText {
  background: var(--color-migso-blue);
  border-radius: 5px;
  color: white;
  padding: 1px 3px;
  font-weight: 400;
  text-transform: capitalize;
}

.riskMatrixToogleSelected {
  padding: 2px 3px; 
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s all ease;
}
.riskMatrixToogleSelected:hover{
  background-color: var(--border-color-gray-1);
}
.riskMatrixToogleSelectedArrow {
  transition: 0.2s all ease;
}
.riskMatrixToogleSelected:hover > .riskMatrixToogleSelectedArrow {
  color: var(--color-migso-blue-hover);
}
.riskMatrixToogleDropDown {
  animation-name: opacityIn;
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  background: var(--border-color-gray-0);
  width: 100%;
  position: absolute;
  top: 100%;
  z-index: 9999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
}
.riskMatrixToogleDropDownItem {
  width: 100%;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid var(--border-color-gray-3);
  transition: 0.2s all ease;
  font-weight: 500;
}
.riskMatrixToogleDropDownItem:hover {
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0,0.4);
  background: var(--color-migso-blue-hover);
  color: white;
}
.riskMatrixItemContainer {
  position: relative;
  transition: 0.2s all ease;
  border: 2px dotted transparent; 
  overflow-x: hidden;
} 
.riskMatrixItemCriticity {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 10px;
  padding: 2px 1px;
}
.riskMatrixItemGrid {
  height: 95%;
  max-height: 95%;
  width: 92%;
  float: right;
  padding:2px;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px;
}
.riskMatrixItemGrid::-webkit-scrollbar {
  display: none;
  width: 3px;
  height: 8px;
  background-color: var(--border-color-gray-00);
  border-radius: 10px;
  transition: 0.3s;
  animation-name: opacityIn;
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}
.shortcutBigListContainer::-webkit-scrollbar-thumb {
  background-color: var(--border-color-gray-2);
  border-radius: 8px;
}
.shortcutBigListContainer::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-migso-blue);
}
.riskMatrixItemGrid:hover::-webkit-scrollbar {
  display: inline-block;
}

.riskMatrixRoItemContainer {
  /* width: 45%; */
  width: fit-content;
  max-height: 20px;
  /* min-width: 65px; */

  margin: 2px;
}
.riskMatrixRoItem {
  background: var(--border-color-gray-00-5);
  border: 1px solid var(--border-color-gray-3);
  cursor: grab;
  border-radius: 10px;
  padding: 2px 5px;
  width: 100%;
  transition: 0.2s all ease;
}
.riskMatrixRoItemId {
  font-size: 9px;
  font-weight: 600;
  padding: 0px 1px;
  align-items: center;
  color: var(--color-text-3);
}
.riskMatrixRoItemEscalated {
  color: var(--color-clash);
  font-size: 11px;
}
.riskMatrixRoItemActionsDot {
  font-size: 50%;
  border: 1px solid var(--border-color-gray-1);
  padding: 0px 2px;
  text-align: center;
  border-radius: 100%;
  height: 11px;
  margin: 0px 1px;
}
.riskContainer {
  width: 100%;
  height: 100%;
}
.riskBigViewModeDisplayId {
  font-size: 19px;
  font-weight: 500;
  padding: 0px 0px 0px 8px; 
}
.riskBigViewModeName {
  font-size: 18px;
  padding: 0px 8px;
  text-transform: capitalize;
  color: var(--color-text-3);
  font-weight: 500;
}
.riskBigViewModeDescriptionHeaders {
  width: 100%;
  background-color: var(--border-color-gray-0);
  border-bottom: 1px solid var(--border-color-gray-2);
}
.riskBigViewModeDescriptionHeaderItem, .riskBigViewModeDescriptionHeaderSelectedItem {
  padding: 3px 8px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: 0.2s all ease;
}
.riskBigViewModeDescriptionHeaderSelectedItem {
  /* border-bottom-color: var(--color-migso-blue); */
  border: 1px solid var(--border-color-gray-2);
  background: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-color: white;
}
.riskBigViewModeDescriptionHeaderItem:hover {
  background: white;
  border-color: var(--color-migso-blue);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0,0.4);
}
.riskBigViewModeSelectedContentContainer {
  flex: 1;
  width:100%;
  animation-name: opacityIn;
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  max-height: 90%;
  /* overflow-y: auto;
  overflow-x: hidden; */
  overflow: visible;

  border: 1px solid var(--border-color-gray-2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
}

.editSideBarGroupMandatoryAlert {
  border-color: var(--color-bad);
}
.riskBigViewModeRelatedActionTableContainer {
  background-color: var(--border-color-gray-00-5);
  border: 1px solid var(--border-color-gray-1);
  border-radius: 5px;
  width: 30%;
}
.riskBigViewModeRelatedActionTableHeaders {
  width: 100%;
  padding: 5px;
  max-height: 30px;
  border-bottom: 1px solid var(--border-color-gray-2);
  background-color: var(--border-color-gray-00-5);
}

.riskBigViewModeRelatedActionTableContent {
  width: 100%;
  padding: 8px 5px;
  flex: 1;
}
.riskBigViewModeRelatedActionTableList {
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}
.riskBigViewModeSingleMatrixContainer {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* margin: 0px 5px; */
  border: 1px solid var(--border-color-gray-1);
  border-radius: 5px;
  background-color: var(--border-color-gray-0);
}
.riskMatrixViewItem {
  height: fit-content;
  /* cursor: grab; */
  cursor:pointer;
  top: auto!important;
  left: auto!important;
}
.riskMatrixViewInnerItem {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  text-align: center;
  width:100%;
  padding: 0px 2px;
}
.detailedRoActionsTableContainer {
  background: white;
  animation-name: opacityIn;
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}
.detailedRoActionsTableContainer:hover > #detailed_matrix_ro_expand_arrow {
  display: block;
}
.detailedRoActionsCloseBtn {
  cursor: pointer;
  border-radius: 4px;
  transition: 0.2s all ease;
  padding: 2px;
}
.detailedRoActionsCloseBtn:hover {
  background: var(--color-migso-blue);
  color: white;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0,0.4);
}
.detailedRoActionsTableRoEscalatedContainer {
  color: var(--color-bad);
  /* border: 1px solid var(--color-bad); */
  padding: 3px 14px;
  margin-right: 8px;
  border-radius: 5px;
}
.detailedRoActionsEscalatedArrow {
  font-size: 17px;
}
.detailedRoActionsTableEscalatedText {
  font-weight: 500;
  margin-left: 3px;
  font-size: 13px;
}
.multipleCheckboxDropdownContainer {
  position: relative;
  font-size: 12px;
}

@keyframes extendHeightFromUp {
  from {
    height: 0%;
    opacity: 0;
  } 

  to {
    heigth: 100%;
    opacity: 1;
  } 
}
.multipleCheckboxDropdownListContainer {
  width: 100%;
  position: absolute;
  top: 100%;
  border: 1px solid var(--border-color-gray-1);
  background: white;
  z-index: 9999999999;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0,0.4);
  padding: 3px 5px 3px 10px;
  margin-left: -12px;

  animation-name: extendHeightFromUp;
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}
.multipleCheckboxDropdownTitleContainer {
  background: white;
  /* border: 1px solid var(--border-color-gray-1); */
  border-radius: 5px;
  /* padding: 3px; */
  cursor: pointer;
  transition: 0.2s all ease;
}

/* .multipleCheckboxDropdownTitleContainer:hover {
  background: var(--border-color-gray-00);
  border-color: var(--color-migso-blue);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0,0.4);
} */
.multipleCheckBoxDropdownArrow {
  transition: 0.2s all ease;
}
/* .multipleCheckboxDropdownTitleContainer:hover > .multipleCheckBoxDropdownArrow {
  color: var(--color-migso-blue);
  transform: rotateZ(-180deg);
} */
.multipleCheckBoxDropdownTitle {
  font-weight: 400;
  padding: 0px 2px;
}
.riskTableFilterBlockCss {
  width: 20%;
  min-width: 100px;
  max-width: 150px;
  margin: 0px 3px;
}
.multipleCheckboxDropdownClearAllContainer {
  color: var(--color-bad);
  bottom: 65%;
  right: -8px;
  font-size: 15px;
  position: absolute;
  background: var(--border-color-gray-00);
  border: 1px solid var(--color-bad);
  border-radius: 10px;
  padding: 1px;
  transition: 0.2s all ease;
  text-align: center;
  vertical-align: center;
  cursor: pointer;
}
.multipleCheckboxDropdownClearAllContainer:hover {
  background: var(--color-bad);
  color: white;
  border-color: white;
}
.riskTableItemDateContainer {
  margin: 0px 5px;
}
.riskTableItemTitle {
  font-weight: 500;
  font-size: 11px;
  font-style: italic;
}
.riskTableItemDate {
  margin: 1px;
  font-size: 10px;
  background: var(--border-color-gray-1);
  border-radius: 8px;
  padding: 2px 5px;
  color: white;
}
.shortcutMini {
  transition: 0.2s all ease;
}
.shortcutMini:hover {
  color: var(--color-migso-blue)!important;
}
.scheduleMini {
  cursor: pointer;
  margin-right: 5px;
  border-radius: 15px;
  padding: 0px 15px;
}
.scheduleMini:hover {
  color: var(--color-migso-blue);
}

.shortcutBigContainer {
  width: 13%;
  min-width:200px;
  padding: 0px 25px 0px 0px;
  height: 365px;
  max-height: 365px; 
  background-color: transparent;
  /* margin:25px 0px; */
  /* margin: 25px -50px 25px 0px; */
  animation-name: opacityIn;
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}
.shortcutBigAddBtn, .shortcutBigSelectedBtn, .shortcutBigLockBtn{
  cursor:pointer;
  color:var(--color-text-2);
  font-size: 20px;
  margin: 5px 2px;
  border-radius: 5px;
  transition: 0.2s all ease;
  padding: 0px;
  width: 23px;
  height: 23px;
}
.shortcutBigLockBtn:hover > .shortcutBigLockIcon {
  color: var(--color-migso-yeallow)!important;
}
/* .shortcutBigAddBtn:hover {
  background: var(--border-color-gray-1);
  color: var(--color-migso-blue);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0,0.4); 
  transform: scale(1.2);
} */
.shortcutBigAddBtn:hover > .shortcutBigBtnIcon {
  color: var(--color-migso-blue)!important;
}
.shortcutBigSelectedBtn {
  color: white;
  background: var(--color-migso-blue);
  border: 1px solid white;
  padding: 2px;
}

.shortcutBigBtnSelectedIcon:hover {
  color: white!important;
}
.shortcutBigListContainer {
  width: 100%;
  overflow: auto;
  position: relative;
  /* overflow-x: auto; */
  padding: 0px 10px;
  border-radius: 5px;
  max-height: 300px!important;
  display: flex;
}
.shortcutBigMultiList {
  min-width: 30px;
  height: 80%!important;
  max-height: 300px;
  margin-top: 10px;
}
.shortcutBigMultiList:first-child {
  min-width: 310px;
  max-width: 400px;
}
.shortcutBigMultiListProject {
  left: 25px;
  z-index: 1000;
  background-color: var(--border-color-gray-0);
  position: sticky;
}
.shortcutListOverflowContainer {
  height: 100px;
  width: 25px;
  background-color: var(--border-color-gray-0);
  position: sticky;
  z-index: 1000;
  left: 0;
  margin-left: -25px;
}
.shortcutBigMultiListDomainName {
  font-weight: bold;
  font-size: 14px;
  /* padding: 4px; */
} 
.shortcutMultiListNoItemsContainer {
  /* padding: 20px; */
  padding: 0px 20px;
  width: 96%;
  margin: 0 auto;
  border-radius: 5px;
  transition: 0.2s all ease;
  position: relative;
}
.shortcutMultiListNoItemsContainerEdit {
  background-color: var(--color-primary-3);
}
.shortcutListNoItemsDragContainer {
  color: var(--color-text-2);
  position: absolute;
  top: 0px;

  animation-name: opacityIn;
  animation-timing-function: ease;
  animation-duration: 200ms;
  animation-iteration-count: 1;
}
.wrap {

  flex-wrap: wrap;
}
.maskImageDown {
  mask-image: linear-gradient(black 97%, transparent);
}
.maskImageUp {
  mask-image: linear-gradient(0, black 97%, transparent);
}
.shortcutBigListContainerDashedBorder {
  background: linear-gradient(to right, var(--color-bad) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--color-bad) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, var(--color-bad) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--color-bad) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 20px 2px, 2px 20px;
}
.shortcutBigListContainerDashedBorderGray {
  background: linear-gradient(to right, var(--border-color-gray-1) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--border-color-gray-1) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, var(--border-color-gray-1) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--border-color-gray-1) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 20px 2px, 2px 20px;
}

.shortcutBigListContainer::-webkit-scrollbar {
  display: none;
  width: 8px;
  height: 8px;

  background-color: var(--border-color-gray-00);

  border-radius: 10px;

  transition: 0.3s;
}
.shortcutBigListContainer::-webkit-scrollbar-thumb {
  background-color: var(--border-color-gray-2);
  border-radius: 8px;
}
.shortcutBigListContainer::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-migso-blue);
}
.shortcutBigListContainer::-webkit-scrollbar-track {
  border-radius: 8px;
}
.shortcutListItemContainer {
  width: 100%;
  /* margin: 10px; */
  padding: 5px;
  border-radius: 8px;
  transition: 0.2s box-shadow ease;
  height: 34px;
  border: 2px solid transparent;
  margin: 3px 0px;
  box-shadow: 0px 0px 4px #888888;
}
.shortcutListItemContainer:hover {
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0,0.4); 
}
.shortcutListItemName {
  font-size: 14px;
  font-weight: 600;
  transition: 0.2s all ease;
}
.shortcutListItemMenuIcon {
  border: 1px solid var(--border-color-gray-2);
  color: var(--border-color-gray-2);
  transition: 0.2s all ease;
  border-radius: 4px;
  width: 19px;
  height: 19px;
}
.shortcutListItemMenuIconCss {
  font-size: 18px;
}
.shortcutListItemMenuIcon:hover {
  background: var(--color-migso-blue);
  color: white;
  border-color: white;
}
.shortcutListItemDragIcon {
  /* color: white; */
  animation-name: opacityIn;
  animation-timing-function: ease;
  animation-duration: 200ms;
  animation-iteration-count: 1;
}
.shortcutListItemOpenMenuBtn {
  margin: 0px 1px;
  /* border: 1px solid var(--border-color-gray-1); */
  padding: 2px;
  /* animation-name: opacityIn;
  animation-timing-function: ease;
  animation-duration: 200ms;
  animation-iteration-count: 1; */
  width: 22px;
  height: 22px;
}
.shortcutListItemOpenMenuEditBtnIcon:hover{
  color: var(--color-good-hover);
}
.shortcutListItemOpenMenuDeleteBtnIcon {
  /* color: var(--color-bad); */
  font-size: 18px;
  opacity: 0.6;
  transition: 0.2s all ease;
}
.shortcutListItemOpenMenuDeleteBtnIcon:hover {
  color: var(--color-bad-hover);
  transform: scale(1.2);
  opacity: 1;
}
.shortcutListItemOpenMenuDeleteConfirm {
  font-size: 10px;
  padding: 2px;
  color: white;
  background: var(--color-bad);
  border: 1px solid white;
  font-weight: bold;
}
.shortcutListItemOpenMenuDeleteConfirm:hover {
  color: white;
}
.shortcutListItemDraggableContainer {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}
.shortcutListItemMenuContainer:focus {
  border: none;
  outline: none;
}
.shortcutMiniCount {
  position: absolute;
  background: var(--color-bad);
  top: -5px;
  right: -8px;
  font-size: 10px;
  font-weight: bold;
  color: white;
  padding: 2px 6px;
  border-radius: 100%;
}
.shortcutBigBtnIcon, .shortcutBigBtnSelectedIcon {
  font-size: 20px;
  color: var(--color-text-3);
}
.shortcutBigBtnSelectedIcon {
  color: white;
}
.modalButtonDelete {
  background: var(--color-bad);
  color: white;
}
.modalButtonDelete:hover {
  background: var(--color-bad-hover);
  color: white;
}
.modalButtonDeleteIcon, .modalButtonDeleteIcon:hover {
  color: white;
}
.shortcutListMoreIcon {
  color: var(--color-text-5);
  width: 100%;
  min-height: 20px;
  animation-name: opacityIn;
  animation-timing-function: ease;
  animation-duration: 200ms;
  animation-iteration-count: 1;
}
.shortcutBigCount {
  background: var(--border-color-gray-0-5);
  color: var(--color-text-3);
  text-align: center;
  border-radius: 100%;
  font-weight: bold;
  margin-left: 8px;

  width: 18px;
  height: 18px;
  padding: 0 2px;
  font-size: 8px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid var(--border-color-gray-2);
}
.shortcutBigHeaderTitle {
  font-weight: 700;
  font-size: 14px;
  color: var(--color-text-2);

}
/* .minWidthTwoHundred {
  min-width: 200px;
} */
.shortcutListNoItems, .shortcutListNoAuthNoItems {
  cursor: pointer;
  max-width: 200px;
  width: 100%;
  height: auto!important;
  box-shadow: none!important;
  
  color: var(--color-text-3);
  background: var(--border-color-gray-00);
  border: 1px solid var(--border-color-gray-1);
  border-radius: 8px;
  /* margin: 0 auto; */
  position: relative;
  /* justify-content: flex-start!important;
  padding-left: 10px; */
}
.shortcutListNoItemsBtn {
  /* color: var(--color-migso-blue); */
  color: var(--color-migso-green);
}
.shortcutListNoItems > span {
  font-weight: 500;
  margin-left: 2px;
}
.shortcutListNoItems:hover {
  /* background-color: var(--color-migso-blue); */
  /* color: white; */
  box-shadow: none!important;
}

.shortcutListNoAuthNoItems {
  opacity: 0.3;
  cursor:default!important;
  border:none;
  box-shadow: none;
  background: none;
}
.shortcutListItemNoAuthNoItemsIcon {
  color: var(--border-color-gray-5);
}

.shortcutListNoItemsInEdit:hover {
  box-shadow: none;
}
.shortcutListNoItemsTxtCss {
  white-space: normal!important;
  /* overflow: visible!important; */
  text-overflow: inherit!important;
}
.flexOne {
  flex: 1;
}
.shortcutBigNoAuthCreate {
  height: 100%;
  width: 100%;
  padding: 20px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: var(--color-bad);
}
.shortcutBigLockIcon {
  color: var(--color-migso-yeallow);
}

/* -------------- GOVERNANCE ---------------- */

/* CALENDAR */
.Kalend__Calendar__root {
  width: 100% !important;
}

.Kalend__ButtonIcon__container {
  height: 35px !important;
  width: 35px !important;
}
.Kalend__ButtonIcon__svg-normal {
  height: 26px !important;
}

.Kalend__MobileLayout  {
  width: auto !important;
  margin-right: 20px;
}

.Kalend__CalendarDesktopNavigation__container-mobile {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}

.Kalend__CalendarHeaderCol, .Kalend__CalendarHeaderCol-dark {
  padding-bottom: 0px !important;
}

.governanceReviewNotesInput {
  background-color: var(--border-color-gray-00);
  border-radius: 10px;
  border: 1px solid var(--border-color-gray-0);
}

.governanceReviewNotesInputActive {
  background-color: var(--border-color-gray-00);
  border-radius: 10px;
  border: 1px solid var(--color-migso-blue-hover-border);
}

.governanceReviewModeBlock {
    width: 100%;
    background-color: var(--border-color-gray-00);
    padding: 15px;
    border-radius: 10px;
    /* margin-top: 10px; */
}

.governanceTimelineEvent:hover {
  box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.7);
}

.governanceScopeViewModeBlock {
  border: 1px solid var(--border-color-gray-0-5);
  border-radius: 8px;
  flex: 1;
  height: 100%;
}

/* -------------- SCHEDULE ---------------- */
.scheduleAtCompletionButton {
  border: 1px solid var(--border-color-gray-1);
  color: var(--color-text-3);
  padding: 2px 8px;
}

.scheduleAtCompletionButton:hover {
  color: var(--color-text-3);
}

.scheduleAtCompletionButtonActive {
  color: white;
  background-color: var(--color-primary-1);
}

.scheduleAtCompletionButtonActive:hover {
  color: white;
}

/* SCHEDULE TIMELINE TASK */
.scheduleTimelineTaskContainer {
  width: 400px;
  height: 20px;
  margin-top: -5px;
  position: relative;
}
.scheduleTimelineTaskDateRangeContainer {
  position: absolute;
  height: 100%;
  border-radius: 10px;
  /* box-shadow: 3px 0 3px -1px var(--border-color-gray-4),
  -3px 0 3px -1px var(--border-color-gray-4);
  box-shadow: -2px 0px 2px 0px var(--border-color-gray-4), 2px 0px 2px 0px var(--border-color-gray-4); */
}
.scheduleTimelineTaskDateAndLetter:hover {
  z-index: 10;
  font-weight: bold;
}
.scheduleTimelineTaskLetter {
  position: relative;
  margin-left: 5px;
  z-index: 2;
  
}
.scheduleTimelineTaskLetterEnd {
  margin-right: 5px;
}
.scheduleTimelineTaskDate {
  position: absolute;
  margin-top: 3px;
  background-color: white;
  padding: 0px 3px;
  border:1px solid var(--border-color-gray-0-5);
  border-radius: 5px;
  /* z-index: 2000; */
}
.scheduleTimelineTaskTodayLine {
  position: absolute;
  height: 100%;
  /* border-right: 3px dotted var(--color-migso-yeallow); */
  box-shadow: 2px 0 var(--color-migso-yeallow);
  z-index: 1;
}

/* SCHEDULE TIMELINE MILESTONE */
.scheduleTimelineMilestone {
  position: absolute;
}
.scheduleTimelineMilestone:hover {
  font-weight: bold;
  z-index: 20000!important;
}
.scheduleTimelineMilestoneSquare {
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
  margin-top: -6px;
  border: 0.5px solid var(--color-text-3);
  position: absolute;
  z-index: 1;
}
.scheduleTimelineMilestoneLetter {
  position: absolute;
  transform: rotate(-45deg);
  z-index: 10;
}
.scheduleTimelineMilestoneDate {
  position: absolute;
  margin-top: 13px;
  margin-left: -9px;
  background-color: white;
  padding: 0px 3px;
  border:1px solid var(--border-color-gray-0-5);
  border-radius: 5px;
}


/* -------------- RISK / OPP ---------------- */

.flipContainer {
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.flipContainerFront, .flipContainerBack {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipContainerBack {
transform: rotateY(180deg);
}

.switchViewBtnContainer{
  font-size: 12px;
  font-weight: 500;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
  padding: 2px 6px;
  border-radius: 10px;
  color: var(--color-text-3);
  transition: 0.3s;
}
.switchViewBtnContainer:hover{
  color:var(--color-migso-blue);
}

.switchViewBtnIcon{
  font-size:14px;
  color:var(--color-text-5);
  margin-right:5px;
}

.riskCardMatrixImpactFlipContainer {
  position:relative;
  height: 100%;
}

.riskCardImpactContentContainer {
  background: white;
  width: 100%;
  height: 100%;
  padding: 3px 10px;
  overflow-y: auto;
  max-height: 90%;
}
.riskCardImpactTableHeader {
  flex: 1;
  margin: 0px 10px;
  text-align: center;
  padding: 1px 4px;
  border-radius: 5px;
  color: white;
  text-transform: capitalize;
  font-weight: 500;
  max-height: 30px;
  font-size: 12px;
}
.riskCardImpactTableHeaderTitle {
  color: var(--color-text-2);
  font-weight: bold;
  font-size: 15px;
  text-align: start;
  margin-right: 10px;
  min-width: 62px;
  
}
.flexTwo {
  flex: 2;
}
.riskCardImpactValueContainer, .riskCardsImpactValueUneditableContainer{
  background-color: var(--border-color-gray-0);
  margin: 0px 10px;
  padding: 2px 4px;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all ease;
}
.riskCardsImpactValueUneditableContainer {
  cursor: default;
}
/* @media only and (max-width: ) {

} */
.riskCardImpactValueContainer:hover {
  background-color: var(--color-migso-blue);
  color: white;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0,0.3);
}
.riskCardImpactValue {
  text-align: center;
  font-size: 9px;
  font-weight: bold;
}
.riskCardImpactMapTitle, .riskCardIMpactMapSubtitle {
  margin-left: 10px;
}
.riskCardImpactMapTitle {
  font-size: 13px;
  font-weight: 600;
  width: 100%;
}
.riskCardIMpactMapSubtitle {
  color: var(--color-text-3);
  font-size: 12px;
}
.hideContainer{
  z-index: -100;
  padding: 0px!important;
  width: 0%!important;
  height: 0%!important;
  border: none!important;
  outline: none!important;
  margin: 0px!important;
  flex: 0!important;
  position: absolute;
}
.govReviewPeriodicitySideBarWeekTxt {
  color: var(--color-text-3);
  font-size: 13px;
  font-weight: 600;
  margin-right: 3px;
  white-space: nowrap;
}
.govReviewPeriodicitySideBarWeekTxt:last-child {
  margin-right: 0px;
  width: 100%;
}
.govReviewPeriodicitySideBarWeekGapInputBlock {
  /* width: 40px; */
  border-color: var(--border-color-gray-2);
  outline: none;
  /* margin: 0px 5px; */
}
.govReviewPeriodicitySideBarWeekGapInput {
  height: 100%;
  width: 100%;
  border: 1px solid var(--border-color-gray-4);
  border-radius: 3px;
}
.govReviewPeriodicityWeekDay {
  border: 1px solid var(--color-migso-blue);
  margin: 0px 10px;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: var(--color-migso-blue);
  cursor: pointer;
  transition: 0.2s all ease;
}
.govReviewPeriodicityWeekDay:hover {
  background: var(--color-migso-blue);
  color: white;
}
.govReviewDeleteSeriesModalInput {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid var(--border-color-gray-3);
  background-color: var(--border-color-gray-0);
  cursor: pointer;
  transition: 0.2s all ease;
}
.govReviewDeleteSeriesModalInput:hover {
  background: var(--color-migso-blue);
  transform: scale(1.1);
}


.govReviewDeleteSeriesModalInputTxt {
  margin-left: 5px;
  color: var(--color-text-2);
  font-weight: 600;
  font-size: 14px;
}

.importSubTitleIcon{
  font-size: 30px;
  margin-left:-4px;
  margin-right:10px;
  color:var(--color-text-4);
}
.eventCardRecurrentIcon::after {
  content: "loop";
  font-family: "Material Icons";
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 10;
  right: 0;
  bottom: 4px;
}

.recurrentIcon {
  position: absolute;
  width: 15px;
  height: 15px;
  color: var(--border-color-gray-0);
  z-index: 10;
  right: 5px;
  bottom: 10px;
  font-size: 20px;
  font-family: "Material Icons"!important;
}
/* .Kalend__Event-normal::after {
  content: "loop";
  font-family: "Material Icons";
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 10;
  right: 0;
  bottom: 4px;
} */
.wbsReferenceModalVersionItem, .wbsReferenceModalSelectedVersionItem {
  border: 1px solid var(--border-color-gray-1);
  width: 100%;
  padding: 5px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all ease;
}
.wbsReferenceModalVersionItem:hover {
  background: var(--bg-color-selected-2);
}
.wbsReferenceModalSelectedVersionItem {
  cursor: default;
  background: var(--bg-color-selected-2);
}
.wbsReferenceModalVersionItem, .wbsReferenceModalSelectedVersionItem {
  border: 1px solid var(--border-color-gray-1);
  width: 100%;
  padding: 5px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all ease;
}
.wbsReferenceModalVersionItem:hover {
  background: var(--bg-color-selected-2);
}
.wbsReferenceModalSelectedVersionItem {
  cursor: default;
  background: var(--bg-color-selected-2);
}

.wbsReferenceModalVersionItemName {
  font-weight: 500;
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.wbsReferenceModalItemCloseBtn:hover{
  color: var(--color-bad);
}
.wbsReferenceModalItemConfirmCss {
  padding: 0px;
  font-size: 12px;
  color: white;
  background-color: var(--color-bad);
}
.wbsReferenceModalItemConfirmCss:hover {
  color:white;
}
.Kalend__CalendarBody {
  cursor: pointer;
}
.Kalend__DayViewOneDay {
  transition: 0.1s all ease;
  border-right: 1px solid #e0e0e0;
}
.Kalend__DaysViewVerticalLine__line {
  width: 0px!important;
}
.Kalend__DayViewOneDay:hover {
  background-color: var(--bg-color-selected-2);
}

.actionCardLinkItem {
  margin: 12px 10px;
  padding: 0px 4px;
  background: var(--border-color-gray-00-5);
  border: 1px solid var(--border-color-gray-0-5);
  border-radius: 5px;
  width: calc(100% - 20px);
  font-size: 12px;
}

.actionCardLinkItem:hover {
  background-color: var(--bg-color-selected-2);
  transition: 0.3s background-color ease;
  cursor: pointer;
}

.actionCardLinkItemDeleteButton {
  opacity: 0%;
}

.actionCardLinkItem:hover .actionCardLinkItemDeleteButton {
  opacity: 100%;
  transition: 0.3s opacity ease;
}

.actionTableItemLinkListItem:not(:last-child) {
  margin-right: -8px;
}

.actionTableItemLinkListItem:hover {
  z-index: 2;
}
.blueInfoEditSideBarIcon {
  font-size: 13px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 100%;
  padding: 1px 8px;
}
.marginBottomZero {
  margin-bottom: 0px;
}
.govScopeViewDetailsDescriptionNewValue {
  max-width: 50%;
  word-break: break-word;
  color: var(--color-migso-blue);
}